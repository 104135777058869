import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'

import { getSizesFromIds, getCatFromSize } from "../../config/sizes"
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";

import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import Dropdown from 'react-bootstrap/Dropdown'
import { getAllSizes } from "../../config/sizes"
import CheckBox from "../../components/CheckBox"
import ImageBackground from "../../components/ImageBackground"
import AlertModal from "../../components/generator/AlertModal";
import { Player } from 'video-react';

import EditGauge from "../../components/generator/EditGauge";
import EditTechnics from "../../components/generator/EditTechnics";
import EditGlossary from "../../components/generator/EditGlossary";
import ManagePanel from "../../components/generator/ManagePanel";
import UploadPicture from "../../components/generator/UploadPicture";
import { getSymbols } from "../../generator/symbols"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ViewSchema from "../../components/generator/ViewSchema";


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function PatternPreview(props) {

  const history = useHistory();
  const { patternId } = useParams();
  const location = useLocation();
  const [createdPattern, setCreatedPattern] = useState(props.pattern)
  const [previewProject, setPreviewProject] = useState(props.previewProject)
  const {token, setToken} = useToken();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [list, setList] = useState()
  const [currentPart, setCurrentPart] = useState(props.currentPart)
  const [showChart, setShowChart] = useState(null)

  const iconSize = 20
  const iconViewSize = 40

  const btnSize = 12
  const color = "rgba(255,255,255,0.8)"



  const selectCurrentPartItem = (item, part) => {
    if (item.type == "part" && item.numPart == part) {
      return true
    } else if (item.section && item.section.numPart == part) {
      return true
    } 
    return false
  }


  useEffect(() => {
    console.log("currentPart", currentPart)
    var tmpList = PatternUtils.constructSectionsAndParts(props.pattern)
    if (props.pattern.parts != "") {
      var partList = tmpList.filter((item) => selectCurrentPartItem(item, currentPart))
      console.log("partList",partList)
      setList(partList)
    } else {
      setList(tmpList)
    }



  }, [props])

  const onBack = () => {
    history.push({
      pathname: `/created-pattern-details/${createdPattern.id}`,
      state: { pattern: createdPattern }
    })
  }

  const editItem = (item) => {
    var pathname = item.type == 'text' ? `/create-section/` : item.type == 'chart' ?  '/edit-chart/' : `/create-image-section/`;
    var backPath = `/ViewProject/${previewProject.id}`

    if (item.type == "chart") {
      var nbSubsections = 0
      var subsection = null
          
        for (var i = 0; i < item.section.subsections.length; i++) {
          if (item.section.subsections[i].deleted != 1) {
            subsection = item.section.subsections[i]
            nbSubsections++
          }
        }
      

        if (nbSubsections === 1 && subsection && subsection.sizeIds.length === createdPattern.sizeIds.length) {
          pathname = `/edit-chart/`

        history.push({
            pathname: '/edit-chart',
            state: { patternId: createdPattern.id, sectionUniqueId:item.section.uniqueIdOnClient, subsectionUniqueId: subsection.uniqueIdOnClient }
          })
          return
        } else {
          pathname = `/chart-list/` 
        }
    }
    history.push({
      pathname: pathname,
      state: { pattern: createdPattern, type: item.type, section:item.section, edit:true, backPath: backPath }
    })
  }





  const renderPart = (item) => {
    return (
      <View style={{flexDirection:'row', alignItems:'center', marginHorizontal:12, marginTop: 24}}>
      <View style={{ paddingTop:16, flex:1 }}>
          <p className="DetailTitle">{item.subtitle}: {item.name}</p>
      </View>
    </View>
    )
  }

  const renderImage = (item) => {
    return (
      <View style={[styles.row, {justifyContent: "space-between"}]}>
        <View style={{flexDirection:"row", justifyContent: "space-between", alignItems: "flex-start", flex: 1 }}>
          <TouchableOpacity
            onPress={() => editItem(item)}>
            <FontAwesomeIcon className={"fa-2xs"} style={{color: "#FECB2E", marginRight: 8}} size={"sm"} icon={ faEdit } />
          </TouchableOpacity>
          {item.imgPath != '' && 
          <Image source={{uri: Utils.getAttachementUrl(item.section.imgAttachmentId)}} style={{width:600, height:400, marginRight: 8, borderRadius:8}}
            />
            }
        </View>
      </View>
    )
  }

  const renderChart = (item) => {
    console.log("CHAAAAART")
    return (
      <TouchableOpacity style={styles.row} onPress={() => editItem(item)}>
      <View style={{flexDirection:"row", justifyContent: "flex-start", alignItems: "center", }}>
        <View>
          <FontAwesomeIcon style={{color: "#FECB2E", marginRight: 8}} size={"sm"} icon={ faEdit } />
        </View>
        <View style={{marginRight: 8, width: iconViewSize, height: iconViewSize, backgroundColor:"#2ba5f7", borderRadius: iconViewSize / 2, justifyContent:'center', alignItems:'center', opacity:0.7}}>
          <Image urlOnServer={PatternUtils.getSectionFromSectionType(item.type).img} style={{width:iconSize, height:iconSize}}
              resizeMode='contain'/>
        </View>
        <Text style={styles.title}>CHART</Text>
      </View>
    </TouchableOpacity>
    )
  }


  const renderRows = (subsection) => {
    var iconSize = 20
    var iconViewSize = 40
    if (subsection.rowStruct && subsection.rowStruct != "" ) {
      var rows = []
      if (subsection.rowStruct.includes("-")) {
        rows = subsection.rowStruct.split("-")
      } else {
        rows[0] = subsection.rowStruct
      }
      return (
        <View style={{marginRight: 8, marginTop: 4, flexDirection: "row"}}>
          <Text style={{color: "white", fontSize: 10, fontStyle: "italic"}}>{ rows[1] ? strings.patternCreator.fromRow : strings.patternCreator.onRow} <Text style={{fontWeight: "bold", color:"#FECB2E"}}>{rows[0]}</Text> { rows[1] && " \n" + strings.patternCreator.toRow + " "}{ rows[1] && <Text style={{fontWeight: "bold", color:"#FECB2E"}}>{rows[1]}</Text>}</Text>
      </View>
      )
    }
  }

  const renderSubsection = (subsection, hasSidePic) => {
    return (
      <View style={styles.subRow}>
      <View style={{flexDirection:"row", justifyContent:"flex-start", alignItems: "flex-start"}}>
      <View style={{width: 65}}>
      {renderRows(subsection) }
      </View>
      <View style={{flexDirection:"row", maxWidth: hasSidePic ? 290 : 600}}>
        <Text style={[styles.title, { flexWrap: 'wrap', textAlign:"justify"}]}>{subsection.content[0] ? subsection.content :"..." }</Text>
      </View>
      </View>
    </View>
    )
  }

  const renderText = (item) => {
    var hasSidePic = (item.section.imgAttachmentId && item.section.imgAttachmentId != 0) ? true : false
    return (
      <View style={[styles.row, {justifyContent: "flex-start", alignItems: "flex-start"}]}>

          <TouchableOpacity
            onPress={() => editItem(item)}>
            <FontAwesomeIcon style={{color: "#FECB2E", marginRight: 8}} size={"sm"} icon={ faEdit } />
          </TouchableOpacity>
          <View style={{flex: 1}}>
          {
          item.section.subsections && item.section.subsections[0] && item.section.subsections.map((subsection) => {
            return renderSubsection(subsection, hasSidePic)
          })
          }
          </View>
          {
            hasSidePic && 
          <div 
          style={{marginLeft: 14, marginRight: 6, height: "100%", display: "flex", justifyContent: "flex-start", alignItems: "center"}} >
            <img 
              style={{width: 290, height: "100%", objectFit: "cover", borderRadius: 12}}
              src={Utils.getAttachementUrl(item.section.imgAttachmentId)} />
          </div>

          }
      </View>
    )
  }


  const renderVideo = (item) => {
    console.log("video item", item)
    return (
      <View style={[styles.row, {justifyContent: "space-between"}]}>
        <View style={{flexDirection:"row", justifyContent: "space-between", alignItems: "flex-start", flex: 1 }}>
          <TouchableOpacity
            onPress={() => editItem(item)}>
            <FontAwesomeIcon style={{color: "#FECB2E", marginRight: 8}} size={"sm"} icon={ faEdit } />
          </TouchableOpacity>
        <View style={{flex: 1, justifyContent: "center", alignItems: "center"}}>
          <Player
            playsInline
            poster="/imgs/icon_android.png"
            fluid={false}
            height={300}
            src={Utils.getAttachementUrl(item.section.videoAttachmentId)}
          /> 
        </View>
        </View>
      </View>
    )
  }

  const renderComponent = (item) => {
          switch (item.type) {
            case "part":
              return renderPart(item)
              break
            case "video":
              return renderVideo(item)
              break
            case "img":
              return renderImage(item)
              break
              case "chart":
                return renderChart(item)
                break
            default:
              return renderText(item)
              break
          }
  }

  const renderFlatList = () => {
    return (
      <FlatList style={{flex:1}}
      data={list}
      contentContainerStyle={{justifyContent:'center', alignItem:'center'}}
      numColumns={1}
      extraData={list}
      keyExtractor={(item, index) => `${item.toString()}-${index}`}
      renderItem={
        (item) => renderComponent(item.item)
      } 
    />
    )
  }


  if (showChart) {
    return (<ViewSchema
          section={showChart}
          onClose={() => setShowChart(null)}
          pattern={createdPattern}
          selectedSizeId={createdPattern.sizeIds[0]}
          history={props.history}
        />)
  }
  return(
 

    <View key={props.currentPart} style={{flexDirection:'column', backgroundColor: "#30556D", overflowY: 'scroll' , marginVertical: 24, borderRadius: 12, height: height - 120}}>

     <View style={{flex: 1,paddingHorizontal: 32, paddingVertical: 24, justifyContent: "center", alignItems: "center"}}>
     
     <h2  className="pattern-preview-title" >{`${createdPattern.name} - Preview`}</h2>
    <ScrollView contentContainerStyle={{flexGrow: 1, alignSelf: "center", justifyContent: "center"}}>
        {/* {list && renderAllContent()} */}
        {list && renderFlatList()}
    </ScrollView>
      


    </View>

    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },
  editImg: {tintColor:'rgba(255,255,255,0.8)', width:8, height:20},
  row: {
    flexDirection:'row', 
    flex: 1,
    alignItems: "center",
    borderColor:'rgba(255,255,255,0.6)', 
    borderBottomWidth:0.5, 
    paddingVertical:12,
    marginHorizontal:12,
  } ,
  subRow: {
    flexDirection:'row', 
    alignItems: "center",
    paddingVertical:12,
  } ,

});