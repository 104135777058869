import strings from "../config/strings";
import fetchClient from "../utils/http-common";
import Utils from "../utils/utils"
import { getSizesArray } from "../config/sizes"
import { SERVER_API_URL, APP_NAME } from '../config/Constants'
import ProjectService from "../services/ProjectService";
import ProjectUtils from '../utils/projectUtils'
//import useToken from '../hooks/useToken';
//import useUser from './hooks/useUser';
//import Patron from "../patrons/patrons";
//import useToken from '../hooks/useToken';
//var RNFS = require('react-native-fs');

var instance = null;
var __DEV__ = true;

export const CURRENT_VERSION = 1
export const LAST_SUPPORTED_VERSION = 1

export const LANGS = [
{
  value:'EN 🇬🇧',
  code:'en'
},
{
  value:'DE 🇩🇪',
  code:'de'
},
{
  value:'FR 🇫🇷',
  code:'fr'
},
{
  value:'ES 🇪🇸',
  code:'es'
},
{
  value:'IT 🇮🇹',
  code:'it'
},
{
  value:'RU 🇷🇺',
  code:'ru'
},
{
  value:'SV 🇸🇪',
  code:'sv'
},
{
  value:'DA 🇩🇰',
  code:'en'
},
{
  value:'PL 🇵🇱',
  code:'pl'
},
{
  value:'FI 🇫🇮',
  code:'fi'
},
{
  value:'NL 🇳🇱',
  code:'nl'
},
{
  value:'PT 🇵🇹',
  code:'pt'
}

]



export const TYPE_TEXT_PURE = 't'
export const TYPE_TEXT_ROWS = 'r'


export const ROW_TYPE_RANGE = 1
export const ROW_TYPE_ONE = 2

export const IMG_POSITION_LEFT = 0
export const IMG_POSITION_RIGHT = 1

export const SEPARATOR_ITEM = '±§|§±'
export const SEPARATOR_SUBITEM = '±#|#±'



const SEPARATOR_SUBSECTIONS = '±>'
const SEPARATOR_TEXT = '|'
export const PLACEHOLDER_SIZE = "*#@k#_SIZE_@##"


const POS_IN_ARRAY_TYPE = 0
const POS_IN_ARRAY_ID = 1
const POS_IN_ARRAY_ROWS = 2
const POS_IN_ARRAY_SIZES = 3
const POS_IN_ARRAY_TEXT = 10

export const getHeaders2 = () => {
    return {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem('token'),
        "APISUPPORT": 2,
        "APPNAME": APP_NAME
    };
}

export default class PatternUtils {

	constructor() {

		if (instance == null) {
			instance = this;


			this.loadSizes()
		}
		return instance;
	}

  // CHARTS


  getChartFromContent(content, customSymbols) {
    if (!content || content.length == 0)
      return null
    var struct = content.split('±>')
      // customSymbols: this.loadChartCustomSymbols(struct[2])
    return {width: parseInt(struct[0]), height: parseInt(struct[1]), customSymbols: this.loadChartCustomSymbols(struct[2], customSymbols) , array: struct[3].split('|'), worked:struct[4] }
  }


  static getEmptyPattern(origPattern) {
    var obj = {id: origPattern.id, uniqueIdOnClient: origPattern.uniqueIdOnClient, updatedAtOnClient: origPattern.updatedAtOnClient}

    return obj
  }
  async getPatternFromServer(patternId) {
    let options = Object.assign({ method: "GET" }, null);
    options.headers = getHeaders2();
    console.log("getPatternFromServer")
    var response = await fetchClient.get(`${SERVER_API_URL}/patterns/${patternId}.json`)

    return response.data

  }

  static convertServerPatternToLocalPattern(pattern) {
    var newPattern = {...pattern}

    newPattern.sections = [];//{...pattern.pattern_sections}

    for (var i = 0; newPattern.pattern_sections && i < newPattern.pattern_sections.length; i++) {

      var section = {...newPattern.pattern_sections[i]}
      section.subsections = [...section.pattern_subsections]
      section.pattern_subsections = null;

      for (var y = 0; section.subsections && y < section.subsections.length; y++) {
        section.subsections[y].sizeIds = section.subsections[y].sizeIds ? section.subsections[y].sizeIds.split('|') : []
      }
      newPattern.sections.push(section);
     // newPattern.sections[i].subsections.push(subsection)//newPattern.pattern_sections[i].pattern_subsections
      //newPattern.sections[i].pattern_subsections = null
    }
    newPattern.pattern_sections = null;


    newPattern.sizeIds = newPattern.sizeIds ? newPattern.sizeIds.split('|') : []

    return newPattern
  }


  static convertLocalPatternToServerPattern(pattern) {
    var newPattern = {...pattern}


    if (pattern.sections) {
      newPattern.sections = [];//{...pattern.pattern_sections}

      for (var i = 0; pattern.sections && i < pattern.sections.length; i++) {

        var section = {...pattern.sections[i]}

        var subsections = []
        for (var y = 0; section.subsections && y < section.subsections.length; y++) {

          var subsection = {...section.subsections[y]}


          subsection.sizeIds = Utils.getArrayAsString(subsection.sizeIds, '|')
          subsections.push(subsection)
        }

        section.subsections = subsections;
        newPattern.sections.push(section);

      }
    }

    if (newPattern.sizeIds)
      newPattern.sizeIds = Utils.getArrayAsString(newPattern.sizeIds, '|')

    if (newPattern.category)
      newPattern.category = parseInt(newPattern.category.code)

    return newPattern
  }


  loadChartCustomSymbols(customSymbolsRaw, customSymbols) {

      if (!customSymbolsRaw)
        return {}
      var tmpArray = customSymbolsRaw.split(',')
      var symbols = {}
      for (var i = 0; i < tmpArray.length; i++) {
        var id = tmpArray[i].split('|')[0]
        var uniqueIdOnClient = tmpArray[i].split('|')[1]

        var filteredSymbols = customSymbols.filter(item => item.uniqueIdOnClient === uniqueIdOnClient)

        if (filteredSymbols.length == 0) {
          alert("CUSTOM SYMBOL NOT FOUND " + uniqueIdOnClient + '  >  ' + customSymbolsRaw + '\n\n')
          return
        }

        symbols[id] = this.getSymbolFromServerObject(filteredSymbols[0], id)

      }

      return symbols
    }


    getSymbolFromServerObject(serverSymbol, id) {


      if (serverSymbol.alreadyConverted)
        return serverSymbol
      var newObject = {}

      newObject.id = id
      newObject.idOnServer = serverSymbol.id
      newObject.uniqueIdOnClient = serverSymbol.uniqueIdOnClient
      newObject.content = serverSymbol.content
    newObject.rs = serverSymbol.rs
    newObject.ws = serverSymbol.ws
    newObject.rs_long = serverSymbol.rsLong
    newObject.ws_long = serverSymbol.wsLong
    newObject.t_img = {uri: SERVER_API_URL + serverSymbol.imgUrl }
    newObject.userIdOnServer = serverSymbol.userIdOnServer
    newObject.width = serverSymbol.width
    newObject.height = serverSymbol.height
    newObject.projectTool = serverSymbol.projectTool


    newObject.alreadyConverted = true;
    return newObject
    }

    async getAllCustomSymbols(customSymbolsUsedInChart = null) {


      let options = Object.assign({ method: "GET" }, null);
      const token = localStorage.getItem('token')
      options.headers = getHeaders2();
      //console.log("TOKEN", token)
      var response = await fetchClient.get(`${SERVER_API_URL}/chart_custom_symbols/getUserSymbols.json`)

      var allSymbols = response.data;

      //alert(JSON.stringify(allSymbols))


      var newArray = []

      var currentId = 9970

      if (customSymbolsUsedInChart) {
        for (const [key, value] of Object.entries(customSymbolsUsedInChart)) {
          if (parseInt(key) >= currentId)
            currentId++
        }
        currentId++
      }
       if(__DEV__) console.log("getAllCustomSymbols customSymbolsUsedInChart", customSymbolsUsedInChart)
      for (var i = 0; i < allSymbols.length; i++) {

        var currentSymbol = allSymbols[i]
        var currentSymbolUsedId = 0



        if (customSymbolsUsedInChart) {
          for (const [key, value] of Object.entries(customSymbolsUsedInChart)) {
            if(__DEV__) console.log("getAllCustomSymbols key=", key, value.uniqueIdOnClient)
          if (value.uniqueIdOnClient == currentSymbol.uniqueIdOnClient) {
            currentSymbolUsedId = parseInt(key)

             if(__DEV__) console.log("getAllCustomSymbols value.uniqueIdOnClient == currentSymbol.uniqueIdOnClient", key, value.uniqueIdOnClient)
            break
          }
        }
      }


      if (currentSymbolUsedId) {
        var newObject = this.getSymbolFromServerObject(currentSymbol, currentSymbolUsedId.toString())

      }
      else {
        var newObject = this.getSymbolFromServerObject(currentSymbol, currentId.toString())
        currentId++
      }



      newArray.push(newObject)
      }
      //alert(JSON.stringify(newArray) + '   >>>    ' + JSON.stringify(allSymbols))
      return newArray
    }


    getUnavailableSizeIds(section, selectedSubsection) {

      var unavailable = []
      for (var i = 0; i < section.subsections.length; i++) {


        var subsection = section.subsections[i]
        if (subsection.deleted == 1)
          continue
        if (!selectedSubsection || subsection.id != selectedSubsection.id) {
          unavailable = unavailable.concat([...subsection.sizeIds])
        }
      }

      return unavailable
    }



  // SIZES

	loadSizes() {

		this.sizesMap = {};
		var SIZES = getSizesArray();
		for (var i = 0; i < SIZES.length; i++) {
			var cat = SIZES[i]
			for (var y = 0; y < cat.sizes.length; y++) {

				var size = cat.sizes[y]
				size.category = cat.name
				this.sizesMap[size.id] = size

			}
		}
	}

	static getImgSizeArray() {
		return [
		{value:strings.small, code:1},
		{value:strings.normal, code:2},
		{value:strings.big, code:3}
	]
	}


	static getAvailableSections() {
		return [
		{
		  img: '/imgs/section_types/text.png',
		  text: strings.text,
		  type:'text',
		  selected: false
		},
		{
		  img: '/imgs/section_types/img.png',
		  text: strings.image,
		  type:'img',
		  selected: false
		},
		{
		  img: '/imgs/section_types/video.png',
		  text: strings.video,
		  type:'video',
		  selected: false
		},
		{
		  img: '/imgs/section_types/chart.png',
		  text: strings.chart,
		  type:'chart',
		  selected: false
		}


		]
	}
  // PATTERN

  static getOnePattern(id) {

    fetchClient.get(`${SERVER_API_URL}/patterns/${id}/get_pattern.json`)
    .then(data => {
      console.log("one pattern in then")
      console.log(data)
    })
    .catch(error => {
      console.log("in catch")
      alert('err ' + error.message);
      console.log(error) })
  }

  static getAllPatterns() {

    let options = Object.assign({ method: "GET" }, null);
    const token = localStorage.getItem('token')
    options.headers = Utils.getJSONHeaders(token);
    fetchClient.get(`${SERVER_API_URL}/patterns/get_patterns.json`, options)
    .then(data => {
      console.log("in then")
      console.log(data)
    })
    .catch(error => {
      console.log("in catch")
      alert('err ' + error.message);
      console.log(error) })
  }

  static createPreviewProject(name, tool, id, onProjCreated) {

     return ProjectService.createProject(ProjectUtils.createRcLinkedProject(name, tool, id), (event) => {
    }).then(onProjCreated)

  }

  static getPreviewProject(patternId, callBack) {
    let options = Object.assign({ method: "GET" }, null);
    const token = localStorage.getItem('token')
    options.headers = Utils.getJSONHeaders(token);
    fetchClient.get(`${SERVER_API_URL}/user_projects/get_project_from_pattern?pattern_id=${patternId}`, options)
    .then(response => {
      console.log("in then")
      console.log(response.data)
      callBack()

    })
    .catch(error => {
      console.log("in catch")
      alert('err ' + error.message);
      console.log(error) })


 }

  static createPattern(user, lang, chosenCategory, name, description, difficulty, sizesIds, projectTool) {
      var uniqueIdOnClient = Math.random().toString(36).slice(2)
      //var sizesAsStrings = ""
      console.log("##### size:", sizesIds)
     /* sizesIds.forEach((size) => {
        if (sizesAsStrings != "") {
          sizesAsStrings = sizesAsStrings + "|"
        }
        sizesAsStrings = sizesAsStrings + size
      })
      console.log("##### sizesAsStrings:", sizesAsStrings)*/
      var pattern = {
          author: user.name,
          appVersion: 1,
          lastSupportedVersion: 1,
          version:1,
          secretKey: Math.random().toString(36).slice(2),
          uniqueIdOnClient: uniqueIdOnClient,
          updatedAtOnClient: Utils.getTime(),
          editable: 1,
          lang: lang.code.toUpperCase(),
          category: chosenCategory,
          name: name,
          glossary: "",
          technics: "",
          gaugePoint: "",
          gaugeNbRows: 0,
          gaugeNbStitches: 0,
          parts:"",
          description: description,
          difficulty: difficulty,
          sizeIds: sizesIds,
          projectTool: projectTool,
        }
      return pattern;
  }

  static createPatternOnServer(pattern, onUploadProgress) {
    console.log("pattern being sent to server", pattern)

      var patternToSend = {"pattern": this.convertLocalPatternToServerPattern(pattern)}
      return fetchClient.post(`${SERVER_API_URL}/patterns.json`, patternToSend).then(onUploadProgress);

  }

  static updatePatternOnServer(pattern, onPatternUpdated) {

    if (typeof pattern.sizeIds == 'string' && pattern.sizeIds.indexOf('|') == -1)
      pattern.sizeIds = [pattern.sizeIds]

    pattern.updatedAtOnClient = Utils.getTime()
    var patternToSend = {"pattern": this.convertLocalPatternToServerPattern(pattern)}
    return fetchClient.put(`${SERVER_API_URL}/patterns/${pattern.id}.json`, patternToSend).then(response => {
      console.log("In then")
      console.log("pattern Sections updated Response",response)

      if (onPatternUpdated && response && response.data && response.data.id)
        onPatternUpdated(this.convertServerPatternToLocalPattern(response.data))
    }).catch(error => console.log(error));
  };

  static convertAndupdatePatternOnServer(pattern, onUploadProgress) {


    var newPattern = {...pattern}

    newPattern.sections = [];//{...pattern.pattern_sections}

    for (var i = 0; newPattern.pattern_sections && i < newPattern.pattern_sections.length; i++) {

      var section = {...newPattern.pattern_sections[i]}
      section.subsections = [...section.pattern_subsections]
      section.pattern_subsections = null;
      newPattern.sections.push(section);
     // newPattern.sections[i].subsections.push(subsection)//newPattern.pattern_sections[i].pattern_subsections
      //newPattern.sections[i].pattern_subsections = null
    }
    newPattern.pattern_sections = null;

    console.log("In Update Pattern")
    pattern.updatedAtOnClient = Utils.getTime()
    var patternToSend = {"pattern": newPattern}
    return fetchClient.put(`${SERVER_API_URL}/patterns/${pattern.id}.json`, patternToSend).then(response => {
      console.log("In then")
      console.log("pattern Sections updated Response",response)
      onUploadProgress(response)
    }).catch(error => console.log(error));
  };

  static publishOnServer(pattern, isPublic, callBack) {

    // this.setState({isLoading: true, publishError:false})
    var url = `${SERVER_API_URL}/patterns/${pattern.id}/publish.json?is_public=${isPublic}`
    return fetchClient.post(url)
    .then(response => {
      //console.log("published pattern", response.data);
      callBack(response.data)
    })
    .catch(error => console.log(error));

  }

  // static publishOnServer2(pattern, isPublic) {
  //   let options = Object.assign({ method: "POST" }, null);
  //   const token = localStorage.getItem('token');
  //   options.headers = Utils.getJSONHeaders(token);
  //   var url = `${SERVER_API_URL}/patterns/${pattern.id}/publish?is_public=${isPublic}`
  //   return fetch(url, options).then(response => {console.log("pattern published response",response)})
  //   .catch(error => console.log(error));

  // }

    static downloadPdf(sizeId, createdPattern, checkTime = true) {
      if (createdPattern.published != 1) {
        alert("You first need to publish your pattern")
        return
      }

    var uri = SERVER_API_URL + `/patterns/${createdPattern.id}/get_pdf_pattern.json?sizeId=${sizeId}&key=${createdPattern.secretKey ? createdPattern.secretKey : ''}`;
    console.log("dl pdf URI:", uri)
    window.open(uri);


  }


  // CATEGORIES & LANG
	static getAvailableCategories() {
		return [
{
  value:strings.head,
  code:1
},
{ value:strings.neck,
  code:2
},
{
  value:strings.torso,
  code:3
},
{
  value:strings.legs,
  code:4
},
{
  value:strings.feet,
  code:5
},
{
  value:strings.hands,
  code:6
},
{
  value:strings.accessories,
  code:7
},
{
  value:strings.pets,
  code:8
},
{
  value:strings.other,
  code:0
},

]
	}

  static country2emoji(country_code) {
    var OFFSET = 127397;
    var cc = country_code.toUpperCase();
    function _toConsumableArray(arr) {
        if (Array.isArray(arr)) {
            for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
                arr2[i] = arr[i];
            }
            return arr2;
        } else {
            return Array.from(arr);
        }
    }
    return /^[A-Z]{2}$/.test(cc) ? String.fromCodePoint.apply(String, _toConsumableArray([].concat(_toConsumableArray(cc)).map(function (c) {
        return c.charCodeAt() + OFFSET;
    }))) : null;
  }

  static getCountryFlagFromCode(country_code) {
  var code = country_code.toUpperCase();

  switch(code) {
    case "EN":
      return "/imgs/flags/england.png"
    case "FR":
      return "/imgs/flags/france.png"
    case "RU":
      return "/imgs/flags/russia.png"
      case "DE":
        return "/imgs/flags/germany.png"
      case "IT":
        return "/imgs/flags/italy.png"
      case "ES":
          return "/imgs/flags/spain.png"
      default:
        return false
  }
  }

  static getCategoryFromCode(code) {

    var CATEGORIES = this.getAvailableCategories()

    for (var i = 0; i < CATEGORIES.length; i++) {
      if (CATEGORIES[i].code == code)
        return CATEGORIES[i]
    }
    //return other if not found
    for (var i = 0; i < CATEGORIES.length; i++) {
      if (CATEGORIES[i].code == 0)
        return CATEGORIES[i]
    }
  }

  static getStringAsArray(str, separator = SEPARATOR_ITEM, innerSeparator = SEPARATOR_SUBITEM) {

		if (str == '')
			return []
		var array = str.split(separator)

		for (var i = 0; i < array.length; i++) {
			array[i] = array[i].split(innerSeparator)
		}

		return array
	}

  static createStringFromArray(array, separator = SEPARATOR_ITEM, innerSeparator = SEPARATOR_SUBITEM) {
    var arrayAsString = ""
    array.forEach((item, index) => {
      var newItem = item[0] + SEPARATOR_SUBITEM + item[1]
      if (arrayAsString != "") {
        arrayAsString = arrayAsString + SEPARATOR_ITEM
      }
      arrayAsString = arrayAsString + newItem
    })

    return arrayAsString
  }


	getLangItem(code) {

		if (!code)
			return LANGS[0]

      for (var i = 0; i < LANGS.length; i++) {
        if (LANGS[i].code.toLowerCase() == code.toLowerCase())
          return LANGS[i]
      }


	    return LANGS[0]
	  }

    // PARTS & SECTIONS

    getSpan(numAdded, text) {
  		return `&nbsp;<span class='sizeSpan' style="background-color:rgb(40,165,247); color: ${this.getRGBColorFromIndex(numAdded)})">&nbsp;${text}&nbsp;</span>&nbsp;`
  	}

    getDbSizeText(sizeTexts, sizeId, numAdded) {
  		for (var i = 0; i < sizeTexts.length; i++) {
  			if (sizeTexts[i].sizeId == sizeId && sizeTexts[i].numAdded == numAdded && sizeTexts[i].deleted != 1)
  				return sizeTexts[i]
  		}

  		//alert('NOT FOUND' + numAdded)
  		return null
  	}

    convertSizeTextToHtml(html, sizeTexts, selectedSizeId) {


  		//alert(JSON.stringify(html) + '    ' + JSON.stringify(sizes) + '    ' + selectedSizeId)

  		//selectedSize = "size1"
  	//	sizes = [{"size1": 'ma super chaine 1', size2:"ma super chaine 1 taille 2"}, {"size1": 'ma super chaine 2', size2:"ma super chaine 2 taille 2"}]
  		//html = 'abc<span>abc</span><h1>h1</h1> <span>span2</span>hello'
  		var str = ''
  		var begin = 0
  		var endSpan = 0
  		var beginSpan
  		var beginSize


       console.log("colorId ", html)
  		//var sizeIndex = 0

  		html = Utils.safisize(html)
  		for (begin = 0; begin < html.length; ) {

  			beginSize = html.indexOf(PLACEHOLDER_SIZE, begin)

  			if (beginSize == -1) {

  				str += html.substr(begin, html.length - begin)
  				break
  			}
  			else {
  				var colorId = parseInt(html.substr(beginSize + PLACEHOLDER_SIZE.length, 3))


  				 console.log("colorId ", colorId, html.substr(beginSize + PLACEHOLDER_SIZE.length, 3))
  			}


  			str += html.substr(begin, beginSize - begin)

  			var dbSizeText = this.getDbSizeText(sizeTexts, selectedSizeId, colorId)

			var textSize = "NOT SET"
  			if (dbSizeText) {
  				if (dbSizeText.text)
  					textSize = Utils.safisize(dbSizeText.text)
  				else
  					textSize = Utils.safisize(dbSizeText.content)
  			}
  			//var textSize = dbSizeText ? dbSizeText.content : "NOT SET"
  			str += this.getSpan(colorId, textSize)
  			//sizeIndex++
  			begin = beginSize + PLACEHOLDER_SIZE.length + 3

  		}


  		console.log('str=', str)


  		return str
  	}

    static getSubsectionName(section, pattern) {

      if (section.sectionType === 'text') {

        if (!section.subsections)
          return strings.text
        for (var i = 0; i < section.subsections.length; i++) {

          var subsection = section.subsections[i]
          if (subsection.position === 0 && subsection.deleted !== 1) {

            if (!subsection.content)
              return strings.text

            if (subsection.content.indexOf(PLACEHOLDER_SIZE) !== -1) {
              return this.convertSizeTextToHtml(subsection.content, subsection.sizeTexts, pattern.sizeIds[0])
            }


            var text = decodeURI(subsection.content)
            text = Utils.replaceAll(text, '<div>', '')
            text = Utils.replaceAll(text, '<br>', '')
            text = Utils.replaceAll(text, '<br/>', '')
            text = Utils.replaceAll(text, '<br />', '')
            text = Utils.replaceAll(text, '</div>', '')
            return text.substring(0, 40) + '...'
          }
        }
      }
      else if (section.sectionType === 'chart') {
        return strings.chart
      }
      else if (section.sectionType === 'img') {
        return strings.image
      }
      else if (section.sectionType === 'video') {
        return strings.video
      }

      return strings.text
    }

  static constructSectionsAndParts(pattern) {
    console.log("constructSectionsAndParts")

    var currentPart = -1;
    console.log("Parts", pattern.parts)
    var parts = (pattern.parts == '' || pattern.parts == null) ? [] : pattern.parts.split('±|±')
    var list = []
    if (!pattern.sections)
      pattern.sections = []

    if (pattern.sections) {
      console.log("Sections", pattern.sections)
      var sections = [...pattern.sections].sort(function(a, b) {
        return a.numPart - b.numPart || a.position - b.position;
      });

      for (var i = 0; i < sections.length; i++) {

        var section = sections[i]

        if (section.deleted == 1)
          continue

        if (section.numPart > currentPart) {

          while (section.numPart > currentPart) {
            list.push({index: list.length, type: 'part', name: parts[currentPart + 1], subtitle: strings.part + " " + (currentPart + 2), numPart: currentPart + 1 })

            currentPart++;
          }
        }


        let sectionName = this.getSubsectionName(section, pattern)


        list.push({index: list.length, type: section.sectionType, name: sectionName, section: section, imgPath: section.imgPath})

      }

      while (currentPart < parts.length - 1) {
        list.push({index: list.length, type: 'part', name: parts[currentPart + 1], subtitle: strings.part + " " + (currentPart + 2), numPart: currentPart + 1 })

        currentPart++;
      }

      //alert(JSON.stringify(list))
      console.log("List (from Utils)", list)
      return list
    }
    return []

  }

  static getSectionFromSectionType(type) {

		var SECTION_TYPES = this.getAvailableSections()

		for (var i = 0; i < SECTION_TYPES.length; i++) {
			if (SECTION_TYPES[i].type == type)
				return SECTION_TYPES[i]
		}
  }

  static createSection(pattern, name, type, numPart, position, uniqueIdOnClient = Math.random().toString(36).slice(2)) {
		var section

    section = {
      name: name,
      content: "",
      sectionType: type,
      numPart: numPart,
      imgAttributes: "",
      position: position,
      subsections: [],
      uniqueIdOnClient: uniqueIdOnClient,
      updatedAtOnClient: Utils.getTime()
    }



		return section
	}

  static createSubsection(section, name, content, rowStruct, type, position, uniqueIdOnClient = Math.random().toString(36).slice(2)) {

		var subsection
    subsection = {
      name: name,
      content: content,
      rowStruct: rowStruct,
      sizeIds: [],
      position: position,
      subsectionType: type,
      uniqueIdOnClient: uniqueIdOnClient,
      updatedAtOnClient: Utils.getTime()
    };

    return subsection
  }

  duplicateSubsection(section, dbSubsection) {

      //alert(JSON.stringify(newSubsection))
      var newSubsection = JSON.parse(JSON.stringify(dbSubsection));
      var maxPosition = 0

      for (var i = 0; i < section.subsections.length; i++) {
        if (section.subsections[i].position > maxPosition)
          maxPosition = section.subsections[i].position
      }


      newSubsection.id = null

      newSubsection.uniqueIdOnClient = Math.random().toString(36).slice(2);
      newSubsection.updatedAtOnClient = Utils.getTime();
      newSubsection.updatedAtOnServer = 0;
      if (newSubsection.subsectionType == 'chart')
        newSubsection.position = -1
      else
        newSubsection.position = maxPosition + 1
      newSubsection.sizeTexts = []

      newSubsection.sizeIds = []
      for (var i = 0; i < dbSubsection.sizeIds.length; i++) {
        newSubsection.sizeIds.push(dbSubsection.sizeIds[i])
      }
      //newSubsection.sizeIds = [...dbSubsection.sizeIds]

      section.subsections.push(newSubsection)

      for (var i = 0; dbSubsection.sizeTexts && i < dbSubsection.sizeTexts.length; i++) {

        var newSizeText = JSON.parse(JSON.stringify(dbSubsection.sizeTexts[i]));
        newSizeText.id = null
        newSizeText.uniqueIdOnClient = Math.random().toString(36).slice(2);
        newSizeText.updatedAtOnClient = Utils.getTime();
        newSizeText.updatedAtOnServer = 0;


        newSubsection.sizeTexts.push(newSizeText)
      }


    return newSubsection
  }



  static getSection(pattern, uniqueIdOnClient) {
    for (var i = 0; i < pattern.sections.length; i++) {

      if (pattern.sections[i].uniqueIdOnClient === uniqueIdOnClient)
        return pattern.sections[i]
    }
    return null
  }

  static getSubsection(section, uniqueIdOnClient) {
    for (var i = 0; i < section.subsections.length; i++) {

      if (section.subsections[i].uniqueIdOnClient === uniqueIdOnClient)
        return section.subsections[i]
    }
    return null
  }

  static createYarn(name, companyName, weight, color, quantities) {
    var yarn
    yarn = {
      name: name,
      companyName: companyName,
      color: color,
      nbSkeins: 0,
      totalLengthMeters:0,
      yarnIdOnRavelry: 0,
      yarnWeightRavelryId: weight,
      quantities: quantities, //"[{"sizeId":"newbornOneSize","quantity":4}]"
      lengthUnits: "meters",
      useTotals: 0,
      deleted: 0,
      uniqueIdOnClient: Math.random().toString(36).slice(2),
      updatedAtOnClient: Utils.getTime(),
    }
    return yarn
  }

//   {
//     "id": 13868,
//     "sizeIdOnRavelry": 399,
//     "length": 14,
//     "typeNeedle": "straight",
//     "sizeUS": "00000",
//     "sizeMetric": "1",
//     "deleted": 0,
//     "uniqueIdOnClient": "n9p60fl1sh",
//     "updatedAtOnClient": 1637154447251,
//     "url": "https://app.rowcounterapp.com/pattern_needles/13868.json"
// }

static createNeedle(sizeIdOnRavelry, length, typeNeedle, sizeUS, sizeMetric) {
  var needle
  needle = {
    sizeIdOnRavelry: sizeIdOnRavelry,
    length: length,
    typeNeedle: typeNeedle,
    sizeUS: sizeUS,
    sizeMetric: sizeMetric,
    deleted: 0,
    uniqueIdOnClient: Math.random().toString(36).slice(2),
    updatedAtOnClient: Utils.getTime(),
  }
  return needle
}

}



