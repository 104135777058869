import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import { SERVER_API_URL, APP_NAME } from '../../config/Constants'
import { Link } from 'react-router-dom';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import Dropdown from 'react-bootstrap/Dropdown'
import { getAllSizes, getSizesFromIds } from "../../config/sizes"
import CheckBox from "../../components/CheckBox"
import AlertModal from "../../components/generator/AlertModal";
import ProjectUtils from '../../utils/projectUtils'
import { HEADER_HEIGHT } from '../../config/Constants'


import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function InitPattern() {

  const history = useHistory();
  const location = useLocation();




  const {token, setToken} = useToken();
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [linkedProj, setLinkedProj] = useState();

  const {user, setUser} = useUser("");
  const [projectTool, setProjectTool] = useState(false);
  const [lang, setLang] = useState(false);
  const [chosenCategory, setChosenCategory] = useState(false);

  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [difficulty, setDifficulty] = useState();

  const [selectedSizes, setSelectedSizes] = useState();

  const [sizes, setSizes] = useState();
  const [selectedSizeCategory, setSelectedSizeCategory] = useState();
  const [savedPattern, setSavedPattern] = useState()
  const [previewProject, setPreviewProject] = useState()

  const [showAlert, setShowAlert] = useState(false)
  const [missingInfos, setMissingInfos] = useState(false)

  const selectedColor = "#FECB2E";
  const availableCategories = PatternUtils.getAvailableCategories()
  const sizeIds = false

  useEffect(() => {
    let allSizes = getAllSizes(sizeIds)
    setSizes(allSizes)


    if (location.state && location.state.pattern) {
      console.log("###### pattern passed via history sizeIds", location.state.pattern.sizeIds)
      const pattern = location.state.pattern
      setSavedPattern(pattern)
      setProjectTool(pattern.projectTool)

      var patternLang = LANGS.filter(lang => lang.code.toLowerCase() == pattern.lang.substring(0,3).toLowerCase())[0]

      setLang(patternLang)

      setChosenCategory(PatternUtils.getCategoryFromCode(pattern.category))
      setName(pattern.name)
      setDescription(pattern.description)
      setDifficulty(pattern.difficulty)

      var updatedSizes = pattern.sizeIds ? getAllSizes(pattern.sizeIds) : []
      //updatedSizes = []
      //alert(JSON.stringify(updatedSizes))
      setSizes(updatedSizes)

    }

  }, [savedPattern])


  const selectsize = (size) => {
    size.selected = !size.selected

    setSizes([ ...sizes])
  }

  const getSelectedSizesIds = () => {
    var array = []
    for (var i = 0; i < sizes.length; i++) {
      var cat = sizes[i]

      for (var y = 0; y < cat.sizes.length; y++) {
        if (cat.sizes[y].selected)
          array.push(cat.sizes[y].id)
      }
    }
    console.log("getSelectedSizesIds", array)
    return array

  }

  const onSave = async () => {
    var sizesIds = getSelectedSizesIds()

    var chosenProps = PatternUtils.createPattern(user, lang, chosenCategory, name, description, difficulty, sizesIds, projectTool)


    PatternUtils.createPatternOnServer(chosenProps, (response) => {
      var pattern =  PatternUtils.convertServerPatternToLocalPattern(response.data)
      console.log("created proj server data", pattern)
      console.log("created proj server data sizeIds", pattern.sizeIds)
      //pattern.sizeIds = sizesIds
      console.log("onSaved initPattern - createPatternOnServer response", pattern.sizeIds)

      PatternUtils.createPreviewProject(pattern.name,pattern.projectTool, pattern.id, (response) => {
        var project = response.data
        setPreviewProject(project)


        history.push({
          pathname: `/created-pattern-details/${pattern.id}`,
          state: { pattern: pattern, previewProject: project }
        })
      })
    })
    .catch((error) => {
      alert(error.message)
    });

  }

  const onUpdate = () => {
    var updatedPattern = savedPattern
    var sizesIds = getSelectedSizesIds()

    updatedPattern.updatedAtOnClient = Utils.getTime()
    updatedPattern.projectTool = projectTool
    updatedPattern.lang = lang.value.substring(0,2)
    updatedPattern.category = chosenCategory.code
    updatedPattern.name = name
    updatedPattern.description = description
    updatedPattern.difficulty = difficulty

    updatedPattern.sizeIds = sizesIds

    PatternUtils.updatePatternOnServer(updatedPattern, (response) => {

      updatedPattern = response;
      history.push({
        pathname: `/created-pattern-details/${updatedPattern.id}`,
        state: { pattern: updatedPattern }
      })
    })
  }

  const checkAllData = () => {
    var sizesIds = getSelectedSizesIds()

    var currentMissingInfos = []
    if (!name || !name[0]) { currentMissingInfos.push(strings.name) }
    if (!lang) { currentMissingInfos.push(strings.patternCreator.language) }
    if (!sizesIds || !sizesIds[0]) { currentMissingInfos.push(strings.patternCreator.sizes)}
    if (!chosenCategory) { currentMissingInfos.push(strings.patternCreator.category)}
    if (!projectTool ) { currentMissingInfos.push(strings.tools)}
    setMissingInfos(currentMissingInfos)
    if (currentMissingInfos[0]) {
      setShowAlert(true)
    } else {
      onSave()
    }
  }

  const renderAlertModal = () => {

    var contentText = strings.patternCreator.missingInfosText
    contentText += "<ul>"
    missingInfos.forEach((info) => {
      contentText += `<li> ${info} </li>`
    })
    contentText += "</ul>"
    return (
      <AlertModal
      title={strings.patternCreator.missingInfosTitle}
      text={contentText}
      onOk={() => {setShowAlert(false); setMissingInfos([])}}
      onDelete={false}
      />
    )
  }

  const renderDifficulty = () => {
    return (
      <View style={{width: 600, flexDirection: "row", marginVertical: 16, justifyContent:"flex-start", alignItems:"flex-start"}}>
        <Text style={[styles.title2, {marginRight: 8, marginTop: 4}]}>{strings.patternCreator.difficulty}</Text>
          <TouchableOpacity
            onPress={() => setDifficulty(1)}>
            <Image style={{width: 24, height: 24, tintColor: difficulty > 0 ? selectedColor : "white", marginRight: 4}} urlOnServer={'/imgs/yarn.png'} />
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setDifficulty(2)}>
            <Image style={{width: 24, height: 24, tintColor: difficulty > 1 ? selectedColor : "white", marginRight: 4}} urlOnServer={'/imgs/yarn.png'}/>
          </TouchableOpacity>
          <TouchableOpacity
            onPress={() => setDifficulty(3)}>
            <Image style={{width: 24, height: 24, tintColor: difficulty > 2 ? selectedColor : "white", marginRight: 4}} urlOnServer={'/imgs/yarn.png'}/>
          </TouchableOpacity>
      </View>
    )
  }

  const renderDetails = () => {

    let myBgColor = "#266D9C";
    return (
      <View style={{flex: 1, flexDirection:'row', justifyContent: "space-between", paddingVertical: 16}}>
      <View style={{marginRight: 20, zIndex: 10000}}>
        <Dropdown >
          <Dropdown.Toggle style={{width:290, zIndex: 10000, backgroundColor:"#266D9C", borderColor: "white", borderWidth: 1}} id="dropdown-custom-1">{lang ? lang.value : strings.select_language}</Dropdown.Toggle>
          <Dropdown.Menu  className="dropdown-init-pattern">
          {
            LANGS.map((lang) => {
              return (<Dropdown.Item style={{}} onClick={() => setLang(lang)}>{lang.value}</Dropdown.Item>)
            })
          }
          </Dropdown.Menu>
        </Dropdown>
      </View>

      <View style={{zIndex: 10000}}>
        <Dropdown >
          <Dropdown.Toggle style={{width:290, backgroundColor:"#266D9C", borderColor: "white", borderWidth: 1}} id="dropdown-custom-2">{chosenCategory ? chosenCategory.value : strings.patternCreator.selectCat}</Dropdown.Toggle>
          <Dropdown.Menu  className="dropdown-init-pattern">
          {
            availableCategories.map((cat) => {
              return (<Dropdown.Item onClick={() => setChosenCategory(cat)}>{cat.value}</Dropdown.Item>)
            })
          }
          </Dropdown.Menu>
        </Dropdown>
      </View>
  </View>

    )
  }

  const renderProjectTool = () => {
    var txtColor = "rgba(255,255,255,0.8)";
    var respWidth = Utils.isLandscape() ? Dimensions.get('window').height / 7 : Utils.isTablet() ? Dimensions.get('window').width / 8.5 : Dimensions.get('window').width / 4.3
    var picSize = 100

    const allTools = [
      {
        num: 1,
        pic: '/imgs/straight-2.png',
        name: strings.knitting_mode,
      },
      {
        num: 2,
        pic: '/imgs/crochet-2.png',
        name: strings.crochet_mode,
      },
      {
        num: 3,
        pic: '/imgs/machine.png',
        name: "Machine",
      },
      {
        num: 4,
        pic: '/imgs/loom.png',
        name: "Loom",
      },
    ]
    return (

      <View style={{flex: 2,marginTop: 16, marginBottom: 90, zIndex: -1}}>

        <Text style={[styles.title, {marginTop:Dimensions.get('window').height < 600 ? 4 : 8}]}>{strings.type}:</Text>

        <View style={{flexDirection:'row', alignItems:'center', justifyContent: "center"}}>
          {
            allTools.map((givenTool) => {
              return (
                <TouchableOpacity
                onPress={() => setProjectTool(givenTool.num)}
                style={{flex: 1, flexDirection: "column", alignItems:'center', justifyContent: "center",}}>
                <Image
                style={{width: picSize, height: picSize, marginTop: 10}}
                urlOnServer={givenTool.pic}/>
                <Text
                style={{textAlign: "center", alignSelf: "center", color: projectTool == givenTool.num ? selectedColor : txtColor, position: "absolute", top: 110, paddingBottom: 10}}>{givenTool.name}</Text>
            </TouchableOpacity>
              )
            })
          }
        </View>

      </View>
      )
  }

  const renderSizeCat = (sizecategory) => {
    var borderStyle = {flex:1,margin:5,padding:5,borderRadius:16,borderWidth:0.5,borderColor:"rgba(255,255,255,0.8)"}
    var textStyle = {fontSize:14,alignSelf:"center", textAlign:"center", color: "white"}
    if (selectedSizeCategory && selectedSizeCategory.catId == sizecategory.catId) {
      var borderStyle = {flex:1,margin:5,padding:5,borderRadius:16,borderWidth:1,borderColor:"#FECB2E"}
      var textStyle = {fontSize:14,alignSelf:"center", textAlign:"center",color:"#FECB2E",fontWeight:"bold"}
    }
    return(
        <TouchableOpacity
            onPress={() => setSelectedSizeCategory(sizecategory)}
            style={borderStyle}>
            <Text
            style={textStyle}>{sizecategory.name}</Text>
    </TouchableOpacity>
      )
  }

  const renderSize = (size) => {

    return(
      <TouchableOpacity
          onPress= {() => selectsize(size)}
          style= {{width: 200,flexDirection:"row",marginVertical:8}}>
        <CheckBox
          checked={size.selected}
          tintColor={"#FECB2E"}
          style={{color:"rgba(255,255,255,0.8)" }}
          onPress={() => selectsize(size)}/>
        <Text
          style={{flex:1, color: "white", fontSize:14, textAlign:"left",marginHorizontal:8}}>
          {size.text}
        </Text>
      </TouchableOpacity>
      )
  }

  const renderSelectSizes = () => {
    return (
      <div id="chooseSizeCat" style={{width: 600}}>
        <Text
          style={styles.title}>
          {strings.size}
        </Text>
        <FlatList
          style={{ margin: 10}}
          data={sizes}
          extraData={selectedSizes}
          numColumns={3}
          keyExtractor={(item, index) => index}
          renderItem={item => renderSizeCat(item.item)}
        />
      </div>
    )
  }

  const renderSelectedCatSizes = () => {
    return (
      <div style={{width: 600}}>
        <Text
          style={styles.title}>
          {selectedSizeCategory.name}
        </Text>
        <FlatList
          style={{ marginHorizontal: 20,marginTop: 16}}
          contentContainerStyle={{flex:1}}
          data={selectedSizeCategory.sizes}
          extraData={selectedSizeCategory}
          numColumns={3}
          keyExtractor={(item, index) => index}

          renderItem={item => renderSize(item.item)}
        />
      </div>
    )
  }

  const renderSaveBtn = () => {
    if (savedPattern) {
      return (
        <button className="ImportPatternBtn" onClick={() => onUpdate()}>
            {strings.patternCreator.updatePattern}
        </button>
      )
    } else {
      return (
        <button className="ImportPatternBtn" onClick={() => checkAllData()}>
            {strings.patternCreator.createPattern}
        </button>
      )
    }
  }


  return(


    <View style={{flexDirection:'column', backgroundColor: "#266D9C", width: Dimensions.get('window').width, minHeight: height - 72}}>

     <div className="container mt-5 rcpattern-container ">
       <h2 className="text-white" >{savedPattern ? strings.patternCreator.updatePattern : strings.patternCreator.createPattern}</h2>
       <div className="mt-4" >
       {showAlert && renderAlertModal()}
          <View style={{flex: 1, flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
            <Text style={[styles.title, {marginTop:16}]}>{strings.pattern_name}:</Text>
            <TextInput
                    containerStyle={{height: 60,flex: 1}}
                    style={[styles.input, {height: 40, borderRadius: 20, width: 600 }]}
                    multiline={false}
                    value={name}
                    onChangeText={(name) => setName(name)}
                    returnKeyType="done"
                  />

            <Text style={[styles.title, {marginTop:16}]}>{strings.description}:</Text>
            <TextInput
                    containerStyle={{flex:1 ,height: 100,}}
                    style={[styles.input, {height: 100, borderRadius: 12, padding: 12, width: 600 }]}
                    multiline={true}
                    value={description}
                    onChangeText={(desc) => setDescription(desc)}
                    returnKeyType="done"
                  />
            {renderDifficulty()}
            {renderDetails()}

            {renderProjectTool()}
            {renderSelectSizes()}
            {selectedSizeCategory && renderSelectedCatSizes()}

          </View>




      </div>
      <div className="NewPatternBtns" >
            {renderSaveBtn()}
          </div>
    </div>

    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" ,
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)",
    textAlign: "left",
    selfAlign: "flex-start",
    flex: 1,
    width: 600,
  },
  title2: {
    color: "rgba(255,255,255,0.8)",
    textAlign: "left",
    selfAlign: "flex-start",
  },

  menuOptionText: {
    color:'#888',
    textAlign:'center'
  },

  menuElem: {
    borderBottomWidth:0.5, padding:12, borderColor:"#ccc"
  }

});
