import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown,faSortUp, faTrashAlt, faCopy, faEdit } from '@fortawesome/free-solid-svg-icons'
import DraggableList  from 'react-draggable-list'
import AlertModal from "../../components/generator/AlertModal";

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function ContentList(props) {
  const history = useHistory();
  const [list, setList] = useState(props.list)
  const [createdPattern, setCreatedPattern] = useState(props.createdPattern)


  //const [maxNumPart, setMaxNumPart] = useState()
  const copyItem = props.copyItem
  const deleteItem = props.deleteItem
  const moveItem = props.moveItem
  const getMaxPosition = props.getMaxPosition
  const setShowAlertChart = props.setShowAlertChart
  const editPart = props.editPart

  const getMaxNumPart = (pattern) => {
    var MaxNumPart = (pattern.parts == '' || !pattern.parts) ? -1 : pattern.parts.split('±|±').length - 1
    //console.log("max num parts from edit pattern", MaxNumPart)
    return MaxNumPart
  }

  useEffect(() => {
    // if (list && list[4] && list[4].section.subsections[0]) {
    //   console.log("text element from sub section",  list[4].section.subsections[0].content)
    // }
    //setMaxNumPart(getMaxNumPart(props.createdPattern))
    setList(props.list)

  }, [props.list])

  const getMaxNumPartFromList = (myList) => {
    var max = -1
    if (myList[0]) {
      myList.forEach((item) => {
        if (item.type == "part") {
          max += 1
        }
      })
    }
    return max
  }

  const editItem = (item, type) => {
    if (type == "part") {
      console.log("Part to edit", item)
      editPart(item)
    } else {

      var pathname
      var subsection = null
      if (type == 'text')
        pathname = `/create-section/`
      else if (type == 'text')
        pathname = `/create-image-section/`
      else {
        pathname = `/edit-chart/`

        var nbSubsections = 0
          
        for (var i = 0; i < item.section.subsections.length; i++) {
          if (item.section.subsections[i].deleted != 1) {
            subsection = item.section.subsections[i]
            nbSubsections++
          }
        }
      

        if (nbSubsections === 1 && subsection && subsection.sizeIds.length === createdPattern.sizeIds.length) {
          pathname = `/edit-chart/`

        history.push({
            pathname: '/edit-chart',
            state: { patternId: createdPattern.id, sectionUniqueId:item.section.uniqueIdOnClient, subsectionUniqueId: subsection.uniqueIdOnClient }
          })
          return
        }
        else
          pathname = `/chart-list/`

        
      }

     
      history.push({
        pathname: pathname,
        state: { pattern: createdPattern, type: type, section:item.section, subsection: subsection, edit:true }
      })
    }
  }


  const renderActions = (item, index, position) => {
    var maxNumPart = getMaxNumPartFromList(list)
    var btnSize = 10
    var color = "rgba(255,255,255,0.8)"
    var maxPosition = -1
    if (item.type != "part") {
      var maxPosition = getMaxPosition(item.section.numPart)
    }
    return (
      <View style={{flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
        {
          ( (item.type == "part" && item.numPart == 0) || (item.section && item.section.numPart == 0 && item.section.position == 0) ) ? 
          <View/> :
          <TouchableOpacity
          onPress={() => moveItem(item, -1)}>
            <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faSortUp } />
          </TouchableOpacity>
        }
        { ((item.type != "part" && item.section.position < maxPosition ) || (item.type != "part" && item.section.numPart != maxNumPart && item.section.position == maxPosition )|| (item.type == "part" && item.numPart < maxNumPart)) &&
          <TouchableOpacity
          onPress={() => moveItem(item, 1)}>
            <FontAwesomeIcon style={{color: color, marginRight: 16}} size={btnSize} icon={ faSortDown } />
          </TouchableOpacity>
        }
        <TouchableOpacity
        onPress={() => copyItem(item, index)}>
          <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faCopy } />
        </TouchableOpacity>
        <TouchableOpacity
        onPress={() => deleteItem(item, index)}>
          <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faTrashAlt } />
        </TouchableOpacity>

      </View>
    )
  }

  const orderTextSubsections = (item) => {
    var subsections = item.section.subsections
    var list = []
    if (subsections.length > 0) {
      for (var i = 0; i < subsections.length; i++) {
      subsections.forEach((sub) => {
        if (sub.position == i && sub.deleted != 1) {
          list.push(sub)
        }
      })
      }
    }
    return list
  }

  const renderItem = (item, index, move, moveEnd, isActive) => {
    var maxNumPart = getMaxNumPartFromList(list)
    var iconSize = 20
    var iconViewSize = 40

    var btnSize = 10
    var color = "rgba(255,255,255,0.8)"


    if (item.type == 'part') {
      return (
      <View style={{flexDirection:'row', alignItems:'center', marginHorizontal:12, marginTop: 24}}>
        <TouchableOpacity
          onPress={() => editItem(item, item.type)}>
          <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faEdit } />
        </TouchableOpacity>
        <View style={{ paddingTop:16, flex:1 }}>
            <p className="DetailTitle">{item.subtitle}: {item.name}</p>
            {/* <Text style={styles.title}>{"NumPart: " + item.numPart + ", MaxNumPart:" + maxNumPart + " "}</Text> */}
        </View>
        {renderActions(item,index,item.numPart)}
      </View>
        )
    }
    else if (item.type == 'img') {
      return (

        <View style={styles.row}>

            <View style={{flexDirection:"row", justifyContent: "flex-start", alignItems: "center", }}>
            <TouchableOpacity
              onPress={() => editItem(item, item.type)}>
              <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faEdit } />
            </TouchableOpacity>
              {item.imgPath != '' && 
              <Image source={{uri: Utils.getAttachementUrl(item.section.imgAttachmentId)}} style={{width:40, height:40, marginRight: 8, borderRadius:20}}
                />
                }
                {/* <Text style={styles.title}>{"NumPart: " + item.section.numPart + ", Position:" + item.section.position + " "}</Text> */}
                <Text style={styles.title}>{ item.name }</Text>
            </View>
          {renderActions(item, index, item.section.position)}

        </View>


        )
    } else if (item.type == 'video') {
      return (
      <View style={styles.row}>

        <View style={{flexDirection:"row", justifyContent: "flex-start", alignItems: "center", }}>
          <TouchableOpacity
            onPress={() => editItem(item, item.type)}>
            <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faEdit } />
          </TouchableOpacity>
          <View style={{marginRight: 8, width: iconViewSize, height: iconViewSize, backgroundColor:"#2ba5f7", borderRadius: iconViewSize / 2, justifyContent:'center', alignItems:'center', opacity:0.7}}>
            <Image urlOnServer={PatternUtils.getSectionFromSectionType(item.type).img} style={{width:iconSize, height:iconSize}}
                resizeMode='contain'/>
          </View>
          {/* <Text style={styles.title}>{"NumPart: " + item.section.numPart + ", Position:" + item.section.position + " "}</Text> */}
          <Text style={styles.title}>{item.name}</Text>
        </View>
        {renderActions(item, index, item.section.position)}

      </View>
        )
    } else if (item.type == 'chart') {
      return (
      <View style={styles.row}>

        <View style={{flexDirection:"row", justifyContent: "flex-start", alignItems: "center", }}>
          <TouchableOpacity
            onPress={() => editItem(item, item.type)}>
            <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faEdit } />
          </TouchableOpacity>
          <View style={{marginRight: 8, width: iconViewSize, height: iconViewSize, backgroundColor:"#2ba5f7", borderRadius: iconViewSize / 2, justifyContent:'center', alignItems:'center', opacity:0.7}}>
            <Image urlOnServer={PatternUtils.getSectionFromSectionType(item.type).img} style={{width:iconSize, height:iconSize}}
                resizeMode='contain'/>
          </View>

          <Text style={styles.title}>{item.name}</Text>
        </View>
        {renderActions(item, index, item.section.position)}

      </View>
        )
    }
    else if (item.type == "text") {
      var orderedSubs = orderTextSubsections(item)
      return (
        <View style={styles.row}>
          <View key={props.list} style={{flexDirection:"row", justifyContent:"flex-start", alignItems: "center"}}>
            <TouchableOpacity
              onPress={() => editItem(item, item.type)}>
              <FontAwesomeIcon style={{color: color, marginRight: 8}} size={btnSize} icon={ faEdit } />
            </TouchableOpacity>
            <View style={{marginRight: 8, width: iconViewSize, height: iconViewSize, backgroundColor:"#2ba5f7", borderRadius: iconViewSize / 2, justifyContent:'center', alignItems:'center', opacity:0.7}}>
              <Image urlOnServer={PatternUtils.getSectionFromSectionType(item.type).img} style={{width:iconSize, height:iconSize}}
              resizeMode='contain'/>
            </View>
            <View>
            {/* <Text style={styles.title}>{"NumPart: " + item.section.numPart + ", Position:" + item.section.position}</Text> */}
            <Text style={styles.title}>
              {(orderedSubs[0] && 
                orderedSubs[0].content) ?
                orderedSubs[0].content.substring(0,42) + "..." : "-" }
            </Text>
            {
              orderedSubs[1] && 
              renderSubPreview(orderedSubs[1])
            }
            {
              orderedSubs[2] && 
              renderSubPreview(orderedSubs[2])
            }
            {
              orderedSubs[3] && 
              <Text style={[styles.title, {fontSize:10, marginTop: 4}]}>...</Text>
            }
            </View>
          </View>
          {renderActions(item, index, item.section.position)}
        </View>
      )
    }

  }



  const renderSubPreview = (subsection) => {
    if (subsection.content) {
      return (
        <Text style={[styles.title, {fontSize:10, marginTop: 4}]}>{subsection.content.substring(0,24) + "..."}</Text>
      )

    }
  }
  const renderAllContentBis = () => {
    return (
        list.map((item, index) => {
          return (
            renderItem(item, index)
          )
        })
      )
  }

  return(
 

    <View key={"all_rc_content" + JSON.stringify(list)} style={{flex: 1, paddingHorizontal: 12}}>
      {renderAllContentBis()}
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" , 
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)", 
    textAlign: "left",
    selfAlign: "flex-start",
  },
  editImg: {tintColor:'rgba(255,255,255,0.8)', width:8, height:20},
  row: {
    flexDirection:'row', 
    flex: 1,
    justifyContent: "space-between",
    alignItems: "center",
    borderColor:'rgba(255,255,255,0.6)', 
    borderBottomWidth:0.5, 
    paddingVertical:12,
    marginHorizontal:12,
  } ,

});