import React from 'react';
import useToken from '../../hooks/useToken';
import useUser from '../../hooks/useUser';
import { useState, useEffect } from 'react';
import PatternUtils from "../../generator/PatternUtils";
import { LANGS } from "../../generator/PatternUtils";
import Image from "../../components/Image";
import { useParams, useHistory, useLocation } from "react-router-dom";
import strings from "../../config/strings";
import Utils from '../../utils/utils';

import {
  ScrollView,
  View,
  TextInput,
  Text,
  TouchableOpacity,
  StyleSheet,
  Platform,
  Alert,
  FlatList,
  ActivityIndicator,
  Dimensions
} from 'react-native';

export default function ManagePanel(props) {
  const [pattern, setPattern] = useState(props.pattern)
  const [panelBtns, setPanelBtns] = useState( [
    // {
    //   id: "more",
    //   pic: "/imgs/menu.png",
    //   action: setToEdit,
    //   title: "More",
    //   enabled: false,
    // },
    {
      id: "delete",
      pic: "/imgs/bin.png",
      action: props.delete,
      title: strings.delete,
      enabled: true,
    },
    {
      id: "download",
      pic: "/imgs/download.png",
      action: props.onDownloadPdf,
      title: strings.patternCreator.dlPdf,
      enabled: false,
    },
    {
      id: "publish",
      pic: "/imgs/check.png",
      action: props.showAlertPublish,
      title: strings.patternCreator.generate,
      enabled: false,
    },
    {
      id: "preview",
      pic: "/imgs/eye.png",
      action: props.seePreview,
      title: strings.preview,
      enabled: false,
    },
    {
      id: "edit",
      pic: "/imgs/pencil.png",
      action: props.editContent,
      title: strings.edit,
      enabled: true,
    },
  ])
  var allPanelBtns =

  useEffect(() => {
  var passedPattern = props.pattern
  //console.log("pattern passed to panel", passedPattern)
  if (passedPattern) {
    var updatedPanel = [...panelBtns]
    if (passedPattern.sections && passedPattern.sections[0]) {
      let index = updatedPanel.findIndex((item) => item.id == "download")
      updatedPanel[index].enabled = true
    }
    if ((passedPattern.published == 1 && passedPattern.lastPublishedAt < passedPattern.updatedAtOnClient) ||
        (passedPattern.published != 1 && passedPattern.sections && passedPattern.sections[0] )) {
      let index = updatedPanel.findIndex((item) => item.id == "publish")
      updatedPanel[index].enabled = true
    }
    if (passedPattern.sections && passedPattern.sections[0]) {
      let index = updatedPanel.findIndex((item) => item.id == "preview")
      updatedPanel[index].enabled = true
    }
    setPanelBtns(updatedPanel)
    setPattern(passedPattern)
  }

  }, [pattern])


  return(


    <View style={{flex: 1, paddingHorizontal: 12, marginTop: -54}}>
      <div className="PanelShadow">
        <View style={{ flexDirection: "row", justifyContent: "space-around", alignItems:"center", padding: 18, borderRadius: 48, backgroundColor: "#278ACC"}}>
          {
            panelBtns.map((btn) => {
              if (btn.enabled) {
                return (
                  <TouchableOpacity
                  style={{justifyContent: "center", alignItems:"center"}}
                  onPress={() => btn.action()}>
                  <Image style={{width: 24, height: 24, tintColor: "#FECB2E", marginRight: 4}} urlOnServer={btn.pic} />
                  <Text style={{color:"white", textAlign: "center", marginTop: 4}} >{btn.title}</Text>
                </TouchableOpacity>
                )
              } else {
                return (
                  <View
                  style={{justifyContent: "center", alignItems:"center"}}>
                  <Image style={{width: 24, height: 24, tintColor:"rgba(255,255,255,0.8)", marginRight: 4}} urlOnServer={btn.pic} />
                  <Text style={{color:"white", textAlign: "center", marginTop: 4}} >{btn.title}</Text>
                </View>
                )
              }

            })
          }


        </View>
        </div>
    </View>
  );
}

const styles = StyleSheet.create({
  input: {
    marginTop: 12,
    backgroundColor: "rgba(255,255,255,0.15)" ,
    color: "white",
    paddingLeft: 16,
    textAlign: "left"
  },
  title: {
    color: "rgba(255,255,255,0.8)",
    textAlign: "left",
    selfAlign: "flex-start",
  },

});
