export const SERVER_API_URL = "https://app.rowcounterapp.com"
//export const SERVER_API_URL = "http://127.0.0.1:4242"
//export const SERVER_API_URL = "http://tricotontest.pingou.fr"

export const RAVELRY_CRAFT_FILTER = ""
export const APP_NAME = "row_counter_web"

export const HEADER_HEIGHT = 72



export const NUM_COLUMNS_CUSTOM_SYMBOL = 10