import LocalizedStrings from 'react-localization';

let strings = new LocalizedStrings({
	en: {
		makeOne: "Make 1 stitch",
		twoTog: "2 stitches together",
		areYouSure: "Are you sure?",
		opaque: "Opaque",
		highlighter: "Highlighter",
		draw: "Draw",
		eraser: "Eraser",
		importWarning1: "If your pattern is not in PDF format (picture, webpage...), use My Row Counter on an emulator (for Web,",
		importWarning2: "See details here",
		importWarning3: ") or in the mobile app instead",
		machine:"Machine",
		loom_knitting:"Loom",
		transparent: "Transparent",
		schemaTool:{
			noColor:"no color",
			editSymbolHelper:"Do a long click on your custom symbol to edit it",
			hideSymbols:"Hide symbols",
			showSymbols:"Show symbols",
			rightSide:"Right Side",
			wrongSide:"Wrong Side"},
		patternCreator:{
			patCreator:"Pattern Creator",
			language:"Language",
			sizes:"Sizes",
			category:"Category",
			missingInfosText:"Please enter the following missing details about your pattern:",
			missingInfosTitle:"Some informations are missing",
			difficulty:"Difficulty",
			selectCat:"Select Category",
			updatePattern:"Update Pattern Details",
			createPattern:"Create New Pattern",
			selectSize:"Please, select a size first",
			alertChangesTitle:"You have ungenerated changes",
			alertChangesText:"Do you want to download the PDF version of your pattern without the last changes since you generated your pattern for the last time?",
			alertNotPublishedTitle:"Generate pattern",
			alertNotPublishedText:"You need to first Generate your pattern, so that we can create the PDF",
			alertDeleteTitle:"Delete Pattern",
			alertDeleteText:"Are you sure that you want to delete this pattern ?",
			alertPublicText:"You can make this pattern public, or keep it private. If private, only people you share your pattern with will be able to access it. If public, users of the Row Counter App and website will be able to find and use your pattern.",
			generatePublic:"Generate as Public",
			generatePrivate:"Generate as Private",
			additionalInfos:"Additional Infos",
			patternListTitle:"Rc Pattern Creator",
			patternListText:"Build your own responsive patterns with our pattern creator. These will be interactive and responsive in the Row Counter App. You can also export them as PDF",
			yourPatterns:"Your patterns",
			backToContent:"Back to content",
			imageSection:"Image Section",
			videoSection:"Video Section",
			alertDeleteContentTitle:"Delete Content",
			alertDeleteContentText:"Are you sure you want to delete this content ?",
			fromRow:"From row",
			onRow:"On Row",
			toRow:"to Row",
			RangeOfRows:"Range of Rows:",
			alertNumbers:"Please only enter numbers",
			enterInstructions:"Enter here your instructions:",
			updatePic:"Update picture",
			addPic:"Add Picture to appear on the side of this section",
			addText:"Add Free Text",
			addRowText:"Add Row instruction",
			textSection:"Text Section",
			addContent:"Add Content",
			helpOne:"Here is where you can add your pattern instructions.",
			helpTwo:"First create a part",
			helpThree:"Then add content sections. They can be text, image, video or charts.",
			helpfour:"You can add more parts if your item is constituted from several pieces assembled together.",
			editPartName:"Edit Part Name:",
			givePartName:"Name of the new Part:",
			addContentSection:"Add a Content Section",
			chooseContentType:"Choose a content type:",
			alertAddPartTitle:"Add a Part first",
			alertAddPartText:"Add at least one part to your pattern before adding content.",
			alertChartTitle:"Only available in the app",
			alertChartText:"You can edit your Chart only from the app, on your phone or tablet.",
			patternContent:"Pattern Content",
			gaugeStitch:"Number of Stitches to reach a 10cm/4 inches width:",
			gaugeRow:"Number of Rows to reach a 10cm/4 inches height:",
			manual:"Manual",
			fromList:"From a List",
			kniGlossary:"Knitting Glossary",
			croGlossary:"Crochet Glossary",
			update:"Update",
			addTool:"Add Tool",
			addYarn:"Add Yarn",
			yarnName:"Yarn name:",
			yarnCompanyName:"Yarn Company Name:",
			yarnWeight:"Yarn weight:",
			selectWeight:"Select Weight",
			yarnColor:"Yarn color:",
			neededYarn:"Needed Yarn:",
			materialType:"Material Type",
			selectLength:"Select Length",
			neededTools:"Needed Tools:",
			alertAllInfos:"Please fill all information",
			dlPdf:"Download PDF",
			generate:"Generate",
			fileSelected:"File selected",
			chooseFile:"Choose File to Upload",
			uploadPic:"Upload a Picture",
			replacePic:"Replace Picture",
			replaceVid:"Replace Video"},
		patterns_sizes: {
			newborn:"Newborn",
			kid:"Kid",
			teenager:"Teenager",
			men:"Men",
			women:"Women",
			mixed:"Mixed",
			cat:"Cat",
			dog:"Dog",

			one_size:"One Size",
			months:"months",
			years:"years",
		},
		head:"Head",
		neck:"Neck",
		torso:"Torso",
		legs:"Legs",
		feet:"Feet",
		hands:"Hands",
		accessories:"Accessories",
		pets:"Pet",
		other:"Other",
		glossaryP: {
			glossary: "Glossary",
			subtitle: "Knitting and Crochet Glossary",
			searchPh: "Search for an abbreviation in your desired language"
		},
		forum: {
			forum:"Forum",
			subtitle: "Forum content from the Row Counter App - Read only",
			postInForum: "Do you want to post in the forum?",
			downloadApp: "Download the app to interact with the community",
		},
		infobox: {
			text:"Did you know? You can now create your own patterns in the app!",
			cta:"Check it out! >",

		},

		popupsize: {
    		size: "Size",
			allsizes: [
		    		{
		    			name: "newborn",
		    			sizes: ["0-1 month", "1-2 months","2-4 months", "4-6 months", "6-9 months","9-12 months", "12-18 months", "18-24 months"],
		    		},
		    		{
		    			name: "kid",
		    			sizes: ["3 years", "4 years","5 years","6 years","7 years", "8 years","9 years", "10 years"],
		    		},
		    		{
		    			name: "teenager",
		    			sizes: ["11 years", "12 years","13 years","14 years","15 years", "16 years","17 years", "18 years"],
		    		},
		    		{
		    			name: "men",
		    			sizes: ["XXS","XS","S","M","L","XL","XXL","XXXL"],
		    		},
		    		{
		    			name: "women",
		    			sizes: ["XXS","XS","S","M","L","XL","XXL","XXXL"],
		    		},
		    		{
		    			name: "mixed",
		    			sizes: ["XXS","XS","S","M","L","XL","XXL","XXXL"],
		    		},
		    		{
		    			name: "cat",
		    			sizes: ["XXS","XS","S","M","L","XL","XXL","XXXL"],
		    		},
		    		{
		    			name: "dog",
		    			sizes: ["XXS","XS","S","M","L","XL","XXL","XXXL"],
		    		},
		    		{
		    			name: "other",
		    			sizes: ["XXS","XS","S","M","L","XL","XXL","XXXL"],
		    		},
	    		],
    	},

		distribstitches: {
            tooltitle: "Distribute Increase & Decrease",
            increases: "increases",
            increase: "increase",
            decreases: "decreases",
            decrease: "decrease",
            stitches: "stitches",
            stitch: "stitch",
            pleaseenter: "Please enter the 2 values",
            willstart: "It will start like this:",
            desirednb: "Desired number of stitches",
            currentnb: "Current number of stitches",
            weadvise: "We advise to do",
            every: "every",
            thefirst: "Start the first",
            after: "after",
            then: "then",
            endwith: "and then end with",
        },


        rc_web: {
        	confirm_email: "Check your emails to confirm your account",
        	create_new_proj: "Create a new project",
        	profile:"Profile",
        },

        infoImportPattern: "Click here to import your pattern from any source",
		lang: "en",
		tts_lang: "en-US",
		lang_is_us:"TRUE",
		ok: "ok",
		cancel: "cancel",
		start_new_project: "Start my\nfirst project",
		new_project: "New Project",
		project_name: "Project Name",
		enter_name: "Enter name",
		enter_project_name: "Please enter a project name",
		project_type: "Select Knitting Pattern",
		next: "NEXT",
		motif: "Select Stitch",
		material: "YOU NEED",
		description: "Description",
		begin: "BEGIN",
		finish: "FINISH",
		current_rang: "Current row : ",
		repeat: "Repeat : ",
		times: " times",
		stitches: "Stitches",
		rangs: "Rows",
		projs_completed: "Completed",
		tutorials: "Tutorials",
		select_size: "Select Size",
		select_woolsize: "Select Wool Size",
		linked_tutos: "Related tutorials",
		quantity: "Quantity",
		size: "Size",
		loading: "Loading...",
		badge1: "Yarnpprentice",
		badge2: "Loop Artist",
		badge3: "YarnMazing",
		badge4: "Loop Addict",
		badge5: "Craft Master",
		badge6: "WonderWool",
		badge7: "Level 7",
		badge8: "Level 8",
		badge9: "Level 9",
		badge10: "Level 10",
		part: "PART ",
		previous_seq: "Previous step",
		finished: "You've finished your project!",
		congratz: "CONGRATULATIONS!",
		row_counters: "Rows Counters",
		global_counter: "Global Counter",
		secondary_counter: "Secondary Counter",
		nb_row: "Number of rows",
		name_optional: "Name (optional)",
		save: "SAVE",
		auto_inc: "Link with Global Counter",
		set_up_main_counter: "Click on the main counter to set it up",
		counter_project: "Use Rows Counters",
		edit_counters: "Edit Counters",
		enter_comment_opt: "Enter a comment here if you want to",
		show_com_at_row: "Show comment at this row #",
		add_counter_com: "Add a comment",
		comments: "Comments",
		reinit_at: "Reset after this row is reached",
		counter: "counter",
		counters: "COUNTERS",
		counters_desc:
			"Keep track of your progress with our row counters!\n\nYou can configure:\n- 1 Global counter, to count the rows of your entire project\n- Several secondary counters, that will help you with your increase, decrease, or knitting patterns.\n\nNeed a reminder at a specific row ? Add it as a comment in the related counter, it will be shown at the right moment!",
		set_average_stitches:
			"Please enter the average number of stitches per row (approximately)",
		average_stitches: "Average number of stitches per rows",
		delete: "Delete",
		enter_number: "please enter a number",
		meters: "Meters",
		centimeters: "CM",
		inches: "Inches",
		yards: "Yards",
		convert_units: "Units converter",
		fill_all_fields: "Please fill all the fields",
		your_project: "YOUR PROJECT",
		compute_swatch: "Swatch Adapter",
		pattern: "PATTERN",
		your_swatch: "YOUR SWATCH",
		swatch: "swatch",
		must_monter: "you must cast on:",
		must_knit: "you must knit:",
		grams: "grams",
		tools: "Tools",
		the_project: "the project",
		tencm: "10cm",
		your_wool: "Your yarn",
		woolball_size: "Each skein/ball contains",
		wool_nb_required: "Number of skeins/balls required",
		woolballs: "Yarn skeins",
		number_required: "please enter numbers",
		woolball_convertor: "Yarn Converter",
		pattern_translate: "Instructions translations",
		activ_sound: "Activate sound to be able to use the app with your voice",
		say_next:
			"Say 'NEXT STEP' to go to the next row (tip: do it in a quiet room, also deep voices work best, so use your manliest voice when knitting)",
		say_next_counter:
			"Say 'NEXT STEP' to increment the main counter (tip: do it in a quiet room, also deep voices work best, so use your manliest voice when knitting)",
		say_next_ios:
			"Say 'NEXT' to go to the next row (tip: do it in a quiet room, also deep voices work best, so use your manliest voice when knitting)",
		say_next_counter_ios:
			"Say 'NEXT' to increment the main counter (tip: do it in a quiet room, also deep voices work best, so use your manliest voice when knitting)",

		search: "search",
		permission_not_granted: "Permissions not granted",
		retry: "Retry",
		authorize: "Allow",
		help_1: "You can click on the icon to see more information",
		you_knitted: "You have knitted: ",
		youre_now: "You are now",
		patterns_easy: "Beginner Patterns",
		patterns_medium: "Easy\nPatterns",
		patterns_hard: "Intermediate Patterns",
		or:"or",
		ravelry_library: "My Ravelry Library",
		ravelry_search:"Search Ravelry",
		import_pattern: "Import PDF Pattern",
		import_to_counter: "Import to my row counter",
		downloading:"Downloading... It can take a few minutes",
		select_source:"Select Source:",
		confirm_import_webpage: "This page will be imported as you see it now. Continue?",
		confirm_delete_imported_pattern: "Remove pattern?",
		ask_img_upload: "Your image may be shared with other users, continue?",
		add_picture: "Add Picture",
		send_feedback:"Send Feedback",
		important_feedback:"Your feedback is important",
		are_you_satisfied: "Are you satisfied of your piece of art? What did you think of the pattern?\n\nYou can leave us your impressions here, to help us improve the app!",
		send:"send",
		thanks:"Thank you",
		time_elapsed: "Time",
		long_press: "Press long on project for more options",
		take_pic: "Take a picture",
  		gallery_pic: "Select from Library",
		select_pic: "Please select:",
		reset_timer:"Reset timer",
		see_picture:"See Picture",
		change_picture:"Change Picture",
		stitches_on_row:"Stitches on row:",
		storage_full: "Storage full. Please delete somes files or apps and retry",
		phone: "Your device",
		select_language:"Select language",
		download: "Download",
		reset_counter:"Reset counter",
		started_on:'Started on',
		time_elapsed2:'Time elapsed',

		detailed_pattern:"Detailed pattern",
		real_virtual_pattern:"in app and printed",

		have_kit:"I already have the kit",
		buy:"Buy",
		enter_command:"To access the pattern, please enter the order number. You can find it in your order confirmation email.",
		command_number:"Order number :",
		inside:"Inside :",
		price:"Price :",
		coloris:"Colours :",
		kit_from:"Kit from :",
		galerie:"Gallery",
		invalid_order:"Invalid order number.",
		network_error:"Network Error",
		need_help_contact:"Need help? Contact us at tricotonapp@gmail.com",
		access_pattern:"Access pattern",

		auto_translate:"What follows is an automated translation of the pattern's description. The pattern has been translated manually.",
		raverly_suggestions:'We love on Ravelry',
		needle_size:"Needles size :",
		wool_size:"Wool size :",
		yardage:"Yardage :",
		in_your_language:"In your language :",
		yes:"yes",
		no:"no",
		access_ravelry_pattern:"To access your pattern, create a project normally, click import pattern and go to your Ravelry library (or download it on your phone, and select the import from phone option)",
		have_pattern:"I already have the pattern",
		buy_download_hint:"After you bought the pattern, click on the \"download button\" on the webpage to start your project.",


		you_no_account:"You don't have an account?",
		login: "Login",
		already_have_account:"I already have an account",
	    register:"Register",
	    username:"username",
	    your_email:"your email",
	    your_password:"your password",
	    password_not_equal:"Password not equal",
	    repeat_password:"Repeat your password",
	    enter_username: "Enter an username",

		login_desc:"Login to use the app on several devices",

		try_premium:"Try the premium version of the app!",
		help_us:"To allow us to continue working on this app and enjoy all features, we are asking you for your contribution.",

		premium_abo: "Premium Subscription",

		no_ads:"No Ads",
		all_patterns_unlocked:"All patterns unlocked",
		access_every_device:"Synchronisation in the cloud: Access your projects from all your devices. Never lose your progress!",
		unlimited_counters:"Unlimited counters per project",
		unlimited_pictures:"Add pictures!",
		try_premium_btn:"Try the premium version",
		continue_free:"Continue with the free version",
		login_required:"Please log in to be able to backup your projects.",
		buy_required:"Synchronisation is turned off. Learn more about all Premium features here.",
		log_out:"Log out",
		year:"year",
		month: "month",
		monthly_sub: "Monthly Subscription",
		annual_sub: "Annual Subscription",

		premium_feature:"This is a premium feature",
		premium_pattern:"This is a premium pattern",
		try_free:"Try Premium for free for one week!",
		learn_more_premium:"Learn more about premium",
		wrong_pwd_email:"Wrong email or password",
		error_occured_check_connection:"An error occured. Are you connected to the internet?",
		already_paid:"I already have an active subscription",
		enter_description:"add description here",
		invalid_email:"Invalid Email",
		email_already_registered:"Email already registered",
		username_already_registered:"username already exists",
		free_week_trial:"Try it for free for 7 days !",
		learn_about:"Learn more about",



		ios_iap_info:"• Payment will be charged to your iTunes Account if you do not cancel the subscription at least 24-hours before the end of the 7 days trial period.\n• Subscription automatically renews unless auto-renew is turned off at least 24-hours before the end of the current period\n• Account will be charged for renewal within 24-hours prior to the end of the current period, and identify the cost of the renewal\n• Subscriptions may be managed by the user and auto-renewal may be turned off by going to the user's Account Settings after purchase\n• Any unused portion of a free trial period, if offered, will be forfeited when the user purchases a subscription to that publication, where applicable",
        privacy_policy:"Privacy policy",
        tos:"Terms of Service",


		explain_why_premium_title:"Why are we offering a premium version?",
		explain_why_premium_1:"We started working on the app in January 2018 to release our first version 2 months later. Since then, we work every day to improve it. It has remained free and without ads for almost a year, and this while the app has required:",
		explain_why_premium_2:"3500 hours of work",
		explain_why_premium_3:"taken on our free time",
		explain_why_premium_4:"Hosting and translation costs",
		explain_why_premium_5:"financed by our savings",
		explain_why_premium_6:"To be available on weekdays and weekends",
		explain_why_premium_7:"to answer your emails and comments",
		explain_why_premium_8:"We love this project and want to keep improving the app so it best meets your needs, but we can not continue to lose money for it.\n\nThe Premium Subscription is for you a way to support us, and allow us to keep working on this great project! ",


		see_premium:"See the Premium Offer >",
		why_not_free:"Why isn't this app completely free?",

		ad_warning:"You may see ads here soon. Learn why.",

		remove_ads:"Remove ads only",

		continue:"continue",


		buy_pattern_desc:"You can purchase this pattern alone. You will still see ads.",
		buy_all_pattern_desc:"Purchase all patterns at once, enjoy the app ads free and save up to 80%.",
		buy_premium:"Or try Knitting Genius Premium and enjoy all premium patterns and features, ads free. Your projects are synchronized among all your devices.",
		buy_pattern_price:"Buy pattern for ",
		buy_all_patterns_price:"Buy all patterns for ",

		buy_all_pattern_no_ads:"Purchase all patterns at once, remove ads.",


		password_forgotten:"Password forgotten?",

		contact_us:"Contact us",

		projects_synchro:"Projects saved on the server",

		last_update:"Last update",
		show_reset: "Show number of resets",
		watch_tuto: "A bit lost? Would you like to watch the app tutorial?",

		annotations_hint:"You can long click on the document to add annotations.",
		annotations_hint_premium:"With Premium you can long click on the document to add annotations.",
		add_annotation_text:"Add your annotation here...",

		select_mode:"Select mode",
		knitting_mode: "Knitting",
		crochet_mode: "Crochet",

		badge1_crochet: "Level 1",
		badge2_crochet: "Level 2",
		badge3_crochet: "Level 3",
		badge4_crochet: "Level 4",
		badge5_crochet: "Level 5",
		badge6_crochet: "Level 6",

		type:'type',
		discard_changes:'Discard changes ?',
		agree:'Agree',
		agree_email:'I agree to receive emails from the app',
		see_terms: 'See terms and privacy policy',
		must_agree_pp: 'You must agree to the terms and conditions and the privacy policy to continue',
		duplicate: 'duplicate',

		no_ravelry_project:'You do not have any project on Ravelry.',
		already_imported:'You already imported this project',
		import_ravelry:"or import from Ravelry",
		import:'Import',
		small:'small',
		normal:'normal',
		big:'big',
		options:'Settings',
		show:'Show',
		hide:'Hide',
		long_press_position:'Press long on a counter to move it around',
		enable_expert_options:'You can enable expert mode in the settings',
		enable_expert:"Enable expert mode",
		counter_size:"Size of counters",
		highligther_size:'Size of highlighter',
		starts_at:'Starts at',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'then',
		hours:'hours',
		minutes:'minutes',
		seconds:'seconds',
		configure:'Configure',
		add_to_timer: 'and add to timer automatically without asking me when screen was off less than:',
		keep_timer_off:'Keep timer on when screen is off',
		timer_ask:"{0} have passed since you left the app, would you like to add this time to the timer?",
		add_time_timer:"Add time to timer?",
		select_camera: "Take a Picture",
    	select_gallery: "Add from gallery",
    	photo:'Photo',
    	new_start_zero:'New counters start at 0 by default',


    	voice_control_ios_13:
			"As an alternative that should work better, you can now enable iOS voice control and say \"tap NEXT\". You do not need to enable the microphone then.",
		configure_voice_control:"> Learn how to enable iOS Voice Control",
		voice_control_ios_13_link:'https://support.apple.com/en-us/HT210417',
		use_voice_control:'Use iOS Voice Control (works better)',
		use_normal_speech:'Use App Voice Control',
		syncing:'Syncing',
		sync_error:'Sync Failed',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch may not be connected (or screen off)',
		watch_connected:'Apple Watch connected',



		troubleshoot_watch: "Troubleshoot Watch",
		reset_all_timers: "Reset timer? WARNING: All time spent on ALL your projects will be reset to 0.",
		premium_file_sharing:"Only Premium users can use file sharing.",
		shared_with_me:"Shared with me",
		no_shared:"Nobody has shared a project with you yet.",
		accept:"Accept",
		from:"from",
		share_project:"Share project",
		share_copyright_check:"I will not share copyrighted material",
		share_copyright:"Please respect pattern's authors and only share material you have permission for. Sharing copyrighted material may result in termination of your account.",
		share_project_with:"Sharing project with",
		disable_social:"Disable Social",
		friends:"Friend(s)",
		shared:"Shared",
		chats:"Chats",
		chat:"Chat",
		join_community:"Join the community!",
		social_desc:"Find friends and share the joy. Friends can see your advancement and pictures, and you can share projects with them.",
		projects:"Projects",
		unfriend:"Unfriend",
		congratulate:"Congratulate",
		wait_confirm:"Waiting for confirmation",
		not_found:"Not found",
		invite_friend:"Invite friend",
		invite_ext:"Hi, I would like to invite you to try the My Row Counter app. Install it and add me as a friend, my username is {0}",
		add_friend_desc:"Add your friends on the app to communicate with them and share your progress. Friends will be able to see your pictures.",
		send_invitation:"Send invitation",
		add_friend:"Add a friend",
		no_friend_yet:"No friend yet",
		start_chatting:"Click to start chatting",
		you_finished_project:"You finished a project",
		you_started_project:"You started a new project",
		finished_project:"finished a project",
		started_project:"started a new project",
		reached_level:"reached level",
		you_reached_level:"You reached level",
		sent_message:"sent you a message",
		congratulated_you:"congratulated you 👏",
		shared_project_with_you:"shared a project with you",
		wants_to_be_friend:"wants to be your friend!",
		became_your_friend:"became your friend!",
		type_your_message:"Type your message",


		login_social:"Login to use Social",
		faq_translated:"This webpage is only in english, would you like to see an automatic translation?",



		watch_help_1:"On your watch, launch the app. On this phone, go to a project. If this doesn't work, follow the procedure below",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",
		watch_help_6:"If it still doesn't work, restart both watch and phone.",

		select:'Select',
		select_part:'Select Part',
		next_part:"Go to next part",
		part_finished:"Part finished",
		part_finished_desc:"Congratulations, you finished this part!",
		part:"part",
		delete_part:"Delete this part ? All counters in this part will be deleted.",

		highlight_vertical:"Display Highlighter Vertically",




		back:"back",
		configure_ads:"Configure ads",
		partner_click:"Click on one of our partners to learn how he collects and uses data",
		how_use_data:"How do we use your data?",
		tailor_ads:"Can we use your data to tailor ads for you?",
		consent_desc:"We care about your privacy and data security. Ads allow us to offer a free version of the app.",
		consent_desc2:"You can change your choice anytime in the app settings. Our partners will collect data and use a unique identifier on your device to show you ads without sharing your personal information",
		how_collect_data:"How do we and our partners collect data",
		relevant_ads:"Yes, customize my experience",
		ad_free:"Purchase the ad free version",
		not_relevant_ads:"No, see ads that are less relevant",

		enable_tracking: "GDPR Compliance",
        enable_tracking_desc: "We care about your privacy and data security. Allowing cookies will enhance your experience on this app, allow us to have more information in case of a bug so we can fix it, tailor features to your needs and the use of the advertising ID will allow to display less ads that would be irrelevant to you. ",
        allow_cookies: "Allow.",
        disallow_cookies: "Disallow. I understand that my experience on this app will be less tailored to my needs and my issues will not be reported",

        convert_what:"What do you want to convert?",
        to:"to",

		activate:"Activate",


		yarn_yards: "yards",
		yarn_meters: "meters",
		ounces:"ounces",
		add:"Add",
		select_this_yarn:"Select this yarn",
		select: "select",
		stash: "Stash",
		stash_ravelry_beta:"Please report issues to tricotonapp@gmail.com",
		adjust_no_sync:"Adjustments cannot be synchronised with Raverly",
		import:"Import",
		select_needle:"Select Needle",
		remove:"Remove",
		nothing_here:"Nothing here yet.",
		see_needles:"See Needles",
		see_stash:"See Stash",
		needles:"Needles",
		needle:"Needle",
		straight:"straight",
		double: "double",
		circular: "circular",
		steel: "steel",
		hook: "hook",
		link_ravelry_proj:"Do you want to link this project to an existing project on Ravelry?",
		link_ravelry_proj_hint:"Select 'no' if you do not use Raverly or didn't create this project on Ravelry",
		must_be_img_pdf:"file cannot be downloaded. Must be image or PDF",
		cannot_be_zero:"Cannot be 0",
		color:"Color",
		add_from_stash:"Add from stash",
		link_needles:"Link needles to project",
		import_ravelry2:"Import from Ravelry",
		create:"Create",
		starting_quantity:"Starting quantity:",
		use:"Use",
		adjust:"Adjust",
		edit_yarn:"Edit Yarn",
		yarn_stash:"Yarn Stash",
		needle_stash:"Needle Stash",
		sync_ravelry:"Sync with Ravelry",
		enter_weight:"enter weight",
		enter_length:"enter length",
		totals:"Totals",
		nb_skeins:"Nb skeins",
		name:"Name",
		company_name:"Company Name",
		yarn_weight:"Yarn weight",
		unlink_ravelry:"Unlink from Ravelry",
		colorname:"Color Name",
		select_weight:"select weight",
		use_nb_skeins:"Use nb skeins",
		use_totals:"Use totals",
		each_skein:"Each skein is",
		notes:"Notes",
		edit:"Edit",
		disconnect_ravelry:"Disconnect from Ravelry",
		type:"Type",
		length:"Length",
		size:"Size",
		notes:"Notes",
		select_all:"Select All",
		select_corresponding_project:"Select corresponding project on Ravelry",
		used_by:"Used by",
		left_stash:"Left on Stash",

		zoom_hint:"You can also pinch or double tap the screen to zoom",

		other_sources:"Other sources",




		create_custom_proj:"I have a pattern",
		create_custom_proj_desc:"Use many as counters as needed, import a pattern from any source if you want to.",
		import_rc_pattern:"I'm looking for inspiration",
		import_rc_pattern_desc:"Browse among our free patterns, you might find something you like!",
		import_project_ravelry:"I have a Raverly project",
		import_project_ravelry_desc:"Import an existing Ravelry project to the app, use many counters to follow your progress.",
		ask_resize_counters:"Would you like to make the counters appear smaller? You can change the counters size anytime in the settings.",
		start:"Start",
		personal_source:"From a personal source",
		external_source:"From an external source",

		cm10inches4: '4 inches',
		rows: 'rows',
		gauge:'Gauge',
		row:'row',
		login_pattern_generator:"Login to create patterns",
		preview:"Preview",
		no_gauge:"Gauge not set",
		worked_in:"Worked in",
		yarns:'yarns',
		pattern_creator:"Pattern Creator",
		pattern_creator_log_in:"You must create an account to use the pattern creator",
		tuto_title_pattern_creator:"Want to know more about pattern creator?\nWatch this short introduction video",
		glossary:"Glossary",
		no_glossary:"Glossary not set",
		size:"Size",
		next_part_is:"Next part",
		previous_part_is:"Previous part is",
		previous_part: "Previous part",
		go_to_intro: "Go to intro",
		add_part_counters:"You must first add a part before you can set up counters",
		add_part:"Add Part",
		no_material:"No material set",
		new_pattern:"New Pattern",
		public_pattern:"Public pattern. Other users will be able to see it.",
		browse_pattern: "Browse Pattern",
		confirm_import_and_reset: "Importing this pattern will reset your current project, continue?",
		create_account:"Create Account",
		add_yarn:"Add yarn",
		sharing_link:"Sharing link",
		link_copied:"link copied to clipboard",
		publish:"Publish",
		edit_content:"Edit Content",
		create_proj_from_pattern:"Create Project from this Pattern",
		more:"More",
		import_rc_desc:"Or import one of the RC-patterns optimized for the Row Counter App, created by our community",
		download_as_pdf:"Download as PDF",
		publish_first:"You first need to publish your pattern before you can import it",
		publish_changes:"Publish changes",
		no_publish_needed: "All modifications have already been published",
		changed_not_published:"You have changes that have not be published",
		continue_anyway: "Continue anyway",
		select_size:"Select size",
		pdf_in_download:"Pdf has been downloaded to your Download folder",
		abreviation:"Abbreviation",
		new_abreviation:"New abbreviation",
		technic:"Technique",
		new_technic:"New Technique",
		technics:"Techniques",
		no_technics:"No techniques",
		pattern_content:"Pattern content",
		saved:"saved",
		hide_in_search:"Hide pattern in search results",
		can_share:"You can still share your pattern with your friends and selected people, but other users won't be able to see it",
		everybody_see:"Everybody can download your pattern. Only you can modify it.",
		counters_not_limited_beta: "Only Premium users can normally see more than 2 secondaries counter for a project. We have unlocked this feature for all users for a limited time for Row Counter patterns.",
		add_content:"Add Content",
		add_needle:"Add Needle",
		available_sizes:"Available sizes",
		video:"Video",
		move_section: "Press long on the item then move it around",
		move:"Move",
		edit_part:"Edit Part",
		published_when_validated:"Your pattern will be visible to other users after a manual review",
		pattern_not_supported:"This pattern has been created with a newer version of this app. You need to update the app to use it.",
		add_free_text:"Add Free Text",
		add_row_instruct:"Add Rows Instructions",
		edit_section:"Edit Section",
		create_symbol:"Create custom Symbol",
		chart_preview:"Chart Preview",
		only_colors_shown:"Only colors are shown in the preview",
		why_few_patterns:"Why is there so few patterns?",
		why_few_patterns_desc:"We just released this feature, more patterns will come in the following weeks!",
		size_instruction:"Add size specific instructions",
		text:"Text",
		width:"Width",
		height:"Height",
		worked_flat:"Worked flat",
		worked_round:"Worked in the round",
		chart:"Chart",
		image:"Image",
		all_sizes_done:"All Sizes have been done",
		sizes:"Sizes",
		show_chart_sizes:"Show this chart for sizes",
		duplicate_chart_size:"Duplicate chart for these sizes",
		add_chart_variation:"Add a chart variation for specific sizes",
		cell: "cell",
		quantity:"Quantity",
		add_quantity:"Add quantity",
		highlight_premium:"Highlighting and text editing are Premium features",

		allrcpatterns: {
    		title: "RC Patterns",
    		areyounew: "Are you new to RC Patterns?",
    		watchcontent: "Watch the introduction video or see our dedicated FAQ",
    		create: "Create your own pattern",
    		faq: "FAQ",
    		video: "Video",

    	},
    	create_pattern:"Create Pattern",
    	create_new_pattern:"Create my first pattern",
    	no_pattern:"No pattern yet",
    	watch_tuto_video:"Watch our video tutorial",
    	read_faq:"Read our FAQ",
    	see_users_patterns:"See patterns created by other users",
    	all_patterns_desc:"See here all patterns created by other users.\nYou can import them from here, this will create a project on the app, with row counters and various other features.",
		pattern_name:"Pattern Name",
		want_other_import:"I want to import patterns from another source",
		knit_crochet_other_source:"Knit or Crochet a pattern from another source",
		patternus_desc:"Patternum is mainly about easily creating great patterns that are optimized for your phone or tablet.",
		rc_desc1:"You can follow a Patternum pattern directly in this app, but to follow other patterns (PDF, video, website....) we created another specific app:",
		rc_desc2:"This app let you import any type of patterns, and then follow it easily using multiple counters, highlighters, comments...",
		discover_rc:"Discover Row Counter",
		patterns_created:"Patterns Created",
		patterns_publish:"Patterns Published",
		patternus_projects:"See here all projects created from a Patternum pattern",
		my_patterns:"My patterns",
		vertical:"Vertical",
		horizontal:"Horizontal",
		all_patterns:"All Patterns",
	pattern_name:"Pattern Name",


		difficulty:"Difficulty",
		category:"Category",
		chart_settings:"Chart Settings",
		rs:"Right Side",
		ws:"Wrong Side",
		rs_short:"RS",
		ws_short:"WS",
		appears_rs:"Appears on right side on PDF",
		appears_ls:"Appears on left side on PDF",
		size_in_pdf:"Size in PDF",

		appear_row:"To appear on row:",
		single_row:"Single Row",
		range_rows:"Range of rows",

		columns:"Colums",
		lines:"Lines",

		title:"Title",
		icon:"Icon",
		document_locked:"Cannot add annotations, PDF is protected by author",

		highlight_annotation_more:"Highlighter, annotations on PDF, and more!",
		cancel_anytime:"You can cancel anytime",


		comment_interpret_help1: "this feature is for very advanced users\n\nYou will be able to compute mathematical operations, and display an handful of useful info.\nFor example, if you want the comment to show you the value of the current row for the counter, you will write $row\nTry to enter this in your comment:\nMy row value is $row\nThe comment will display: My row value is 1 (if you are currently at row 1)\n\nAvailable keywords are:\n$row : current row of the counter\n$globalrow : current row of the global counter\n$nbreset : number of reset of the counter\n\n",
		comment_interpret_help2: "Everything between the symbols { and } will be mathematically interpreted:\nfor example enter:\n{ 1 + 2 }\n\nit will display:\n3\n\nor enter : { 1 + $row }\nif will display:\n2 (if current row is 1}\n\nLet's take a more complicated example. You are knitting a sweater sleeve that needs decreasing by 3 every 8th row. First row is 100 stitches.\n\nYou set a secondary counter that cycles through 1-8 rows, and on row 1 you set a comment to tell you to decrease. If you want to know how many stitches you would need, you can enter as a comment for this second counter:\n\nI now need to do { 100 - ($nbreset * 3) } stitches\n\nWhen you reach your row 1, it will display\nI now need to do 100 stitches\nNow increase the counter 8 times to reset to 1 and it will display\nI now need to do 97 stitches",
	},
	"en-US": {
		tencm: '4"',
		lang_is_us:"TRUE",
	},
	// NEW LANG
	fr: {
		areYouSure: "Êtes-vous sûr(e) ?",
		highlighter: "Surligneur",
		draw: "Dessiner",
		opaque: "Opaque",
		eraser: "Gomme",
		importWarning1 : "Si votre patron n'est pas au format PDF (image, page web...), utilisez Compte Rangs sur un émulateur (pour le Web,",
		importWarning2 : "Voir les détails ici",
		importWarning3 : ") ou dans l'application mobile",
		machine:"Machine",
		loom_knitting:"Loom",
		schemaTool:{
			noColor:"sans couleur",
			editSymbolHelper:"Faites un clic long sur votre symbole personnalisé pour le modifier",
			hideSymbols:"Masquer les symboles",
			showSymbols:"Afficher les symboles",
			rightSide:"Endroit",
			wrongSide:"Envers"},
		patternCreator:{
			language:"Langue",
			patCreator:"Créateur de patrons",
			sizes:"Tailles",
			category:"Catégorie",
			missingInfosText:"Veuillez entrer les détails manquants suivants :",
			missingInfosTitle:"Certaines informations sont manquantes",
			difficulty:"Difficulté",
			selectCat:"Choisir une catégorie",
			updatePattern:"Mettre à jour le patron",
			createPattern:"Créer un nouveau patron",
			selectSize:"Sélectionnez d'abord une taille ",
			alertChangesTitle:"Mettez à jour votre patron",
			alertChangesText:"Vous n'avez pas mis à jour votre patron (= cliqué sur 'Génerer') depuis vos dernières modifications. Voulez-vous quand même télécharger le PDF ?",
			alertNotPublishedTitle:"Générer le Patron",
			alertNotPublishedText:"Vous devez d'abord générer votre patron afin que nous puissions créer le fichier PDF",
			alertDeleteTitle:"Supprimer le patron",
			alertDeleteText:"Êtes-vous sûr de vouloir supprimer ce patron ?",
			alertPublicText:"Vous pouvez rendre ce patron public ou le garder privé. Si privé, seules les personnes avec qui vous partagez votre modèle seront en mesure d'y accéder. Si public, les utilisateur de l'application mobile et du site web pourront découvrir et utiliser votre patron.",
			generatePublic:"Générer en mode public",
			generatePrivate:"Générer en mode privé",
			additionalInfos:"Infos supplémentaires",
			patternListTitle:"Créateur de Patrons RC",
			patternListText:"Créez vos propres patrons interactifs avec notre créateur de patrons. Ceux-ci seront utilisables dans l'application Compte-Rangs et téléchargeables en PDF.",
			yourPatterns:"Vos patrons",
			backToContent:"Retour au contenu",
			imageSection:"Section image",
			videoSection:"Section vidéo",
			alertDeleteContentTitle:"Supprimer le contenu",
			alertDeleteContentText:"Êtes-vous sûr de vouloir supprimer ce contenu?",
			fromRow:"Du rang",
			onRow:"Rang",
			toRow:"Au Rang",
			RangeOfRows:"Concernant les rangs :",
			alertNumbers:"N'entrez que des chiffres",
			enterInstructions:"Entrez ici vos instructions:",
			updatePic:"Mettre à jour la photo",
			addPic:"Ajouter une image qui apparaitra á côté de cette section",
			addText:"Ajouter un texte",
			addRowText:"Ajouter une instruction spécifique à un rang",
			textSection:"Section texte",
			addContent:"Ajouter du contenu",
			helpOne:"Voici comment ajouter les instructions de votre patron :",
			helpTwo:"Créez d'abord une partie",
			helpThree:"Ensuite, ajoutez des sections de contenu. Elles peuvent être du texte, des images, des vidéos ou des schéma.",
			helpfour:"Vous pouvez ajouter plus de parties si votre projet est constitué de plusieurs parties assemblées ensemble.",
			editPartName:"Éditer le nom de la partie:",
			givePartName:"Nom de la nouvelle partie:",
			addContentSection:"Ajouter du contenu",
			chooseContentType:"Choisissez un type de contenu:",
			alertAddPartTitle:"Ajoutez d'abord une partie ",
			alertAddPartText:"Ajoutez au moins une partie à votre patron avant d'ajouter du contenu.",
			alertChartTitle:"Disponible uniquement dans l'application",
			alertChartText:"Vous pouvez modifier votre schéma uniquement à partir de l'application, sur votre téléphone ou votre tablette.",
			patternContent:"Contenu du patron",
			gaugeStitch:"Nombre de mailles pour atteindre une largeur de 10 cm / 4 pouces:",
			gaugeRow:"Nombre de rangs pour atteindre une hauteur de 10 cm / 4 pouces:",
			manual:"Manuellement",
			fromList:"À partir d'une liste",
			kniGlossary:"Glossaire tricot",
			croGlossary:"Glossaire crochet",
			update:"Mettre à jour",
			addTool:"Ajouter Outil",
			addYarn:"Ajouter Laine",
			yarnName:"Nom de la laine :",
			yarnCompanyName:"Marque de la laine :",
			yarnWeight:"Taille de la laine :",
			selectWeight:"Selectionner",
			yarnColor:"Couleur de la laine : ",
			neededYarn:"Quantité : ",
			materialType:"Type de materiel : ",
			selectLength:"Sélectionner la longueur",
			neededTools:"Outils nécessaires :",
			alertAllInfos:"Prière de remplir toutes les informations",
			dlPdf:"Télécharger le PDF",
			generate:"Générer",
			fileSelected:"Fichier sélectionné",
			chooseFile:"Choisissez fichier á télécharger",
			uploadPic:"Télécharger une image",
			replacePic:"Remplacer l'image",
			replaceVid:"Remplacer la vidéo"},
		patterns_sizes: {
			newborn:"Nouveau né",
			kid:"Enfant",
			teenager:"Adolescent",
			men:"Homme",
			women:"Femme",
			mixed:"Unisexe",
			cat:"Chat",
			dog:"Chien",

			one_size:"Taille unique",
			months:"mois",
			years:"ans",
		},
		counter: "compteur",
		pattern_name:"Nom du patron",
		text:"Texte",
		preview:"Preview",
		add_part:"Ajouter une partie",
		technics:"Techniques",
		head:"Tête",
		neck:"Cou",
		torso:"Torse",
		legs:"Jambe",
		feet:"Pieds",
		hands:"Mains",
		accessories:"Accessoires",
		pets:"Animal",
		other:"Autre",
		glossaryP: {
			glossary: "Glossaire",
			subtitle: "Glossaire tricot et crochet",
			searchPh: "Chercher une abréviation dans la langue de votre choix"
		},
		forum: {
			forum:"Forum",
			subtitle: "Contenu du forum de l'appli Compteur de Rangs",
			postInForum: "Souhaitez-vous participer au forum ?",
			downloadApp: "Télecharger l'appli pour intéragir avec la communauté",
		},
distribstitches: {
            tooltitle: "Répartir augmentations & diminutions",
            endwith: "et terminez par",
            increases: "augmentations",
            increase: "augmentation",
            decreases: "diminutions",
            decrease: "diminution",
            stitches: "mailles",
            stitch: "maille",
            pleaseenter: "Veuillez entrer les 2 valeurs",
            willstart: "Le rang commencera ainsi :",
            desirednb: "Nombre de mailles désiré",
            currentnb: "Nombre de mailles actuel",
            weadvise: "Nous conseillons de faire",
            every: "toutes les",
            thefirst: "commencez la première",
            after: "après",
            then: "ensuite, faites",
        },

        rc_web: {
        	confirm_email: "Un email vous a été envoyé. Cliquez sur le lien pour valider votre inscription.",
        	create_new_proj: "Créer un nouveau projet",
        	profile:"Profil",
        },


        infoImportPattern: "Cliquez ici pour importer votre patron depuis n'importe quelle source",
		lang: "fr",
		tts_lang: "fr-FR",
		ok: "ok",
		cancel: "annuler",
		start_new_project: "Commencer mon\npremier projet",
		new_project: "Nouveau Projet",
		project_name: "Nom du projet",
		enter_name: "Entrez un nom",
		enter_project_name: "Veuillez entrer un nom pour votre projet",
		project_type: "Choisissez le type de patron",
		next: "SUIVANT",
		motif: "Choisissez le motif",
		material: "MATERIEL",
		description: "Description",
		begin: "COMMENCER",
		finish: "TERMINER",
		current_rang: "Rang actuel : ",
		repeat: "Répétez : ",
		stitches: "Mailles",
		rangs: "Rangs",
		times: " fois",
		projs_completed: "Terminés",
		tutorials: "Tutoriaux",
		select_size: "Choisissez la taille",
		select_woolsize: "Choisissez la grosseur de la laine",
		linked_tutos: "Tutoriels associés",
		quantity: "quantité",
		size: "Taille",
		loading: "Chargement...",
		badge1: "Niveau 1",
		badge2: "Niveau 2",
		badge3: "Niveau 3",
		badge4: "Niveau 4",
		badge5: "Niveau 5",
		badge6: "Niveau 6",
		badge7: "Niveau 7",
		badge8: "Niveau 8",
		badge9: "Niveau 9",
		badge10: "Niveau 10",
		part: "PARTIE ",
		previous_seq: "Etape précédente",
		finished: "Vous avez terminé votre tricot",
		congratz: "FELICITATIONS!",
		row_counters: "Compteur de rangs",
		global_counter: "Compteur Global",
		secondary_counter: "Compteur secondaire",
		nb_row: "Nombre de rangs",
		name_optional: "Nom (optionel)",
		save: "Enregistrer",
		auto_inc: "Lier avec compteur global",
		set_up_main_counter:
			"Cliquez sur le compteur global pour le configurer",
		counter_project: "Utilisez le compteur de rang",
		edit_counters: "Editer les compteurs",
		enter_comment_opt: "Entrez un commentaire (optionel)",
		show_com_at_row: "Afficher commentaire à ce rang",
		add_counter_com: "Ajouter un commentaire",
		comments: "Commentaires",
		reinit_at: "Réinitialiser après ce rang",
		counters: "COMPTEURS",
		counters_desc:
			"Suivez votre avancement & évitez toute erreur avec nos compteurs de rangs !\n\nVous pouvez configurer :\n\n- Un Compteur global, destiné à suivre la progression de votre projet.\n\n- Plusieurs Compteurs secondaires, avec lesquels vous pourrez aisément suivre les répétitions de vos motifs, vos augmentations ou diminutions.\n\nUne instruction importante à se remémorer pour un rang particulier ? Ajoutez-la en commentaire dans le compteur associé, elle apparaitra au bon moment !",
		set_average_stitches:
			"Veuillez entrer le nombre de mailles moyen par rang (approximativement)",
		average_stitches: "Nombre moyen de mailles par rang",
		delete: "Supprimer",
		enter_number: "Veuillez entrer un nombre",
		meters: "Mètres",
		centimeters: "CM",
		inches: "Inches",
		yards: "Yards",
		convert_units: "Convertisseur d'unités",
		fill_all_fields: "Veuillez remplir tous les champs",
		your_project: "VOTRE PROJET",
		compute_swatch: "Calculateur d'échantillon",
		pattern: "PATRON",
		your_swatch: "VOTRE ECHANTILLON",
		swatch: "échantillon",
		must_monter: "Vous devez monter:",
		must_knit: "Vous devez tricoter",
		grams: "grammes",
		tools: "Outils",
		the_project: "le projet",
		tencm: "10cm",
		your_wool: "Votre Pelote",
		woolball_size: "Chaque pelote contient:",
		wool_nb_required: "Nombre de pelotes nécessaires:",
		woolballs: "Pelotes",
		number_required: "veuillez entrer des nombres",
		woolball_convertor: "Calculateur de pelotes",
		activ_sound: "Activate sound to be able to use the app with your voice",
		say_next:
			"Dites 'étape suivante' après avoir fini de tricoter le schéma pour aller à la prochaine étape (cela fonctionne mieux dans une pièce sans bruit, et si vous prenez une voix grave).\nN'ayez pas peur du ridicule !",
		say_next_counter:
			"Dites 'étape suivante' pour incrémenter le compteur principal (cela fonctionne mieux dans une pièce sans bruit, et si vous prenez une voix grave).\nN'ayez pas peur du ridicule !",
		say_next_ios:
			"Dites 'NEXT' (et oui désolé, en anglais) après avoir fini de tricoter le schéma pour aller à la prochaine étape (cela fonctionne mieux dans une pièce sans bruit, et si vous prenez une voix grave).\nN'ayez pas peur du ridicule !",
		say_next_counter_ios:
			"Dites 'NEXT' pour incrémenter le compteur principal (cela fonctionne mieux dans une pièce sans bruit, et si vous prenez une voix grave).\nN'ayez pas peur du ridicule !",

		search: "search",
		permission_not_granted: "Vous n'avez pas donné les permissions",
		retry: "Réessayer",
		help_1: "Vous pouvez cliquer sur l'icone pour obtenir plus d'informations",
		authorize: "Autoriser",
		you_knitted: "Vous avez tricoté ",
		youre_now: "Vous êtes maintenant",

		patterns_easy: "Patrons débutants",
		patterns_medium: "Patrons faciles",
		patterns_hard: "Patrons Intermédiaires",
		or:"ou",
		ravelry_library: "Ma bibliothèque Ravelry",
		ravelry_search:"Chercher sur Ravelry",
		import_pattern: "Importer un Patron PDF",
		import_to_counter: "Importer vers le compteur de rang",
		downloading:"Téléchargement, cela peut prendre quelques minutes...",
		select_source:"Depuis quelle source souhaitez-vous importer votre patron ?",
		confirm_import_webpage: "Cette page sera importée telle que vous la voyez maintenant. Continuer ?",
		confirm_delete_imported_pattern:"Enlever le patron?",
		ask_img_upload: "Votre image pourrait être visible par les autres utilisateurs, souhaitez-vous continuer ?",
		add_picture: "Ajouter une Photo",
		send_feedback:"Nous envoyer vos retours",
		important_feedback:"Votre avis nous importe !",
		are_you_satisfied: "Etes-vous satisfait de votre oeuvre ?\n\nLaissez-nous vos impressions pour nous aider à améliorer Tricoton",
		send:"Envoyer",
		thanks:"Merci",
		time_elapsed: "Temps",
		long_press: "Appuyez longtemps sur un projet pour plus d'options",
		take_pic: "Prendre photo",
  		gallery_pic: "Sélectionner depuis bibliothèque",
		select_pic: "Veuillez choisir:",
		reset_timer:"Réinitialiser le Chrono",
		see_picture:"Voir Photo",
		change_picture:"Changer Photo",
		stitches_on_row:"Mailles sur ce rang:",
		storage_full: "Plus de place sur votre appareil. Supprimez des apps ou des fichiers et reessayez.",
		phone: "Votre appareil",
		select_language:"Choisir langue",
		download: "Télécharger",
		reset_counter:"Réinitialiser le compteur",
		started_on:'Commencé le',
		time_elapsed2:'Temps écoulé',

		detailed_pattern:"Patron détaillé",
		real_virtual_pattern:"dans l'app et version papier",
		have_kit:"J'ai deja le kit",
		buy:"Acheter",
		enter_command:"Pour accéder au patron, veuillez indiquer ci-dessous le numéro de commande (vous le trouverez dans l'email de confirmation de commande que vous avez reçu).",
		command_number:"Numéro de commande :",
		inside:"Contenu :",
		price:"Prix :",
		coloris:"Coloris :",
		kit_from:"Kit de :",
		galerie:"Galerie",
		invalid_order:"Numéro de commande invalide.",
		network_error:"Erreur de réseau, vérifiez votre connexion.",
		need_help_contact:"Besoin d'aide ? Contactez-nous sur tricotonapp@gmail.com",
		access_pattern:"Accéder au patron",

		auto_translate:"La description ci-dessous a été traduite automatiquement. Le patron est lui traduit manuellement.",
		raverly_suggestions:'Nos favoris sur Ravelry',
		needle_size:"Taille aiguilles :",
		wool_size:"Taille Laine :",
		yardage:"Métrage :",
		in_your_language:"Dans votre langue :",
		yes:"oui",
		no:"non",
		access_ravelry_pattern:"Pour accéder au patron, créez un project normalement, puis cliquez sur importer un patron, et allez dans votre librairie Ravelry (ou téléchargez le sur votre appareil, et selectionnez l'option importer depuis l'appareil)",
		have_pattern:"J'ai déjà le patron",
		buy_download_hint:"Après avoir acheté le patron, cliquez sur le bouton \"download\" (ou \"téléchargement\" le cas échéant) sur la page web pour créer un nouveau projet.",


		you_no_account:"Vous n'avez pas de compte?",
		login: "Connexion",
		already_have_account:"J'ai déjà un compte",
	    register:"S'inscrire",
	    username:"Nom d'utilisateur",
	    your_email:"email",
	    your_password:"mot de passe",
	    password_not_equal:"Mot de passe different",
	    repeat_password:"Répétez le mot de passe",
	    enter_username: "Entrez un nom d'utilisateur",

		login_desc:"Connectez-vous pour utiliser l'app sur plusieurs appareils",

		try_premium:"Essayez la version Premium !",
		help_us:"Pour nous permettre de continuer à améliorer l'app et accèder à toutes les fonctionnalités, achetez la version Premium.",

		premium_abo: "Abonnement Premium",

		no_ads:"Pas de pub",
		all_patterns_unlocked:"Tous les patrons débloqués",
		access_every_device:"Synchronisation: utilisez l'app sur plusieurs appareils, vos projets se mettent à jour partout !",
		unlimited_counters:"Autant de compteurs secondaires que vous le souhaitez.",
		unlimited_pictures:"Ajoutez des photos !",
		try_premium_btn:"Essayer la version premium",
		continue_free:"Continuer avec la version gratuite",
		login_required:"Connectez-vous pour sauvegarder vos projets en ligne.",
		buy_required:"La synchronisation n'est pas activée. Découvrez la version Premium de l'app",
		log_out:"Se déconnecter",
		year:"an",
		month: "mois",
		monthly_sub: "Abonnement Mensuel",
		annual_sub: "Abonnement Annuel",

		premium_feature:"Cette fonctionalité est réservée à la version Premium de l'app",
		premium_pattern:"Ce patron est un patron Premium",
		try_free:"Essayez gratuitement la version Premium pendant 1 semaine !",
		learn_more_premium:"Apprenez-en plus sur la version premium",
		wrong_pwd_email:"Email ou mot de passe incorrect",
		error_occured_check_connection:"Une erreur est survenue. Êtes-vous connecté à internet ?",
		already_paid:"J'ai déjà un abonnement Premium",
		enter_description:"Ajoutez une description",
		invalid_email:"Email non valide",
		email_already_registered:"Email déjà existant",
		username_already_registered:"nom d'utilisateur déjà existant",
		free_week_trial:"7 jours d'essai gratuit !",
		learn_about:"Découvrez",






		explain_why_premium_title:"Pourquoi y a-t-il une version premium ?",
		explain_why_premium_1:"Nous avons commencé à travailler sur l’application en Janvier 2018 pour sortir une première version 2 mois après. Depuis nous travaillons chaque jour à l’améliorer. Celle-ci est restée gratuite et sans publicités pendant près d’un an, et ce alors que cette application c’est :",
		explain_why_premium_2:"3500 heures de travail",
		explain_why_premium_3:"prises sur notre temps libre",
		explain_why_premium_4:"Des frais de serveurs et de traduction",
		explain_why_premium_5:"financés par nos économies",
		explain_why_premium_6:"Être disponible semaine et weekend",
		explain_why_premium_7:"pour répondre à vos emails et commentaires",
		explain_why_premium_8:"Nous adorons ce projet et souhaitons continuer à améliorer l’application pour qu’elle réponde au mieux à vos besoins, mais nous ne pouvons continuer à perdre de l’argent pour cela.\n\nL’abonnement Premium est donc pour vous un moyen de nous soutenir, et de nous permettre de continuer et d'améliorer ce beau projet! ",


		see_premium:"Voir l'offre premium >",
		why_not_free:"Pourquoi cette app n'est elle pas complétement gratuite ?",

		ad_warning:"Il est possible que des pubs apparaissent ici. Découvrez pourquoi",

		remove_ads:"Enlever seulement les pubs",

		continue:"continuer",


		buy_pattern_desc:"Vous pouvez acheter ce patron : vous verrez toujours des pubs dans l'appli.",
		buy_all_pattern_desc:"Achetez tous les patrons en une fois. Vous économisez 80% et ne voyez plus aucune publicité. ",
		buy_premium:"Ou essayez Tricoton Premium: libre accès à toutes les fonctionalités et tous les patrons Premium, sans aucune publicité. ",
		buy_pattern_price:"ACHETER CE PATRON : ",
		buy_all_patterns_price:"ACHETER TOUS LES PATRONS : ",

		buy_all_pattern_no_ads:"Achetez tous les patrons et enlevez la publicité.",

		password_forgotten:"Mot de passe oublié ?",

		projects_synchro:"Projets sauvegardés sur le server",
		last_update:"mise à jour",
		show_reset: "Afficher nombre de réinitialisations",

		annotations_hint:"Vous pouvez cliquer longtemps sur le document pour ajouter des annotations.",
		annotations_hint_premium:"Vous pouvez cliquer longtemps sur le document pour ajouter des annotations.",
		add_annotation_text:"Ajoutez votre annotation ici...",

		select_mode:"Selectionnez mode",
		knitting_mode: "Tricot",
		crochet_mode: "Crochet",


		badge1_crochet: "Niveau 1",
		badge2_crochet: "Niveau 2",
		badge3_crochet: "Niveau 3",
		badge4_crochet: "Niveau 4",
		badge5_crochet: "Niveau 5",
		badge6_crochet: "Niveau 6",

		type:'type',

		discard_changes:'Annuler les modifications ?',


		agree:'Accepter',
		agree_email:'J\'accepte de recevoir des emails de l\'app',
		see_terms: 'Voir termes et politique de confidentialité',
		must_agree_pp: 'Vous devez accepter les termes et politique de confidentialité pour continuer',
		duplicate: 'dupliquer',

		no_ravelry_project:'Vous n\'avez pas encore de projet sur Ravelry.',
		already_imported:'Vous avez déjà importé ce projet',
		import_ravelry:"ou importer depuis Ravelry",
		import:'Importer',
		small:'petite',
		normal:'normale',
		big:'grande',
		options:'Options',
		show:'Montrer',
		hide:'Cacher',
		long_press_position:'Appuyez longtemps sur compteur pour le déplacer',
		enable_expert_options:'Vous pouvez activer le mode expert dans les options',
		enable_expert:"Activer le mode expert",
		counter_size:"Taille des compteurs",
		highligther_size:'Taille du surligneur',
		starts_at:'Commence à',
		max_inf_begin:'Le nombre de rangs ne peut pas être inférieur au nombre "Commence à"',
		reset:'Réinitialiser',

		then_price:'Puis',
		hours:'heures',
		minutes:'minutes',
		seconds:'secondes',
		configure:'Configurer',
		add_to_timer: 'et ajouter automatiquement au chronomètre si le temps est inférieur à:',
		keep_timer_off:"Continuer à chronomètrer quand l'écran est éteint",
		timer_ask:"{0} se sont écoulées depuis la dernière fois, voulez-vous les ajouter au chronomètre ?",
		add_time_timer:"Ajouter temps au chronomètre ?",
		select_camera: "Prendre une photo",
    	select_gallery: "Sélectionner depuis galerie",
    	photo:'Photo',
    	new_start_zero:'Les nouveaux compteurs commencent à 0 par défaut',


    	syncing:'Synchronisation',
		sync_error:'Impossible de synchroniser',

		connect_watch:'Connecter Apple Watch (Premium)',
		watch_not_connected:'Apple Watch (peut-être) non connectée (ou ecran éteint)',
		watch_connected:'Apple Watch connectée',


		troubleshoot_watch:"Aide",
reset_all_timers:"Réinitialisation du Timer? Tout le temps passé sur TOUS vos projets sera remis à 0.",
premium_file_sharing:"Seuls les utilisateurs Premium peuvent utiliser le partage de fichiers.",
shared_with_me:"Partagé avec moi",
no_shared:"Personne n'a partagé un projet avec vous encore.",
accept:"J'accepte",
from:"de",
share_project:"Partager projet",
share_copyright_check:"Je ne partagerai pas de document sous copyright",
share_copyright:"Prière de respecter les auteurs et de partager uniquement des documents pour lesquels vous avez la permission. Le partage de matériel protégé peut entraîner la suppression de votre compte.",
share_project_with:"Je veux partager ce projet avec",
disable_social:"Désactiver les fonctionnalités sociales",
friends:"Ami(s)",
shared:"partagé",
chats:"messages",
chat:"Message",
join_community:"Rejoignez la communauté!",
social_desc:"Trouvez des amis et partagez avec eux vos exploits ! Vos amis peuvent voir votre progression, vos photos et vous pouvez partager vos projets avec eux.",
projects:"Projets",
unfriend:"Retirer cet ami",
congratulate:"Féliciter",
wait_confirm:"En attente de confirmation",
not_found:"Pas trouvé",
invite_friend:"Inviter un ami",
invite_ext:"Salut, je voudrais vous inviter à essayer l'application Compteur de Rangs. Installez-la et ajoutez-moi comme ami mon nom d'utilisateur est {0}",
add_friend_desc:"Ajouter vos amis sur l'application afin de communiquer avec eux et partager vos progrès. Les amis pourront voir vos photos.",
send_invitation:"Envoyer une invitation",
add_friend:"Ajouter un ami",
no_friend_yet:"Aucun ami encore",
start_chatting:"Cliquez ici pour commencer à discuter",
you_finished_project:"Vous avez terminé un projet",
you_started_project:"Vous avez commencé un nouveau projet",
finished_project:"a terminé un projet",
started_project:"a commencé un nouveau projet",
reached_level:"est maintenant niveau",
you_reached_level:"Vous êtes maintenant niveau",
sent_message:"vous a envoyé un message",
congratulated_you:"vous a félicité 👏",
shared_project_with_you:"a partagé un projet avec vous",
wants_to_be_friend:"veut être votre amie!",
became_your_friend:"est devenue votre amie!",
type_your_message:"Tapez votre message",


login_social:"Se connecter pour accèder à la communauté",

	faq_translated:"Cette FAQ est seulement disponible en anglais pour l'instant, voulez-vous voir une traduction automatique?",


	watch_help_1:"Sur votre montre, lancez l'app Compte Rang. Sur ce telephone, selectionnez un projet. Si ça ne fonctionne pas suivez les instructions ci-dessous",
		watch_help_2:"Ouvrez l'app «Watch» sur votre iPhone.",
		watch_help_3:"Allez en bas pour trouver l'application row_counter, et cliquez dessus.",
		watch_help_4:"Desactivez et reactivez l'app",
		watch_help_5:"Lancez l'app sur votre montre. Sur ce telephone, selectionnez un projet.",


		select:'Select',
		select_part:'Sélectionnez Partie',
		next_part:"Allez à la prochaine partie",
		part_finished:"Partie terminée",
		part_finished_desc:"Félicitations, vous avez terminé cette partie!",
		part:"partie",
		delete_part:"Supprimer cette partie? Tous les compteurs dans cette partie seront supprimés.",

		highlight_vertical:"Afficher le surligneur verticalement",


		back:"Retour",
		configure_ads:"Configurer les pubs",
		partner_click:"Cliquez sur l'un de nos partenaires pour savoir comment ils utilisent vos données",
		how_use_data:"Comment utilisons nous vos données",
		tailor_ads:"Pouvons-nous personnaliser les publicités en fonction de vos données ?",
		consent_desc:"Vos données personnelles seront protégées. Les publicités nous permettent de proposer une version gratuite.",
		consent_desc2:"Vous pouvez changer votre choix à n'importe quel moment dans les options de l'app. Cliquez ci-dessous pour decouvrir comment nous et nos partenaires traitont vos données personnelles.",
		how_collect_data:"Comment mes données sont-elles utilisées",
		relevant_ads:"Oui, personnaliser en fonction de mes goûts",
		ad_free:"Achetez la version sans pubs",
		not_relevant_ads:"Non, voir des pubs qui peuvent ne pas me correspondre",


		enable_tracking: "GDPR et respect de vos données personnelles",
        enable_tracking_desc: "Nous nous soucions de votre vie privée et de la sécurité de vos données. En autorisant les cookies, nous améliorerons votre expérience sur cette application, nous aurons plus d'informations en cas de bogue afin de pouvoir le corriger, nous adapterons les fonctionnalités à vos besoins et l'identifiant publicitaire nous permettra d'afficher moins de publicités qui ne vous concerneraient pas",
        allow_cookies: "Autoriser",
        disallow_cookies: "Ne pas autoriser. Je comprends que mon expérience sur cette application sera moins adaptée à mes besoins et que mes problèmes ne seront pas signalés aux développeurs",

        convert_what:"Que voulez-vous convertir?",
        to:"à",

		activate:"Activer",

		yarn_yards: "yards",
		yarn_meters: "mètres",
		ounces:"ounces",
		add:"Ajouter",
		select_this_yarn:"Sélectionner cette laine",
		select: "Sélectionner",
		stash: "Stock Laine",
		stash_ravelry_beta:"Cette fonctionnalitée est en beta, pour tout problème contacter tricotonapp@gmail.com",
		adjust_no_sync:"Les ajustements ne peuvent pas être synchronisés avec Raverly",
		import:"Importer",
		select_needle:"Sélectionner Aiguille",
		remove:"Délier",
		nothing_here:"Il n'y a pour l'instant rien ici.",
		see_needles:"Voir Aiguilles",
		see_stash:"Voir Stock Laine",
		needles:"Aiguilles",
		needle:"Aiguille",
		straight:"droite",
		double: "double",
		circular: "circulaire",
		steel: "metal",
		hook: "crochet",
		link_ravelry_proj:"Voulez-vous lier ce projet à un projet Ravelry existant ?",
		link_ravelry_proj_hint:"Sélectionnez non si vous n'utilisez pas Ravelry ou vous n'avez pas créé ce projet sur Ravelry",
		must_be_img_pdf:"Le ficher n'a pas pu être téléchargé, seuls des images ou fichier PDFs peuvent être téléchargés",
		cannot_be_zero:"Ne peut pas être 0",
		color:"Couleur",
		add_from_stash:"Ajouter depuis stock de laine",
		link_needles:"Lier aiguilles au projet",
		import_ravelry2:"Importer depuis Ravelry",
		create:"Créer",
		starting_quantity:"Quantité de départ:",
		use:"Utiliser",
		adjust:"Ajuster",
		edit_yarn:"Modifier la laine",
		yarn_stash:"Stock de laine (Beta)",
		needle_stash:"Aiguilles (Beta)",
		sync_ravelry:"Synchroniser avec Ravelry",
		enter_weight:"entre poids",
		enter_length:"entrer longueur",
		totals:"Totaux",
		nb_skeins:"Nombre de pelotes",
		name:"Nom",
		company_name:"Nom du fabriquant",
		yarn_weight:"Grosseur de laine",
		unlink_ravelry:"Enlever lien à Ravelry",
		colorname:"Nom de la couleur",
		select_weight:"Sélectionner poids",
		use_nb_skeins:"Nombre de pelotes",
		use_totals:"Utiliser totaux",
		each_skein:"Chaque pelote fait",
		notes:"Notes",
		edit:"Modifier",
		disconnect_ravelry:"Déconnecter de Ravelry",
		type:"Type",
		length:"Longueur",
		size:"Taille",
		notes:"Notes",
		select_all:"Sélectionner tout",
		select_corresponding_project:"Sélectionnez projet correspondant sur Ravelry",
		used_by:"Utilisé par",
		left_stash:"Restant dans le stock",

		zoom_hint:"Vous pouvez aussi double cliquer ou écarter deux doigts sur l'écran pour zoomer",

		other_sources:"Autres sources",

		create_custom_proj:"J'ai un patron",
		create_custom_proj_desc:"Multiple compteurs et import de votre patron possible depuis n'importe quelle source",
		import_rc_pattern:"À la recherche d'inspiration",
		import_rc_pattern_desc:"Jetez un oeil à nos patrons gratuits, vous en trouverez peut-être un qui vous plaira",
		import_project_ravelry:"J'ai un projet Ravelry",
		import_project_ravelry_desc:"Multiple compteurs et import de votre projet Ravelry",
		ask_resize_counters:"Voulez-vous afficher les compteurs en plus petit ? Vous pouvez changer la taille à tout moment dans les options.",
		start:"Commencer",
		personal_source:" ",
		external_source:" ",

		/* PATTERN CREATOR */
		cm10inches4: '10 cm',
		rows: 'rangs',
		gauge:'Échantillon',
		row:'rang',
		login_pattern_generator:"Connectez-vous pour créer des patrons",
		preview:"Preview",
		no_gauge:"Échantillon non spécifié",
		worked_in:"Travaillé en",
		yarns:'pelottes',
		pattern_creator:"Créateur de Patrons",
		pattern_creator_log_in:"Vous devez créer un compte pour utiliser le créateur de patron",
		tuto_title_pattern_creator:"Vous voulez en savoir plus sur le créateur de patron ?\nRegardez cette vidéo d'introduction",
		glossary:"Glossaire",
		no_glossary:"Glossaire non spécifié",
		size:"Taille",
		next_part_is:"Partie suivante",
		previous_part_is:"Partie précédente est:",
		previous_part: "Partie précédente",
		go_to_intro: "Aller à l'intro",
		add_part_counters:"Vous devez d'abord ajouter du contenu avant de configurer les compteurs",
		add_part:"Ajouter Partie",
		no_material:"Matériel non spécifié",
		new_pattern:"Nouveau Patron",
		public_pattern:"Patron public. Les autres utilisateurs pourront le voir.",
		browse_pattern: "Parcourir les patrons",
		confirm_import_and_reset: "Importer ce patron resetera votre projet, continuer ?",
		create_account:"Créer compte",
		add_yarn:"Ajouter laine",
		sharing_link:"Lien de partage",
		link_copied:"lien copié dans le presse papier",
		publish:"Publier",
		generate:"Générer",
		edit_content:"Modifier contenu",
		create_proj_from_pattern:"Créer projet depuis ce patron",
		more:"Plus",
		import_rc_desc:"Ou importez un des projets crées exprès pour l'app, par la communauté",
		download_as_pdf:"Télecharger en format PDF",
		generate_first:"Vous devez d'abord générer votre patron avant de l'importer",
		publish_changes:"Publier les changements",
		generate_changes:"Générer changements",
		no_publish_needed: "Toutes les modifications ont déjà été publiées",
		changed_not_published:"Vous avez des modifications non générées",
		continue_anyway: "Continuer quand même",
		select_size:"Sélectionner la taille",
		pdf_in_download:"Le PDF a été téléchargé dans le dossier téléchargements.",
		abreviation:"Abréviation",
		new_abreviation:"Nouvelle Abréviation",
		technic:"Technique",
		new_technic:"Nouvelle Technique",
		technics:"Techniques",
		no_technics:"Pas de techniques",
		pattern_content:"Contenu du patron",
		saved:"Sauvegardé",
		hide_in_search:"Ne pas montrer le patron dans les résultats de recherche",
		can_share:"Vous pouvez toujours partager votre patrons avec le lien de partage, mais seuls les utilisateurs ayant le lien pourront y accéder.",
		everybody_see:"Tout le monde peut voir votre patron. Seul vous pouvez le modifier.",
		counters_not_limited_beta: "Seuls les utilisateurs Premium peuvent normalement voir plus de deux compteurs secondaire, mais nous avons temporairement levé cette restriction.",
		add_content:"Ajouter contenu",
		add_needle:"Ajouter aiguilles",
		available_sizes:"Tailles disponibles",
		video:"Vidéo",
		move_section: "Appuyer longtemps sur une section pour la déplacer",
		move:"Bouger",
		edit_part:"Modifier partie",
		published_when_validated:"Votre patrons sera visible des autres utilisateurs après vérification.",
		pattern_not_supported:"Ce patron a été créé avec une version plus récente de l'app. Updatez l'app pour y accéder.",
		add_free_text:"Ajouter texte libre",
		add_row_instruct:"Ajouter instructions de rangs",
		edit_section:"Modifier Section",
		create_symbol:"Créer symbole",
		chart_preview:"Prévisualiser schéma",
		only_colors_shown:"Seules les couleurs sont ici affichées",
		why_few_patterns:"Pourquoi y a-t-il si peu de patrons ?",
		why_few_patterns_desc:"Nous venons de sortir la création de patrons, d'autres patrons suivront dans les prochaines semaines !",
		size_instruction:"Ajouter instructions spécifiques par tailles",
		text:"texte",
		width:"Longueur",
		height:"Hauteur",
		worked_flat:"Travaillé à plat",
		worked_round:"Travaillé en rond",
		chart:"Schéma",
		image:"Image",
		all_sizes_done:"Toutes les tailles ont été faites",
		sizes:"Tailles",
		show_chart_sizes:"Montrer le schéma pour les tailles",
		duplicate_chart_size:"Dupliquer schéma pour les tailles",
		add_chart_variation:"Ajouter une variation du schéma pour certaines tailles",
		cell: "cellule",
		quantity:"Quantité",
		add_quantity:"Ajouter quantité",
		highlight_premium:"le highligher et l'édition de texte sont des fonctionnalités Premium",
		hint_creator_content:"Vous pouvez ajouter ici vos instructions.\nLe contenu peut être du texte, des images, des vidéos ou des schémas.\nVous pouvez ajouter plusieurs parties si votre patron est constitué de plusieurs pièces",
		my_patterns:"Mes patrons",
		new_section:"Nouvelle Section",
		head:"Tête",
		neck:"Cou",
		torso:"Torse",
		legs:"Jambe",
		feet:"Pieds",
		hands:"Mains",
		accessories:"Accessoires",
		pets:"Animal",
		other:"Autre",
		setup_counters:"Configurer compteurs",
		add_picture_next_text:"Ajouter photo qui apparaitra à coté du texte",
		select_category:"Choisir catégorie",
		additional_infos:"Infos complémentaires",

		patterns_sizes: {
			newborn:"Nouveau né",
			kid:"Enfant",
			teenager:"Adolescent",
			men:"Homme",
			women:"Femme",
			mixed:"Unisexe",
			cat:"Chat",
			dog:"Chien",

			one_size:"Taille unique",
			months:"mois",
			years:"ans",
		},

		allrcpatterns: {
    		title: "Patrons CR",
    		areyounew: "Vous découvrez les patrons Compte-Rangs ?",
    		watchcontent: "Regardez notre intro en vidéo",
    		create: "Créez votre propre patron",
    		faq: "FAQ",
    		video: "Vidéo",

    	},

		create_pattern:"Créer Patron",
    	create_new_pattern:"Créer mon premier patron",
    	no_pattern:"Pas encore de patron",
    	watch_tuto_video:"Regardez notre tuto en vidéo",
    	read_faq:"Lire la FAQ",
    	see_users_patterns:"Voir les patrons publiés par d'autres utilisateurs",
    	all_patterns_desc:"Ici vous pouvez voir les patrons créés par les autres utilisateurs.\nVous pouvez les importer, ce qui créera un projet dans l'app, avec compte rangs et autres fonctionnalités",
    	all_patterns_desc_pc:"Ici vous pouvez voir les patrons publiés publiquement par les autres utilisateurs.\nVous pouvez les importer, ce qui créera un projet dans l'app, avec compte rangs et autres fonctionnalités",

		pattern_name:"Nom du patron",
		want_other_import:"Je veux importer des patrons d'une autre source",
		knit_crochet_other_source:"Tricoter ou crocheter un patron d'une autre source",
		patternus_desc:"Patternum vous permet de créer de super patrons optimisés pour mobile",
		rc_desc1:"Vous pouvez suivre un patron Patternum directement dans l'app, mais si vous souhaitez importer un patron PDF, utilisez notre autre app:",
		rc_desc2:"Cette app vous permet de suivre tous vos patrons, PDF ou Web, avec highligher, comments, annotations, etc...",
		discover_rc:"Découvrir Compte rang",
		patterns_created:"Patrons créés",
		patterns_publish:"Patrons publiés",
		patternus_projects:"Voyez ici tous les projets créés depuis un patron Patternum",
		difficulty:"Difficulté",
		category:"Catégorie",
		chart_settings:"Options du Schéma",
		rs:"Endroit",
		ws:"Envers",
		rs_short:"Endroit",
		ws_short:"Envers",
		size_in_pdf:"Taille dans le PDF",

		appears_rs:"Apparait à droite sur PDF",
		appears_ls:"Apparait à gauche sur PDF",


		vertical: "Vertical",
		horizontal: "Horizontal",
		all_patterns: "Tous les patrons",

		appear_row:"Apparait au rang:",
		single_row:"sur seul rang",
		range_rows:"sur plusieurs rangs",

		columns:"Colonnes",
		lines:"Lignes",

		title:"Titre",
		icon:"icon",
		document_locked:"Impossible d'ajouter des annotations, PDF protégé par l'auteur",

		highlight_annotation_more:"Highlighteur, annotations sur PDF, et plus encore!",
		cancel_anytime:"Vous pouvez annuler quand vous voulez",
	},
	// NEW LANG
	de: {
		areYouSure: "Sind Sie sicher?",
		highlighter: "Highlighter",
		draw: "Zeichnen",
		opaque: "undurchsichtig",
		eraser: "Radiergummi",
		importWarning1: "Wenn Ihr Muster nicht im PDF-Format vorliegt (Bild, Webseite...), verwenden Sie Zähl Mich auf einem Emulator (für Web,",
		importWarning2: "Siehe Details hier",
		importWarning3: ") oder stattdessen in der mobilen App",
		machine:"Machine",
		loom_knitting:"Loom",
		schemaTool:{
			noColor:"keine Farbe",
			editSymbolHelper:"Klicken Sie auf Ihr benutzerdefiniertes Symbol, um es zu bearbeiten",
			hideSymbols:"Symbole ausblenden",
			showSymbols:"Symbole anzeigen.",
			rightSide:"Rechte Seite",
			wrongSide:"Linke Seite"},
		patternCreator:{
			patCreator:"Pattern Creator",
			language:"Sprache",
			sizes:"Größen",
			category:"Kategorie",
			missingInfosText:"Bitte geben Sie die folgenden fehlenden Details:",
			missingInfosTitle:"Einige Informationen fehlen",
			difficulty:"Schwierigkeit",
			selectCat:"Kategorie wählen",
			updatePattern:"Anleitung aktualisieren.",
			createPattern:"Anleitung erstellen",
			selectSize:"Bitte wählen Sie zuerst eine Größe aus",
			alertChangesTitle:"Sie haben Änderungen erzeugt",
			alertChangesText:"Möchten Sie die PDF-Version ohne die letzten Änderungen herunterladen, seit Sie Ihre Anleitung zum letzten Mal erstellt haben?",
			alertNotPublishedTitle:"Anleitung erzeugen",
			alertNotPublishedText:"Sie müssen zuerst Ihre Anleitung generieren, damit wir das PDF erstellen können",
			alertDeleteTitle:"Anleitung löschen",
			alertDeleteText:"Sind Sie sicher, dass Sie diese Anleitung löschen möchten?",
			alertPublicText:"Sie können diese Anleitung öffentlich machen oder privat halten. Wenn private, nur Personen, mit denen Sie Ihre Anleitung teilen, können Sie darauf zugreifen. Wenn die Öffentlichkeit, Benutzer der Zeilenzähler-App und der Website Ihre Anleitung finden und verwenden können.",
			generatePublic:"Als öffentlich generieren",
			generatePrivate:"Als privat generieren",
			additionalInfos:"Zusätzliche Infos.",
			patternListTitle:"Pattern Creator",
			patternListText:"Bauen Sie Ihre eigenen Anleitungen. Diese sind interaktiv und reagieren in der Zähl Mich App. Sie können sie auch als PDF exportieren",
			yourPatterns:"Deine Anleitungen",
			backToContent:"Zurück zum Inhalt",
			imageSection:"Bildbereich.",
			videoSection:"Videoabschnitt.",
			alertDeleteContentTitle:"Inhalt löschen",
			alertDeleteContentText:"Sind Sie sicher, dass Sie diesen Inhalt löschen möchten?",
			fromRow:"Aus der Reihe",
			onRow:"Auf der Reihe",
			toRow:"Zum Reihe",
			RangeOfRows:"Reihen:",
			alertNumbers:"Bitte geben Sie nur Zahlen ein",
			enterInstructions:"Geben Sie hier Ihre Anweisungen ein:",
			updatePic:"Bild aktualisieren.",
			addPic:"Fügen Sie ein Bild hinzu, das auf der Seite dieses Abschnitts angezeigt wird",
			addText:"Free Text hinzufügen",
			addRowText:"Reihenanweisung hinzufügen.",
			textSection:"Textabschnitt",
			addContent:"Inhalt hinzufügen",
			helpOne:"Hier können Sie Ihre Anleitungsanweisungen hinzufügen.",
			helpTwo:"Erstellen Sie zuerst einen Teil",
			helpThree:"Fügen Sie dann Inhaltsabschnitte hinzu. Sie können Text, Bild, Video oder Diagramme sein.",
			helpfour:"Sie können weitere Teile hinzufügen, wenn Ihr Artikel aus mehreren zusammengebauten Teilen besteht.",
			editPartName:"Teilname bearbeiten:",
			givePartName:"Name des neuen Teils:",
			addContentSection:"Fügen Sie einen Inhaltsabschnitt hinzu",
			chooseContentType:"Wählen Sie einen Inhaltstyp:",
			alertAddPartTitle:"Fügen Sie zuerst einen Teil hinzu",
			alertAddPartText:"Fügen Sie mindestens einen Teil hinzu, bevor Sie Inhalte hinzufügen.",
			alertChartTitle:"Nur in der App verfügbar",
			alertChartText:"Sie können Ihr Diagramm nur von der App auf Ihrem Telefon oder Tablet bearbeiten.",
			patternContent:"Inhalt",
			gaugeStitch:"Anzahl der Masche, um eine 10 cm / 4 Zoll-Breite zu erreichen:",
			gaugeRow:"Anzahl der Reihen, um eine Höhe von 10 cm / 4 Zoll zu erreichen:",
			manual:"Manuell",
			fromList:"Aus einer Liste",
			kniGlossary:"Strickglossar",
			croGlossary:"Häkelglossar",
			update:"Aktualisieren",
			addTool:"Werkzeug hinzufügen",
			addYarn:"Fügen Sie Garn hinzu",
			yarnName:"Garnname:",
			yarnCompanyName:"Garn-Firmenname:",
			yarnWeight:"Garngewicht:",
			selectWeight:"Gewicht auswählen",
			yarnColor:"Garnfarbe:",
			neededYarn:"Benötigtes Garn:",
			materialType:"Materialtyp",
			selectLength:"Länge auswählen",
			neededTools:"Benötigte Werkzeuge:",
			alertAllInfos:"Bitte füllen Sie alle Informationen aus",
			dlPdf:"PDF Herunterladen",
			generate:"Generieren",
			fileSelected:"Ausgewählte Datei",
			chooseFile:"Datei zum Hochladen auswählen",
			uploadPic:"Laden Sie ein Bild hoch",
			replacePic:"Bild ersetzen",
			replaceVid:"Video ersetzen"},
		patterns_sizes: {
			newborn: "Neugeborenes",
			kid: "Kind",
			teenager: "Teenager",
			men: "Männer",
			women: "Frauen",
			gemischt: "Unisex",
			cat: "Katze",
			dog: "Hund",

			one_size: "Einheitsgröße",
			months: "Monate",
			years: "Jahre",
		},
		counter: "Zähler",
		text:"Text",
		pattern_name: "Name",
		add_part:"Teil hinzufügen",
		technics:"Techniken",
		head: "Kopf",
		neck: "Hals",
		torso: "Torso",
		legs: "Beine",
		feet: "Füße",
		hands: "Hände",
		accessories: "Zubehör",
		pets: "Haustier",
		other: "Sonstiges",
		glossaryP: {
			glossary: "Glossar",
			subtitle: "Strick- und Häkelglossar",
			searchPh: "Suche nach einer Abkürzung in der Sprache Ihrer Wahl"
		},
		forum: {
			forum:"Forum",
			subtitle: "App Forum Inhalt",
			postInForum: "Möchten Sie an dem Forum teilnehmen?",
			downloadApp: "Laden Sie die App herunter, um mit der Community zu interagieren",
		},
distribstitches: {
            tooltitle: "Ab- & Zunahmen verteilen",
            increases: "Zunahmen",
            increase: "Zunahme",
            decreases: "Abnahmen",
            decrease: "Abnahme",
            stitches: "Maschen",
            stitch: "Masche",
            pleaseenter: "Bitte geben Sie die 2 Werte ein",
            willstart: "Es wird so beginnen:",
            desirednb: "Gewünschte Maschenanzahl",
            currentnb: "Aktuelle Maschenanzahl",
            weadvise: "Wir beraten zu tun: ",
            every: "alle",
            thefirst: "Machen Sie die erste",
            after: "nach",
            then: "dann,",
            endwith: "und beenden Sie dann mit:",
        },

        rc_web: {
        	confirm_email: "Prüfen Sie Ihre E-Mails, um Ihr Konto zu bestätigen",
        	create_new_proj: "Neues Projekt erstellen",
        	profile:"Profil",
        },

        infoImportPattern: "Klicken Sie hier, um Ihre PDF-, Web- oder Video-Anleitung zu importieren",
		lang: "de",
		tts_lang: "de-DE",
		ok: "ok",
		cancel: "stornieren",
		start_new_project: "Mein erstes Projekt starten",
		new_project: "Neues Projekt",
		project_name: "Projektname",
		enter_name: "Name eingeben",
		enter_project_name: "Bitte geben Sie einen Projektnamen ein",
		project_type: "Wählen Sie Strickanleitungen",
		next: "weiter",
		motif: "Wählen Sie den Strickmuster",
		material: "Sie benötigen",
		description: "Beschreibung",
		begin: "Beginn",
		finish: "Beenden",
		current_rang: "Aktuelle Reihe ",
		repeat: "Wiederholen: ",
		stitches: "Maschen",
		rangs: "Reihen",
		times: " mal",
		projs_completed: "Abgeschlossen",
		tutorials: "Tutorials",
		select_size: "Wählen Sie die Größe",
		select_woolsize: "Wählen Sie die Größe der Wolle",
		linked_tutos: "Verwandte Tutorials",
		quantity: "Anzahl",
		size: "Größe",
		loading: "Laden....",
		badge1: "Stufe 1",
		badge2: "Stufe 2",
		badge3: "Stufe 3",
		badge4: "Stufe 4",
		badge5: "Stufe 5",
		badge6: "Stufe 6",
		badge7: "Stufe 7",
		badge8: "Stufe 8",
		badge9: "Stufe 9",
		badge10: "Stufe 10",
		part: "Teil ",
		previous_seq: "Vorheriger Schritt",
		finished: "Sie haben Ihr Projekt abgeschlossen!",
		congratz: "HERZLICHEN GLÜCKWUNSCH!",
		row_counters: "Reihenzähler",
		global_counter: "Globaler Zähler",
		secondary_counter: "Sekundärzähler",
		nb_row: "Anzahl der Reihen",
		name_optional: "Name",
		save: "Speichern",
		auto_inc: "Verknüpfung mit globalem Zähler",
		set_up_main_counter:
			"Klicken Sie auf den Hauptzähler, um ihn einzurichten.",
		counter_project: "Reihenzähler verwenden",
		edit_counters: "Zähler bearbeiten",
		enter_comment_opt: "Geben Sie hier einen Kommentar ein",
		show_com_at_row: "Kommentar in dieser Reihe anzeigen",
		add_counter_com: "Kommentar hinzufügen",
		comments: "Kommentare",
		reinit_at: "Zurücksetzen nach Erreichen dieser Reihe",
		counters: "Zähler",
		counters_desc:
			"Verfolgen Sie Ihre Fortschritte mit unseren Reihenzählern! Sie können konfigurieren:\n\n- 1 Globaler Zähler, um die Reihen Ihres gesamten Projekts zu zählen \n\n- Mehrere sekundäre Zähler, die Ihnen helfen Ihre Muster zu erhöhen, zu verringern oder zu stricken Benötigen Sie eine Erinnerung an eine bestimmte Reihe? Fügen Sie es als Kommentar in den entsprechenden Zähler ein, es wird im richtigen Moment angezeigt!",
		set_average_stitches:
			"Bitte geben Sie die durchschnittliche Anzahl der Maschen pro Reihe (ungefähr) ein.",
		average_stitches: "Durchschnittliche Anzahl der Maschen pro Reihe",
		delete: "Löschen",
		edit: "Bearbeiten",
		change_name: "Name ändern",
		enter_number: "Fügen Sie bitte eine Nummer ein",
		meters: "M",
		centimeters: "CM",
		inches: "Inches",
		yards: "Yards",
		convert_units: "Einheiten Umrechner",
		fill_all_fields: "Fügen Sie bitte all information ein",
		your_project: "IHR PROJECT",
		compute_swatch: "Maschenprobe rechner",
		pattern: "Anleitung",
		your_swatch: "IHRE MASCHENPROBE",
		swatch: "maschenprobe",
		must_monter: "Anschlagen Sie:",
		must_knit: "Stricken Sie:",
		grams: "Gramm",
		tools: "Werkzeuge",
		the_project: "Das Projekt",
		tencm: "10cm",
		your_wool: "Ihrer Garn Ball",
		woolball_size: "Knäuel enthält:",
		wool_nb_required: "Anzahl der Knäuel Sie benötigen",
		woolballs: "Knäuel",
		number_required: "Fügen Sie bitte Nummern",
		woolball_convertor: "Knäuel Umrechner",
		activ_sound: "Activate sound to be able to use the app with your voice",
		say_next:
			"Sagen Sie 'NEXT STEP' wenn Sie mit der Reihe fertig sind (funktioniert besser mit einer tiefen Stimme in einem ruhigen Zimmer)",
		say_next_counter:
			"Sagen Sie 'NEXT STEP' um den Reihenzähler zu inkrementieren (funktioniert besser mit einer tiefen Stimme in einem ruhigen Zimmer)",
		say_next_ios:
			"Sagen Sie 'NEXT' wenn Sie mit der Reihe fertig sind (funktioniert besser mit einer tiefen Stimme in einem ruhigen Zimmer)",
		say_next_counter_ios:
			"Sagen Sie 'NEXT' um den Reihenzähler zu inkrementieren (funktioniert besser mit einer tiefen Stimme in einem ruhigen Zimmer)",

		search: "search",
		permission_not_granted: "Berechtigungen abgelehnt. Bitte erlauben",
		retry: "Wiederholen",
		help_1: "Sie können auf das Symbol klicken, um weitere Informationen anzuzeigen",
		authorize: "Erlauben",
		you_knitted: "Sie haben gestricken: ",
		youre_now: "Sie sind jetzt",

		patterns_easy: "Anfänger",
		patterns_medium: "Einfach",
		patterns_hard: "Vorgeschritten",
		or:"oder",
		ravelry_library: "Ravelry Bibliothek",
		ravelry_search:"Ravelry Suchen",
		import_pattern: "PDF Anleitung Importieren",
		import_to_counter: "Diese Webpage importieren",
		downloading:"Laden... Es könnte eine Weile dauern...",
		select_source:"Wählen Sie eine Quelle:",
		confirm_import_webpage: "Webseite Importieren?",
		confirm_delete_imported_pattern:"Anleitung löschen?",
		ask_img_upload: "Andere Benutzer könnten Ihr foto sehen, weitermachen?",
		add_picture: "Foto hinzufügen",
		send_feedback:"Rückmeldung schicken",
		important_feedback:"",
		are_you_satisfied: "Sind Sie mit den Anleitungen zufrieden? Was könnten wir besser machen?",
		send:"Schicken",
		thanks:"Danke!",
		time_elapsed: "Zeit",
		long_press: "Langer Druck auf Projekt für Optionen",
		take_pic: "Foto aufnehmen",
  		gallery_pic: "Foto auswählen",
		select_pic: "Bitte wählen:",
		reset_timer:"Timer zurücksetzen",
		see_picture:"Foto ansehen",
		change_picture:"Foto ändern",
		phone: "Ihr Gerät",
		select_language:"Sprache Wählen",
		download: "Laden",
		reset_counter:"Reihenzähler zurücksetzen",
		started_on:'Angefangen am:',
		time_elapsed2:'Zeit',

		need_help_contact:"Brauchen Sie Hilfe ? Schicken Sie eine Mail zum tricotonapp@gmail.com",
		auto_translate:"Automatische Übersetzung. Die Anleitung ist aber manuelle übersetz.",
		raverly_suggestions:'Wir lieben auf Ravelry',
		wool_size:"Garnstärke :",
		needle_size:"Nadelstärke :",
		yardage:"Lauflänge :",
		in_your_language:"In Ihrer Sprache :",
		yes:"Ja",
		no:"Nein",
		access_ravelry_pattern:"Um die Anleitung in der App zuzugreifen, erstellen Sie ein neues Projekt, und importieren Sie die Anleitung aus Ihrer Ravelry Bibliothek",
		have_pattern:"Die Anleitungen habe ich schon",
		buy_download_hint:"Nach dem Kauf, klicken Sie auf \"Download\".",


		you_no_account:"Sie haben noch kein Konto?",
		login: "Einloggen",
		already_have_account:"Ich habe bereits ein Konto",
		register:"Registrieren",
		username:"Benutzername",
		your_email:"Ihre E-Mail",
		your_password:"Ihr Passwort",
		password_not_equal:"Passwort nicht identisch.",
		repeat_password:"Passwort wiederholen",
		enter_username: "Geben Sie einen Benutzernamen ein",

		login_desc:"Einloggen um die App auf mehreren Geräten zu nutzen",

		try_premium:"Testen Sie die Premium-Version der App!",
		help_us:"Damit wir weiter an dieser App arbeiten können und Sie alle Funktionen genießen kannst, bitten wir Sie um Ihren Beitrag.",

		premium_abo: "Premium-Abo",
		no_ads:"Keine Werbung",
		all_patterns_unlocked:"Alle Anleitungen sind freigeschaltet.",
		access_every_device:"Synchronisation in der Cloud: Zugriff auf Ihre Projekte von allen Ihren Geräten aus. So verlieren Sie nie Ihre Fortschritte!",
		unlimited_counters:"So viele Reihenzähler pro Projekt wie sie wollen!",
		unlimited_pictures:"Bilder hinzufügen",
		try_premium_btn:"Premium-Version ausprobieren",
		continue_free:"Weiter mit der kostenlosen Version",
		login_required:"Bitte melden Sie sich an, um Ihre Projekte sichern zu können.",
		buy_required:"Die Synchronisation ist deaktiviert. Hier erfahren Sie mehr über alle Premium-Funktionen.",
		log_out:"Abmelden",
		year:"Jahr",
		month: "Monat",
		monthly_sub: "Monatliches Abo",
		annual_sub: "Jahres Abo",

		premium_feature:"Dies ist eine Premiumfunktion",
		premium_pattern:"Dies ist eine Premium-Anleitung.",
		try_free:"Testen Sie Premium eine Woche lang kostenlos!",
		learn_more_premium:"Hier erfahren Sie mehr über Premium",
		wrong_pwd_email:"Falsche E-Mail oder falsches Passwort",
		error_occured_check_connection:"Es ist ein Fehler aufgetreten. Sind Sie mit dem Internet verbunden?",
		already_paid:"Ich habe bereits ein aktives Abonnement",
		enter_description:"Hier Beschreibung eingeben",
		invalid_email:"Ungültige E-Mail",
		email_already_registered:"Email schon registriert",
		username_already_registered:"Benutzername existiert bereits",
		free_week_trial:"7 Tage lang kostenlos testen!",
		learn_about:"Entdecken Sie",


		ios_iap_info:"Dies ist ein automatisch verlängerbares Abonnement. Nach Bestätigung wird Ihr iTunes-Konto mit der Zahlung belastet. Das Abonnement wird erneuert, es sei denn, es wird mindestens 24 Stunden vor Ablauf des Zeitraums beendet. Ihr Konto wird auch für die Verlängerung belastet. Sie können es in Ihren Kontoeinstellungen deaktivieren. Jeder nicht genutzte Teil eines kostenlosen Testzeitraums, falls angeboten, verfällt, wenn der Nutzer ein Abonnement für diese Publikation kauft, sofern zutreffend.\n\nAbonnement kündigen\n\nSie können die automatische Verlängerung für das Abonnement jederzeit über iTunes deaktivieren https://support.apple.com/HT202039. Wenn Ihre aktuelle Abonnementsperiode abläuft, werden Sie abgemeldet. Die aktuelle aktive Abonnementsperiode kann nicht storniert werden.",
        privacy_policy:"Datenschutz-Bestimmungen",
        tos:"Nutzungsbedingungen",


		explain_why_premium_title:"Wieso Premium?",
		explain_why_premium_1:"Wir haben mit der Arbeit an der App im Januar 2018 begonnen, um unsere erste Version 3 Monate später zu veröffentlichen. Seitdem arbeiten wir jeden Tag daran, sie zu verbessern. Sie ist seit fast einem Jahr kostenlos und werbefrei, und das, obwohl die App folgendes gefordert hat:",
		explain_why_premium_2:"3500 Arbeitsstunden",
		explain_why_premium_3:"in unserer Freizeit engagiert.",
		explain_why_premium_4:"Hosting- und Übersetzungskosten,",
		explain_why_premium_5:"finanziert durch unsere Ersparnisse",
		explain_why_premium_6:"An Wochentagen und Wochenenden verfügbar sein,",
		explain_why_premium_7:"um Ihre E-Mails und Kommentare zu beantworten.",
		explain_why_premium_8:"Wir lieben dieses Projekt und wollen die App ständig verbessern, damit sie Ihren Bedürfnissen am besten gerecht wird, aber wir können nicht weiter Geld dafür aufwenden. Das Premium-Abonnement ist für Sie eine Möglichkeit, uns zu unterstützen und uns zu ermöglichen, weiter an diesem großartigen Projekt zu arbeiten! ",


		see_premium:"Premium Angebot sehen >",
		why_not_free:"Warum ist diese App nicht völlig kostenlos?",


		ad_warning:"Hier werden möglicherweise bald Anzeigen geschaltet. Mehr Informationen.",

		buy:"Kaufen",
		preview:"Vorschau",

		remove_ads:"Werbung entfernen",

		continue:"forsetzen",


		buy_pattern_desc:"Sie können diese Anleitung kaufen, Werbungen werden weiterhin in der App angezeigt",
		buy_all_pattern_desc:"Kaufen Sie alle Anleitungen auf einmal. Genießen Sie die App ohne Werbung und sparen Sie bis zu 80% der gesamten Anleitungkosten.",
		buy_premium:"Oder probieren Sie Strick Mich Premium und genießen Sie alle erstklassigen Anleitungen und Funktionen ohne Werbung. Ihre Projekte werden zwischen all Ihren Geräten synchronisiert.",
		buy_pattern_price:"DIESE ANLEITUNG KAUFEN: ",
		buy_all_patterns_price:"ALLE ANLEITUNGEN KAUFEN:  ",

		buy_all_pattern_no_ads:"Kaufen Sie alle Anleitungen. Werbung entfernen.",

		password_forgotten:"Passwort vergessen?",

		projects_synchro:"Auf dem Server gespeicherte Projekte",

		last_update:"letztes Update",
		show_reset: "Anzahl der Resets anzeigen",

		annotations_hint:"Sie können lange auf das Dokument klicken, um Anmerkungen hinzuzufügen.",
		annotations_hint_premium:"Sie können lange auf das Dokument klicken, um Anmerkungen hinzuzufügen.",
		add_annotation_text:"Fügen Sie hier Ihre Anmerkung hinzu...",

		select_mode:"Wählen Sie einen Modus",
		knitting_mode: "Stricken",
		crochet_mode: "Häkel",

		badge1_crochet: "Stuffe 1",
		badge2_crochet: "Stuffe 2",
		badge3_crochet: "Stuffe 3",
		badge4_crochet: "Stuffe 4",
		badge5_crochet: "Stuffe 5",
		badge6_crochet: "Stuffe 6",

		type:'Art',

		discard_changes:'Änderungen verwerfen?',

		agree:'Zustimmen',
		agree_email:'Ich bin damit einverstanden, E-Mails von der App zu erhalten',
		see_terms: 'Siehe Nutzungsbedingungen und Datenschutzbestimmungen',
		must_agree_pp: 'Sie müssen den Nutzungsbedingungen und den Datenschutzbestimmungen zustimmen, um fortzufahren',
		duplicate: 'duplizieren',

		no_ravelry_project:'Sie haben kein Projekt auf Ravelry.',
		already_imported:'Sie haben dieses Projekt bereits importiert',
		import_ravelry:"oder aus Ravelry importieren",
		import:'Importieren',
		small:'klein',
		normal:'normal',
		big:'groß',
		options:'Einstellungen',
		show:'Sichtbar machen',
		hide:'Unsichtbar machen',
		long_press_position:'Lange auf einen Zähler drücken, um seine Position zu ändern',
		enable_expert_options:'Sie können den Expertenmodus in den Einstellungen aktivieren',
		enable_expert:"Expertenmodus aktivieren",
		counter_size:"Größe der Zähler",
		highligther_size:'Textmarker Größe',
		starts_at:'startet um',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'dann',
		minutes:'Minuten',
		seconds:'Sekunden',
		configure:'konfigurieren',
		add_to_timer: 'und füge den Timer automatisch hinzu, ohne mich zu fragen, wenn der Bildschirm weniger als x Minuten ausgeschaltet war:',
		keep_timer_off:'Der Timer bleibt eingeschaltet, wenn der Bildschirm ausgeschaltet ist',
		timer_ask:"{0} sind vergangen, seit Sie die App verlassen haben. Möchten Sie sie dem Timer hinzufügen??",
		add_time_timer:"Zeit zum Timer hinzufügen?",
		select_camera: "Foto aufnehmen",
    	select_gallery: "Filmrolle",
    	photo:'Foto',
    	new_start_zero:'Neue Zähler beginnen standardmäßig bei 0',

    	syncing:'Synchronisierung',
		sync_error:'Synchronisierungsfehler',


		connect_watch:'Apple Watch verbinden (Premium)',
		watch_not_connected:'Apple Watch nicht verbunden (oder bildschirm aus)',
		watch_connected:'Apple Watch verbunden',





		troubleshoot_watch:"Hilfe",
reset_all_timers:"Timer zurücksetzen? ALLE TIMER für Projekte wird auf 0 zurückgesetzt.",
premium_file_sharing:"Nur Premium-Benutzer können Dateifreigabe verwenden.",
shared_with_me:"Mit mir geteilt",
no_shared:"Bisher hat noch niemand ein Projekt mit Ihnen geteilt.",
accept:"Akzeptieren",
from:"von",
share_project:"Projekt teilen",
share_copyright_check:"Ich werde nicht ein urheberrechtlich geschütztes Dokument teilen",
share_copyright:"Bitte beachten Sie die Autoren und nur austauschen Sie nur Dokumenten für die Sie eine Berechtigung haben. urheberrechtlich geschütztes Material zu teilen kann zur Kündigung Ihres Kontos führen.",
share_project_with:"Ich möchte dieses Projekt teilen mit",
disable_social:"Soziale Funktionen deaktivieren ",
friends:"Freund (en)",
shared:"Geteilt",
chats:"Nachrichten",
chat:"Nachricht",
join_community:"Trete der Community bei!",
social_desc:"Finde Freunde und teilen die Freude. Freunde können Ihren Fortschritt, Ihre Bilder sehen, und Sie können mit ihnen Ihre Projekte teilen.",
projects:"Projekte",
unfriend:"Entfernen Sie diesen Freund",
congratulate:"Gratulieren",
wait_confirm:"Auf Bestätigung warten",
not_found:"Nicht gefunden",
invite_friend:"Freund einladen",
invite_ext:"Hallo Ich möchte Sie einladen, den Zähl Mich App zu versuchen. Installieren Sie es und fügen Sie mich als Freund. My username is {0}",
add_friend_desc:"Fügen Sie Ihre Freunde auf der App, um mit ihnen zu kommunizieren und Ihre Fortschritte zu teilen. Freunde können Ihre Bilder sehen.",
send_invitation:"Einladung senden",
add_friend:"Einen Freund hinzufügen",
no_friend_yet:"Noch kein Freund",
start_chatting:"Klicken Sie, um zu chatten",
you_finished_project:"Sie haben ein Projekt beendet ",
you_started_project:"Sie haben ein neues Projekt angefangen",
finished_project:"beendet ein Projekt",
started_project:"hat ein neues Projekt angefangen",
reached_level:"John ist nun Stufe",
you_reached_level:"Sie sind jetzt Stufe",
sent_message:"hat eine Nachricht geschickt",
congratulated_you:"hat Sie gratuliert👏",
shared_project_with_you:"hat sich ein Projekt mit Ihnen geteilt",
wants_to_be_friend:"will dein Freund sein!",
became_your_friend:"wurde dein Freund!",
type_your_message:"Schreiben Sie Ihre Nachricht",


login_social:"Melden Sie sich an, um der Community beizutreten",


faq_translated:"Diese Webseite ist nur in Englisch, möchten Sie eine automatische Übersetzung sehen?",



		watch_help_1:"Starten Sie auf Ihrer Apple Watch die App. Wählen Sie auf diesem Telefon ein Projekt aus. Wenn dies nicht funktioniert, lesen Sie die folgenden Anweisungen",
		watch_help_2:"Öffnen Sie die App «Watch» auf Ihrem iPhone.",
		watch_help_3:"Scrollen Sie nach unten, um diese App zu finden, und klicken Sie darauf",
		watch_help_4:"Schalten Sie die Schaltfläche zum Deaktivieren um und aktivieren Sie App on Watch erneut.",
		watch_help_5:"Starten Sie auf Ihrer Apple Watch die App. Wählen Sie auf dem iPhone ein Projekt aus.",


		select:'Wählen',
		select_part:'Wählen Sie Part',
		next_part:"Nächster",
		part_finished:"Teil abgeschlossen",
		part_finished_desc:"Gratulation, Sie haben diesen Teil fertig!",
		part:"Teil",
		delete_part:"Teil löschen? Alle Zähler in diesem Teil werden gelöscht.",

		highlight_vertical:"Textmarker vertikal anzeigen",


		back:"Zurück",
		configure_ads:"Werbungen Einstellungen",
		partner_click:"Klicken Sie auf einen unserer Partner zu erfahren, wie er die Daten verwendet.",
		how_use_data:"Lernen Sie wie wir Ihre Daten verwenden",
		tailor_ads:"Können wir Ihre Daten verwenden, um personalisierte Werbung anzuzeigen?",
		consent_desc:"Ihre Datensicherheit ist uns wichtig. Wir zeigen Werbung, um eine kostenlose Version der App anzubieten.",
		consent_desc2:"Sie können Ihre Wahl jederzeit in den App-Einstellungen ändern",
		how_collect_data:"Wie sammeln wir und unsere Partner Daten?",
		relevant_ads:"Ja, nach meinem Geschmack anpassen",
		ad_free:"Kaufen Sie die werbefreie Version",
		not_relevant_ads:"Nein, Werbungen sehen, die weniger relevant sind",


		enable_tracking: "GDPR",
        enable_tracking_desc: "Ihre Privatsphäre und Ihre Datensicherheit sind uns wichtig. Das Zulassen von Cookies wird Ihre Erfahrung mit dieser Anwendung verbessern, uns im Falle eines Fehlers mehr Informationen zur Verfügung stellen, damit wir ihn beheben können, Funktionen auf Ihre Bedürfnisse zuschneiden und die Verwendung der Werbe-ID wird es ermöglichen, weniger Werbung anzuzeigen, die für Sie irrelevant wäre. ",
        allow_cookies: "Zulassen",
        disallow_cookies: "Abweisen. Ich verstehe, dass meine Erfahrungen mit dieser App weniger auf meine Bedürfnisse zugeschnitten sind und meine Probleme nicht gemeldet werden.",


        convert_what:"Was wollen Sie konvertieren?",
        to:"zu",


		activate:"Aktivieren",


		yarn_yards	:"Yards",
yarn_meters	:"Meter",
ounces	:"Unzen",
add	:"hinzufügen",
select_this_yarn	:"Dieses Garn auswählen",
select	:"Wählen Sie",
stash	:"Garnvorrat",
stash_ravelry_beta	:"Diese Funktion befindet sich in der Beta-Phase, die Nutzung erfolgt auf eigenes Risiko und Sie können Probleme (Fehler, Übersetzungsfehler) an tricotonapp@gmail.com melden.",
adjust_no_sync	:"Anpassungen können nicht mit Raverly synchronisiert werden",
import	:"importieren",
select_needle	:"Auswahl der Nadeln",
remove	:"entfernen",
nothing_here	:"Hier ist noch nichts.",
see_needles	:"Nadeln sehen",
see_stash	:"Garnvorrat sehen",
needles	:"Nadeln",
needle	:"Nadel",
link_ravelry_proj	:"Möchten Sie dieses Projekt mit einem bestehenden Projekt über Ravelry verbinden?",
link_ravelry_proj_hint	:"Wählen Sie 'nein', wenn Sie Raverly nicht verwenden oder wenn Sie dieses Projekt nicht auf Ravelry erstellt haben.",
must_be_img_pdf	:"Datei kann nicht heruntergeladen werden. Muss Bild oder PDF sein.",
cannot_be_zero	:"Kann nicht 0 sein",
color	:"Farbe",
add_from_stash	:"Aus Garnvorrat hinzufügen",
link_needles	:"Nadeln an dieses Projekt anschließen",
import_ravelry2	:"Import von Ravelry",
create	:"Erstellen",
starting_quantity	:"Ausgangsmenge:",
use	:"Verwenden",
adjust	:"anpassen",
edit_yarn	:"Garn bearbeiten",
yarn_stash	:"Garnvorrat (Beta)",
needle_stash	:"Nadelversteck (Beta)",
sync_ravelry	:"Sync mit Ravelry",
enter_weight	:"Gewicht eingeben",
enter_length	:"Länge eingeben",
totals	:"Gesamtzahlen:",
nb_skeins	:"Anzahl der Stränge",
name	:"Name",
company_name	:"Name des Unternehmens",
yarn_weight	:"Garngewicht",
unlink_ravelry	:"Verbindung zu Ravelry lösen",
colorname	:"Name der Farbe",
select_weight	:"Gewicht auswählen",
use_nb_skeins	:"Anzahl der Stränge",
use_totals	:"Summen verwenden",
each_skein	:"Jedes Strang ist:",
notes	:"Anmerkungen",
edit	:"bearbeiten",
disconnect_ravelry	:"Abmelden von Ravelry",
type	:"Typ auswählen",
length	:"Länge",
size	:"Größe",
notes	:"Anmerkungen",
select_all	:"Alle auswählen",
select_corresponding_project	:"Entsprechendes Projekt auf Ravelry auswählen",
used_by:"Verwendet für",
		left_stash:"noch im Garnvorrat",


		other_sources:"Mehr",

		create_custom_proj:"Neues Projekt erstellen",
		create_custom_proj_desc:"Konfigurieren Sie Ihre Zähler und importieren Sie Ihre Anleitung aus einer beliebigen Quelle",
		import_rc_pattern:"Zähl-Mich Anleitung importieren",
		import_rc_pattern_desc:"Für die App optimisierte Anleitung importieren",
		import_project_ravelry:"Raverly projekt importieren",
		import_project_ravelry_desc:"Ein Projekt importieren, das auf Ravelry erstellt wurde",
		ask_resize_counters:"Möchten Sie die Counter kleiner erscheinen lassen? Sie können die Größe der Zähler jederzeit in den Einstellungen ändern.",
		start:"Start",
		personal_source:" ",
		external_source:" ",

		difficulty: "Schwierigkeitsgrad",
		category: "Kategorie",
		chart_settings: "Diagrammeinstellungen",
		rs: "Vorderseite",
		ws: "Rückseite",
		rs_short: "Vorderseite",
		ws_short: "Rückseite",
		appears_rs: "auf der rechten Seite",
		appears_ls: "auf der linken Seite",
		size_in_pdf: "Größe in PDF",


		vertical: "Vertikal",
		horizontal: "Horizontal",
		all_patterns: "Alle Anleitugen",

		appear_row:" ",
		single_row: "auf 1 Reihe",
		range_rows: "auf mehreren Reihen",

		columns:"Spalten",
		lines:"Lignes",


		title:"Titel",
		icon:"Symbol",
		document_locked:"Kann keine Anmerkungen hinzufügen, PDF ist vom Autor geschützt",
		highlight_annotation_more:"Textmarker, Anmerkungen auf dem PDF, und mehr!",
		cancel_anytime:"Sie können jederzeit kündigen",
	},
	ru: {
distribstitches: {
		            tooltitle: 'Распределить Увеличение & Уменьшение',
					increases: 'увеличивается',
					increase: 'увеличение',
					decreases: 'уменьшается',
					decrease: 'снижение',
					stitches: 'швы',
					stitch: 'стежок',
					pleaseenter: 'Пожалуйста, введите 2 значения',
					willstart: 'Она начнется, как это:',
					desirednb: 'Желаемое количество стежков',
					currentnb: 'Текущее количество стежков',
					weadvise: 'Мы рекомендуем делать',
					every: 'каждый',
					thefirst: 'Запуск первого',
					after: 'после',
					then: 'тогда',
					endwith: 'а затем заканчиваются',
		        },
		infoImportPattern: "Click here to import your pattern from any source",
		lang: "ru",
		tts_lang: "ru-RU",
		ok: "ok",
		cancel: "Отмена",
		start_new_project: "Новое Изделие",
		new_project: "Новое Изделие",
		project_name: "Название Изделия",
		enter_name: "Введите название",
		enter_project_name: "Пожалуйста, введите название изделия",
		project_type: "Выберите Технику Вязания",
		next: "ДАЛЕЕ",
		motif: "Выберите петли",
		material: "ВАМ НЕОБХОДИМО",
		description: "ОПИСАНИЕ",
		begin: "НАЧАЛО",
		finish: "КОНЕЦ",
		current_rang: "Текущий ряд: ",
		repeat: "Повторите: ",
		stitches: "разы",
		rangs: "Петли ",
		times: " Ряды ",
		projs_completed: "Завершено",
		tutorials: "Инструкции ",
		select_size: "Выберите Размер",
		select_woolsize: "Выберите Размер Пряжи",
		linked_tutos: "Соответствующие пособия",
		quantity: "Количество ",
		size: "размер",
		loading: "Загрузка …",
		badge1: "Уровень 1",
		badge2: "Уровень 2",
		badge3: "Уровень 3",
		badge4: "Уровень 4",
		badge5: "Уровень 5",
		badge6: "Уровень 6",
		badge7: "Уровень 7",
		badge8: "Уровень 8",
		badge9: "Уровень 9",
		badge10: "Уровень 10",
		part: "ЧАСТЬ ",
		previous_seq: "Предыдущий шаг",
		finished: "Вы закончили свое изделие!",
		congratz: "ПОЗДРАВЛЯЕМ!",
		row_counters: "Счетчик Рядов",
		global_counter: "Общий Счетчик",
		secondary_counter: "Второстепенный Счетчик",
		nb_row: "Количество рядов",
		name_optional: "Название (возможный вариант)",
		save: "СОХРАНИТЬ",
		auto_inc: "Ссылка на Общий Счетчик",
		set_up_main_counter:
			"Кликните на главный счетчик, чтобы его установить",
		counter_project: "Используйте Счетчик Рядов",
		edit_counters: "Отладить Счетчики",
		enter_comment_opt: "Оставьте замечание здесь, если хотите",
		show_com_at_row: "Покажите замечание в этом ряду #",
		add_counter_com: "Добавьте замечание",
		comments: "Замечания ",
		reinit_at: "Начать снова после того, как этот ряд будет закончен",
		counters: "СЧЕТЧИКИ",
		counters_desc:
			"Следите за тем, сколько вы связали с помощью нашего счетчика рядов! \n\n Вы можете задать: \n- 1 Общий счетчик, чтобы рассчитать количество рядов на все свое изделие, \n- Несколько Второстепенных счетчиков, чтобы увеличивать, уменьшать количество петель или при вывязывании узора. \n\n Нужно Напомнить для определенного ряда? Добавьте эту функцию в соответствующий счетчик, и он будет показан в нужный момент!",
		set_average_stitches:
			"Пожалуйста, введите среднее количество петель на один ряд (приблизительно)",
		average_stitches: "Среднее количество петель по рядам",
		delete: "Удалить ",
		enter_number: "пожалуйста, введите число",
		meters: "М",
		centimeters: "СМ",
		inches: "Дюймы ",
		yards: "Ярды ",
		convert_units: "Единице пересчета",
		fill_all_fields: "Пожалуйста, заполните все поля",
		your_project: "ВАШЕ ИЗДЕЛИЕ",
		compute_swatch: "Подгонка образца",
		pattern: "УЗОР",
		your_swatch: "ВАШ ОБРАЗЕЦ",
		swatch: "образец",
		must_monter: "вам нужно набрать:",
		must_knit: "вам нужно связать:",
		grams: "граммы",
		tools: "Инструменты ",
		the_project: "изделие",
		tencm: "10 см",
		your_wool: "Ваша шерсть",
		woolball_size: "Каждый клубок содержит",
		wool_nb_required: "Количество клубков, которое необходимо",
		woolballs: "Клубки шерсти",
		number_required: "пожалуйста, введите количество",
		woolball_convertor: "Расчет клубков шерсти",
		activ_sound: "Активируйте звуковое приложение, чтобы его использовать",
		say_next: "Скажите «NEXT STEP» чтобы перейти к следующему ряду",
		say_next_counter: "Скажите «NEXT STEP» чтобы перейти к следующему ряду",
		say_next_ios: "Скажите «NEXT» чтобы перейти к следующему ряду",
		say_next_counter_ios: "Скажите «NEXT» чтобы перейти к следующему ряду",
		search: "поиск",
		retry: "повторить попытку",
		help_1: "нажмите на значок, чтобы показать дополнительную информацию",
		authorize: "позволять",
		you_knitted:"vy vyazali :",
		youre_now: "Ты сейчас",
		patterns_easy: "начинающий",
		patterns_medium: "легко",
		patterns_hard: "средний",
or:"или",
ravelry_library: "Ravelry Library",
		ravelry_search:"поиск Ravelry",
		import_pattern: "Импорт УЗОР",
		import_to_counter: "Импорт УЗОР",
		downloading:"скачивание...",
		select_source:"Выберите источник:",
		confirm_import_webpage: "Эта страница будет перенесена в том виде, как вы ее видите. Продолжить ?",
		confirm_delete_imported_pattern:"Удалить образец?",
		ask_img_upload: "Можно поделиться вашим образцом с другими пользователями, продолжить?",
		add_picture: "Добавить картинку",
		send_feedback:"Послать отзыв",
		important_feedback:"Ваш отзыв очень важен",
		are_you_satisfied: "Вы довольны своей работой? Как вам понравился образец?\n\n Вы можете поделитесь с нами вашими впечатлениями здесь, чтобы помочь нам усовершенствовать приложение!",
		send:"Отправить",
		thanks:"Спасибо",
		time_elapsed: "Время",
		long_press: "Долго нажимайте на проект,\nчтобы получить еще больше опций",
		take_pic: "Сфотографировать",
  		gallery_pic: "Выбрать из библиотект",
		reset_timer:"таймер сброса",
		see_picture:"Посмотреть картинку",
		change_picture:"Поменять картинку",
		stitches_on_row:"Общее количество петель:",
		storage_full: "Память заполнена. Пожалуйста, удалите каки-либо файлы или приложения и попробуйте еще раз",
		phone: "Ваше устройство",
		reset_counter:"сброс",
		select_language:"Выбрать язык",
		download: "скачать",
		started_on:'началось',
		time_elapsed2:'Время',
		need_help_contact:"вам нужна помощь? -> tricotonapp@gmail.com",
		auto_translate:"автоматический перевод",
		raverly_suggestions:'Ravelry',
		wool_size:"Размер пряжи  :",
		needle_size:"Размер иглы :",
		yardage:"площадь или объем :",
		in_your_language:"Доступно на вашем языке :",

		yes:"да",
		no:"нет",

		you_no_account:"У вас нет аккаунта?",
login: "войти",
already_have_account:"У меня уже есть аккаунт",
register:"зарегистрировать",
username:"имя пользователя",
your_email:"ваша электронная почта",
your_password:"ваш пароль",
password_not_equal:"Пароль не совпадает",
repeat_password:"Повторите пароль",
enter_username: "Введите имя пользователя",

login_desc:"Войдите в систему, чтобы использовать приложение на нескольких устройствах",
try_premium:"Попробуйте премиум-версию приложения!",
help_us:"Чтобы позволить нам продолжить работу над этим приложением и пользоваться всеми функциями, мы просим вас внести свой вклад.",

premium_abo: "Премиум-подписка",
no_ads:"Без рекламы",
all_patterns_unlocked:"Все шаблоны разблокированы",
access_every_device:"Синхронизация в облаке: Доступ к вашим проектам со всех ваших устройств. Никогда не теряйте свои данные!",
unlimited_counters:"Столько счетчиков, сколько вы хотите",
unlimited_pictures:"Добавить фотографии!",
try_premium_btn:"Попробуйте премиум-версию",
continue_free:"Продолжить с бесплатной версией",
login_required:"Пожалуйста, войдите, чтобы иметь возможность резервного копирования ваших проектов.",
buy_required:"Синхронизация отключена. Узнайте больше обо всех функциях Премиум здесь.",
log_out:"Выйти",
year:"год",
		month: "Месяц",
		monthly_sub: "Месячная подписка",
		annual_sub: "Годовая подписка",

premium_feature:"Это премиум функция",
premium_pattern:"Это премиум шаблон",
try_free:"Попробуйте Премиум бесплатно в течение одной недели!",
learn_more_premium:"Узнайте больше о премиум",
wrong_pwd_email:"Неправильный адрес электронной почты или пароль",
error_occured_check_connection:"Произошла ошибка. Вы подключены к Интернету?",
already_paid:"У меня уже есть активная подписка",
enter_description:"Введите описание здесь",
invalid_email:"Недействительный адрес электронной почты",
email_already_registered:"Электронная почта уже зарегистрирована",
username_already_registered:"Имя пользователя уже существует",

		free_week_trial:"Попробуйте бесплатно в течение 7 дней!",
		learn_about:"узнать больше о",

		explain_why_premium_title:"Чтобы позволить нам продолжить работу над этим приложением и пользоваться всеми функциями, мы просим вас внести свой вклад.",
		explain_why_premium_1:"Мы начали работать над приложением в январе 2018 года, чтобы через 3 месяца выпустить нашу первую версию. С тех пор мы ежедневно работаем над его улучшением. Приложение оставалось бесплатным и без рекламы почти год, и на это приложение потребовалось:",
		explain_why_premium_2:"3500 часов работы,",
		explain_why_premium_3:"потраченных из нашего свободного времени",
		explain_why_premium_4:"Расходы на хостинг и переводы,",
		explain_why_premium_5:"оплаченные за счет наших сбережений",
		explain_why_premium_6:"Быть доступными в будние дни и выходные,",
		explain_why_premium_7:"чтобы отвечать на ваши письма и комментарии",
		explain_why_premium_8:"Мы любим этот проект и хотим продолжать работу над приложением, чтобы оно как можно больше соответствовало вашим потребностям, но мы не можем продолжать тратить на него деньги. Премиум Подписка - для вас это способ поддержать нас и позволить нам продолжать работу над этим замечательным проектом!",


		see_premium:" премиум-версию >",
		why_not_free:"почему приложение не полностью бесплатное?",



		ad_warning:"В ближайшее время вы можете увидеть рекламу. Дополнительная информация.",

		buy:"купить",

		remove_ads:"удалить рекламу",

		continue:"Продолжать",


		buy_pattern_desc:"Вы можете приобрести эту модель только, вы все еще есть объявления на приложение.",
		buy_all_pattern_desc:"Покупка всех моделей сразу. Наслаждайтесь приложение без рекламы и сэкономить до 80% от цен на модели.",
		buy_premium:"Или попробуйте Премиум и пользоваться всеми моделей премиум-класса и функции, объявления бесплатно. Ваши проекты будут синхронизированы между всеми вашими устройствами.",
		buy_pattern_price:"КУПИТЬ этот шаблон для : ",
		buy_all_patterns_price:"КУПИТЬ ВСЕ ДЛЯ МОДЕЛЕЙ : ",

		buy_all_pattern_no_ads:"Покупка всех моделей сразу. Наслаждайтесь приложение без рекламы.",

		password_forgotten:"Забыли пароль?",


		projects_synchro:"Проекты сохранены на сервере",

		last_update:"последнее обновление",
		show_reset: "Показать количество сбросов",

		annotations_hint:"Вы можете долго нажимать на документ, чтобы добавить аннотации.",

		add_annotation_text:"Добавьте свой текст здесь...",


		select_mode:"выберите режим",
		knitting_mode: "",
		crochet_mode: "",

		badge1_crochet: "Уровень 1",
		badge2_crochet: "Уровень 2",
		badge3_crochet: "Уровень 3",
		badge4_crochet: "Уровень 4",
		badge5_crochet: "Уровень 5",
		badge6_crochet: "Уровень 6",

		type:'тип',

		discard_changes:'Отменить изменения ?',

		agree:'я согласен',
		agree_email:'Я согласен получать письма от приложения',
		see_terms: 'Ознакомьтесь с условиями и политикой конфиденциальности',
		must_agree_pp: 'Вы должны согласиться с условиями и политикой конфиденциальности, чтобы продолжить',
		duplicate: 'дублированный',

		no_ravelry_project:'У вас нет проектов на Ravelry.',
		already_imported:'Вы уже импортировали этот проект',
		import_ravelry:"или импортировать из Ravelry",
		import:'импорт',
		small:'маленький',
		normal:'обычный',
		big:'большой',
		options:'настройки',
		show:'шоу',
		hide:'скрыть',
		long_press_position:'долгое нажатие на элемент, чтобы изменить его положение',
		enable_expert_options:'Вы можете включить экспертный режим в настройках',
		enable_expert:"Включить экспертный режим",
		counter_size:"счетчиков размер",
		highligther_size:'размер маркера',
		starts_at:'начинается в',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'тогда',
		minutes:'минуты',
		seconds:'секунды',
		configure:'настроить',
		add_to_timer: "и добавить к таймеру автоматически, не спрашивая меня, когда экран был выключен менее чем за 'X' минуты:",
		keep_timer_off:'Таймер остается включенным, когда экран выключен',
		timer_ask:"{0} прошло с тех пор, как вы покинули приложение, хотите ли вы добавить их в таймер?",
		add_time_timer:"Добавить время к таймеру?",

		select_camera: "Сделайте снимок",
    	select_gallery: "галерея",
    	photo:'Фото',


    	syncing:'синхронизации',
		sync_error:'ошибка синхронизации',


		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',




		troubleshoot_watch:"Помогите",
reset_all_timers:"Сброс таймера? Все время, затраченное на проекты, будут сброшены в 0.",
premium_file_sharing:"Только пользователи Премиум могут использовать общий доступ к файлам.",
shared_with_me:"Поделился со мной",
no_shared:"Никто не поделился проект с вами еще.",
accept:"принимать",
from:"из",
share_project:"Доля проекта",
share_copyright_check:"Я не буду делить, защищенный авторским правом документ",
share_copyright:"Пожалуйста, уважайте автор и только совместно использовать документы, для которых у вас есть разрешение. Совместное использование защищенного авторским правом материала может привести к прекращению действия вашего аккаунта.",
share_project_with:"Я хочу поделиться этим проектом с",
disable_social:"Отключить социальные функции",
friends:"Друг (ы)",
shared:"Общий",
chats:"Сообщения",
chat:"Сообщение",
join_community:"Присоединяйтесь к сообществу!",
social_desc:"Найти друзей и разделить радость. Друзья могут видеть ваше продвижение, ваши фотографии, и вы можете поделиться своими проектами с ними.",
projects:"проектов",
unfriend:"Удалить этот друг",
congratulate:"Поздравлять",
wait_confirm:"Ожидание подтверждения",
not_found:"Не найден",
invite_friend:"Пригласить друга",
invite_ext:"Привет Я хотел бы пригласить вас попробовать приложение My Row счетчик. Установите его и добавить меня в качестве друга мое имя пользователя {0}",
add_friend_desc:"Добавьте друзей в приложение, чтобы общаться с ними и поделиться своим прогрессом. Друзья смогут увидеть ваши фотографии.",
send_invitation:"Выслать пригласительное",
add_friend:"Добавить в друзья",
no_friend_yet:"Нет друзей",
start_chatting:"Нажмите, чтобы начать чат",
you_finished_project:"Вы закончили проект",
you_started_project:"Вы начали новый проект",
finished_project:"закончил проект",
started_project:"начал новый проект",
reached_level:"теперь уровень",
you_reached_level:"Вы сейчас уровень",
sent_message:"послал вам сообщение",
congratulated_you:"поздравил вас 👏",
shared_project_with_you:"поделился с вами проект",
wants_to_be_friend:"хочет быть вашим другом!",
became_your_friend:"стал вашим другом!",
type_your_message:"Введите Ваше сообщение",



login_social:"Войдите, чтобы присоединиться к сообществу",


faq_translated:"Эта веб-страница только на английском языке, вы хотите увидеть автоматический перевод?",



		watch_help_1:"Запустите приложение на Apple Watch. На этом телефоне выберите проект. Если это не работает, прочитайте инструкции ниже",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",


		select:'Выбрать',
		select_part:'Выберите часть',
		next_part:"следующий",
		part_finished:"Часть закончена",
		part_finished_desc:"Поздравляю, вы закончили эту часть!",
		part:"часть",
		delete_part:"Удалить эту часть? Все счетчики в этой части будут удалены.",

		highlight_vertical:"Показать подсветку по вертикали",


		back:"обратно",
		configure_ads:"Настроить рекламу",
		partner_click:"Нажмите на одного из наших партнеров, чтобы узнать, как он собирает и использует данные.",
		how_use_data:"Как мы используем ваши данные?",
		tailor_ads:"Можем ли мы использовать ваши данные, чтобы показать вам персонализированную рекламу.",
		consent_desc:"",
		consent_desc2:"Вы можете изменить свой выбор в любое время в настройках приложения.",
		how_collect_data:"как мы собираем данные",
		relevant_ads:"Да",
		ad_free:"Приобрести версию без рекламы",
		not_relevant_ads:"Нет, см. объявления, которые менее актуальны",

		enable_tracking: "GDPR\nРазрешать cookie-файлы и использование вашего Рекламного Идентификатора в приложении?",
        enable_tracking_desc: "Мы заботимся о вашей конфиденциальности и безопасности данных. Разрешение cookie-файлов улучшит вашу работу с этим приложением, позволит нам получать больше информации в случае возникновения ошибки, чтобы мы могли исправить ее, настроить функции в соответствии с вашими потребностями, а использование рекламного идентификатора позволит отображать меньше рекламы, которая будет неактуальна для вас.  ",
        allow_cookies: "Разрешить.",
        disallow_cookies: "Запретить. Я понимаю, что мой опыт работы с этим приложением будет в меньшей степени соответствовать моим потребностям, и о моих проблемах не будет сообщено.",

		activate:"активировать",


		convert_what:"Что ты хочешь преобразовать?",
        to:"в",



        yarn_yards	:"ярдов",
yarn_meters	:"метров",
ounces	:"унции",
add	:"Добавить",
select_this_yarn	:"Выберите эту пряжу",
select	:"выбрать",
stash	:"тайник",
stash_ravelry_beta	:"Эта функция находится в бета-версии, используйте на свой страх и риск и сообщайте о проблемах (ошибка, ошибка перевода) по адресу tricotonapp@gmail.com.",
adjust_no_sync	:"Регулировка не может быть синхронизирована с Raverly.",
import	:"Импорт",
select_needle	:"Выбор иглы",
remove	:"Удалить",
nothing_here	:"Пока ничего.",
see_needles	:"Видишь иглы",
see_stash	:"См. тайник",
needles	:"Иглы",
needle	:"Игла",
link_ravelry_proj	:"Вы хотите связать этот проект с существующим проектом на Равелри?",
link_ravelry_proj_hint	:"Выберите \"нет\", если вы не используете \"Raverly\", или если вы не создали этот проект на Равелли",
must_be_img_pdf	:"файл не может быть загружен. Должно быть изображение или PDF",
cannot_be_zero	:"Не может быть 0",
color	:"Цвет",
add_from_stash	:"Добавить из тайника",
link_needles	:"подключать иглы к этому проекту",
import_ravelry2	:"Импорт из Равелри",
create	:"Создать",
starting_quantity	:"Начальное количество:",
use	:"Использовать",
adjust	:"Настройка",
edit_yarn	:"Редактировать Пряжа",
yarn_stash	:"Yarn Stash (Beta)",
needle_stash	:"Заначка для игл (Beta)",
sync_ravelry	:"Синхронизация с Равелри",
enter_weight	:"входной вес",
enter_length	:"длина ввода",
totals	:"Итого:",
nb_skeins	:"Количество мотков",
name	:"Имя",
company_name	:"Наименование организации",
yarn_weight	:"вес пряжи",
unlink_ravelry	:"Не подключайтесь к сети от Равелри",
colorname	:"Наименование цвета",
select_weight	:"выбранный вес",
use_nb_skeins	:"Количество мотков",
use_totals	:"Суммарные показатели использования",
each_skein	:"Каждый мотылёк:",
notes	:"Примечания",
edit	:"Редактирование",
disconnect_ravelry	:"Выход из Равелри",
type	:"выбранный тип",
length	:"Длина",
size	:"Размер",
notes	:"Примечания",
select_all	:"Выбрать все",
select_corresponding_project	:"Выберите соответствующий проект на Равелри",
used_by:"Используется для",
left_stash:"всё ещё в тайнике",

other_sources:"подробнее",


create_custom_proj:"Создать новый пользовательский проект",
		create_custom_proj_desc:"Настройте счетчики и импортируйте шаблоны из любого источника.",
		import_rc_pattern:"Импорт шаблона 'Счетчик Рядов'",
		import_rc_pattern_desc:"Импорт шаблона, созданного и оптимизированного специально для этого приложения",
		import_project_ravelry:"Импорт проекта Raverly",
		import_project_ravelry_desc:"Импорт проекта, созданного на Равелри, в приложение",
		ask_resize_counters:"Хотите, чтобы жетоны были меньше? Вы можете изменить размер счетчиков в любое время в настройках",
		start:"Start",
		personal_source:" ",
		external_source:" ",


		appear_row:" ",
		single_row:"на 1 ряд",
		range_rows:"на несколько рядов",

		title:"заглавие",
		icon:"икона",
		document_locked:"Cannot add annotations, PDF is protected by author",
	},





	hi: {

	lang: "hi",
tts_lang: "hi-IN",
ok: "ठीक",
cancel: "रद्द करें",
start_new_project: "नया कार्य",
new_project: "नया कार्य",
project_name: "कार्य का नाम",
enter_name: "नाम डालें",
enter_project_name: "कृपया कार्य का एक नाम दें",
project_type: "एक बुनाई का पैटर्न (डिजाइन) चुनें",
next: "अगला",
motif: "बुनाई का चयन करें",
material: "आपको इन चीजो की आवश्यकता है",
description: "विवरण",
begin: "प्रारंभ",
finish: "समापन",
current_rang: "वर्तमान कतार: ",
repeat: "दोहराएं: ",
times: "बार",
stitches: "बुनाई",
rangs: "कतार",
projs_completed: "संपूर्ण",
tutorials: "ट्यूटोरिअल्स (प्रशिक्षण)",
select_size: "आकार का चयन करें",
select_woolsize: "ऊन के आकार का चयन करें",
linked_tutos: "संबंधित ट्यूटोरिअल्स",
quantity: "मात्रा",
size: "आकार",
loading: "लोड हो रहा है...",
badge1: "बुनाई प्रारंभिक",
badge2: "नित्तेरिफिक",
badge3: "निटरूडाईट",
badge4: "निटहीरोइक",
badge5: "बुनाई की शहजादी",
badge6: "बुनाई की रानी",
part: "भाग ",
previous_seq: "पिछला कदम",
finished: "आपने अपना कार्य पूरा कर लिया है!",
congratz: "बधाई हो!",
row_counters: "रो काउंटर्स",
global_counter: "ग्लोबल काउंटर",
secondary_counter: "सेकेंडरी काउंटर",
nb_row: "कतारों की संख्या",
name: "नाम (वैकल्पिक)",
save: "सुरक्षित करें",
auto_inc: "ग्लोबल काउंटर से जोड़े",
set_up_main_counter: "इसे सेट करने के लिए मुख्य काउंटर पर क्लिक करें.",
counter_project: "रो काउंटर्स का उपयोग करें",
edit_counters: "काउंटर को संशोधित करें",
enter_comment_opt: "अगर आप चाहते हैं तो यहां एक टिप्पणी दें",
show_com_at_row: "इस कतार के लिए टिप्पणी दिखाएं!",
add_counter_com: "एक टिप्पणी जोड़ें",
comments: "टिप्पणियां",
reinit_at: "इस कदर तक पहुंचने के बाद इसे रीसेट करें",
counters: "काउंटर",
counters_desc:"हमारे नए रो काउंटर्स के साथ अपनी प्रगति पर नजर रखें.\n\n आप इसे व्यवस्थित कर सकते हैं:\n-1 ग्लोबल काउंटर, पूरे कार्य में कतारों की संख्या की गणना करने हेतु\n- अनेक सेकेंडरी काउंटर्स, यह आपको बढ़ाते समय, घटाव या बुनाई पैटर्न के संबंध में सहायता करेंगे.\n\n किसी विशेष कतार के लिए अनुस्मारक की आवश्यकता है? संबंधित काउंटर में इसे एक टिप्पणी के रूप में जोड़ें, यह सही समय पर दिखा दिया जाएगा!",
set_average_stitches:"कृपया प्रत्येक कतार में मौजूद फंदों की औसत संख्या डालें (लगभग)",
average_stitches: "प्रत्येक कतार में मौजूद फंदों की औसत संख्या",
delete: "हटायें",
number_required: "एक संख्या दर्ज करें",
meters: "मीटर",
centimeters: "से.मी",
inches: "इंच",
yards: "गज",
convert_units: "यूनिट कनवर्टर",
fill_all_fields: "कृपया सभी फ़ील्ड भरें",
your_project: "आपका प्रोजेक्ट",
compute_swatch: "स्विच एडाप्टर",
pattern: "प्रतिरूप",
your_swatch: "आपका स्वाच",
swatch: "स्वाच",
must_monter: "आपको फंदे करना चाहिए:",
must_knit: "आपको बुनना चाहिए:",
grams: "ग्राम",
tools: "उपकरण",
the_project: "परियोजना",
tencm: "10cm",
your_wool: "आपकी ऊन",
woolball_size: "प्रत्येक गेंद में शामिल हैं",
wool_nb_required: "अपेक्षित गेंदों की संख्या",
woolballs: "ऊन की गंदे",
number_required: "कृपया नंबर दर्ज करें",
woolball_convertor: "ऊन गेंद कनवर्टर",
pattern_translate: "निर्देश अनुवाद",
activ_sound: "अपनी आवाज के साथ ऐप का उपयोग करने  के लिए ध्वनि सक्रिय करें",
say_next:"अगली पंक्ति में जाने के लिए ‘NEXT STEP' कहें",
say_next_counter:"अगली पंक्ति में जाने के लिए ‘NEXT STEP' कहें",
say_next_ios:"अगली पंक्ति में जाने के लिए ‘NEXT' कहें",
say_next_counter_ios:"अगली पंक्ति में जाने के लिए ‘NEXT' कहें",
search: "खोज",
permission_not_granted: "अनुमति नहीं दी गई",
retry: "पुन: प्रयास",
authorize: "",
help_1: "अनुमति देते हैं",
you_knitted: "आपके पास बुनना है",
youre_now: "अब तुम हो",
patterns_easy: "आसान",
patterns_medium: "मध्यम",
patterns_hard: "अनुभव",
or:"या",
ravelry_library: "My Ravelry Library",
		ravelry_search:"Search Ravelry",
		import_pattern: "Import Pattern",
		import_to_counter: "Import to my row counter",
		downloading:"Downloading...",
select_source:"",
confirm_import_webpage: "Import Webpage ?",
add_picture: "Add Picture",
send_feedback:"Send Feedback",
important_feedback:"",
send:"OK",
		thanks:"Thank you!",



		buy_pattern_desc:"You can purchase this pattern alone. You will still see ads.",
		buy_all_pattern_desc:"Purchase all patterns at once, enjoy the app ads free and save up to 80%.",
		buy_premium:"Or try Knitting Genius Premium and enjoy all premium patterns and features, ads free. Your projects are synchronized among all your devices.",
		buy_pattern_price:"Buy pattern for ",
		buy_all_patterns_price:"Buy all patterns for ",


	},



pl: {
distribstitches: {
		    tooltitle: 'Zwiększanie i zmniejszanie rozprowadzać',
			increases: 'wzrosty',
			increase: 'zwiększać',
			decreases: 'zmniejsza',
			decrease: 'zmniejszać',
			stitches: 'szwy',
			stitch: 'szew',
			pleaseenter: 'Proszę wprowadzić wartości 2',
			willstart: 'Zostanie ona uruchomiona w ten sposób:',
			desirednb: 'Pożądany liczba ściegów',
			currentnb: 'Aktualna liczba ściegów',
			weadvise: 'Zalecamy, aby zrobić',
			every: 'każdy',
			thefirst: 'Zacznij pierwszy',
			after: 'po',
			then: 'następnie',
			endwith: 'i następnie kończyć',
		        },
	infoImportPattern: "Click here to import your pattern from any source",
	lang: "pl",
tts_lang: "pl-PL",
ok: "ok",
cancel: "Anuluj",
start_new_project: "Nowy projekt",
new_project: "Nowy projekt",
project_name: "Nazwa projektu",
enter_name: "Wpisz nazwę projektu",
enter_project_name: "Proszę wpisać nazwę projektu",
project_type: "Lub Wybierz Schemat Robótki",
next: "DALEJ",
motif: "Wybierz splot",
material: "BĘDZIESZ POTRZEBOWAŁ",
description: "OPIS",
begin: "ROZPOCZNIJ",
finish: "ZAKOŃCZ",
current_rang: "Bieżący rząd : ",
repeat: "Powtórz : ",
times: " razy",
stitches: "Oczka",
rangs: "Rzędy",
projs_completed: "Zakończone",
tutorials: "Instrukcje",
select_size: "Wybierz Rozmiar",
select_woolsize: "Wybierz Grubość Włóczki",
linked_tutos: "Powiązane instrukcje",
quantity: "Ilość",
size: "Rozmiar",
loading: "Ładuję…",
badge1: "Poziom 1",
badge2: "Poziom 2",
badge3: "Poziom 3",
badge4: "Poziom 4",
badge5: "Poziom 5",
badge6: "Poziom 6",
badge7: "Poziom 7",
		badge8: "Poziom 8",
		badge9: "Poziom 9",
		badge10: "Poziom 10",
part: "CZĘŚĆ ",
previous_seq: "Poprzedni krok",
finished: "Zakończyłeś projekt!",
congratz: "GRATULACJE!",
row_counters: "Liczniki rzędów",
global_counter: "Licznik globalny",
secondary_counter: "Licznik dodatkowy",
nb_row: "Liczba rzędów",
name: "Nazwa (opcjonalna)",
save: "ZAPISZ",
auto_inc: "Powiąż z licznikiem globalnym",
set_up_main_counter: "Kliknij na licznik główny aby wprowadzić ustawienia",
counter_project: "Użyj liczników rzędów",
edit_counters: "Edytuj liczniki",
enter_comment_opt: "Tu możesz wprowadzić komentarz",
show_com_at_row: "Pokaż komentarz przy tym rzędzie #",
add_counter_com: "Dodaj komentarz",
comments: "Komentarze",
reinit_at: "Resetuj po dojściu do tego rzędu",
counters: "LICZNIKI",
counters_desc:"	Śledź swój postęp przy użyciu liczników rzędów!\n\nMożesz skonfigurować:\n- 1 Licznik globalny w celu śledzenia rzędów w całym projekcie\n- Kilka liczników dodatkowych, które pomogą Ci śledzić dodawanie i odejmowanie oczek i schematy\n\nPotrzebujesz przypomnienia przy konretnym rzędzie ? Dodaj je jako komentarz przy odpowiednim liczniku, a zostanie pokazane w odpowiednim momencie!",
set_average_stitches:"	Wpisz średnią ilość oczek na rząd (około)",
average_stitches: "Średnia ilość oczek na rząd",
delete: "Usuń",
enter_number: "Wpisz liczbę",
meters: "m",
centimeters: "cm",
inches: "\"",
yards: "yd",
convert_units: "Konwerter jednostek",
fill_all_fields: "Wypełnij wszystkie pola",
your_project: "TWÓJ PROJEKT",
compute_swatch: "Adapter próbek",
pattern: "SCHEMAT",
your_swatch: "TWOJA PRÓBKA",
swatch: "próbka",
must_monter: "musisz nabrać oczek:",
must_knit: "musisz przerobić:",
grams: "gram",
tools: "Narzędzia",
the_project: "projekt",
tencm: "10cm",
your_wool: "Twoja wełna",
woolball_size: "Każdy kłębek zawiera",
wool_nb_required: "Ilość potrzebnych kłębków",
woolballs: "Kłębki wełny",
number_required: "Wpisz liczby",
woolball_convertor: "Konwerter kłębków wełny",
pattern_translate: "Tłumaczenie instrukcji",
activ_sound: "Włącz dźwięki, aby móc sterować aplikacją za pomocą głosu",
say_next:"Powiedz 'NEXT STEP' aby przejść do następnego rzędu",
say_next_counter:"Powiedz 'NEXT STEP' aby przejść do następnego rzędu",
say_next_ios:"Powiedz 'NEXT' aby przejść do następnego rzędu",
say_next_counter_ios:"Powiedz 'NEXT' aby przejść do następnego rzędu",
search: "",
permission_not_granted: "pozwolenie nie zostało przyznane",
retry: "spróbować ponownie",
authorize: "autoryzować",
help_1: "kliknij ikonę, aby uzyskać więcej informacji",
you_knitted: "zrobiłeś",
youre_now: "jesteś teraz",
patterns_easy: "nowicjusz",
patterns_medium: "łatwo",
patterns_hard: "średni",
ravelry_library: "My Ravelry Library",
		ravelry_search:"Szukaj Ravelry",
		import_pattern: "SCHEMAT importu",
		import_to_counter: "SCHEMAT importu",
		downloading:"ściąganie...",
select_source:"",
confirm_import_webpage: "Importu ?",
confirm_delete_imported_pattern:"usunąć SCHEMAT?",
ask_img_upload: "Twoje zdjęcie będzie publiczne",
add_picture: "Dodaj zdjęcie",
send_feedback:"Send Feedback",
important_feedback:"",
send:"OK",
		thanks:"Thank you!",
time_elapsed: "Pora",
long_press: "naciśnij długo, aby wyświetlić opcje",
take_pic: "zrobić zdjęcie",
  		gallery_pic: "wybierz zdjęcie",
reset_timer:"Wyzeruj timer",
see_picture:"widzieć zdjęcie",
change_picture:"Zmień obrazek",
phone: "Twoje urządzenie",
select_language:"Wybierz język",
download: "pobieranie",
reset_counter:"nastawić",
started_on:'rozpoczęty',
time_elapsed2:'Pora',
need_help_contact:"potrzebujesz pomocy? -> tricotonapp@gmail.com",
auto_translate:"automatyczne tłumaczenie",
raverly_suggestions:'Ravelry',
wool_size:"Rozmiar przędzy :",
needle_size:"Rozmiar igły :",
yardage:"Metraż :",
in_your_language:"Dostępne w Twoim języku :",
yes:"tak",
		no:"Nie",
access_ravelry_pattern:"utwórz projekt i zaimportuj go z biblioteki ravelry",
		have_pattern:"już to mam",
buy_download_hint:"Kliknij przycisk \"pobierz\" po zakupie wzoru.",

you_no_account:"Nie masz konta?",
login: "Zaloguj Się",
already_have_account:"mam już konto",
register:"zarejestrować",
username:"Nazwa Użytkownika",
your_email:"Twój email",
your_password:"Twoje hasło",
password_not_equal:"Hasło nie jest równe",
repeat_password:"Powtórz hasło",
enter_username: "Wprowadź nazwę użytkownika",

login_desc:"Zaloguj się, aby korzystać z aplikacji na kilku urządzeniach",

try_premium:"Wypróbuj wersję premium aplikacji!",
help_us:"Aby umożliwić nam kontynuowanie pracy nad tą aplikacją i cieszyć się wszystkimi funkcjami, prosimy o Twój wkład.",

premium_abo: "Subskrypcja Premium",
no_ads:"Bez reklam",
all_patterns_unlocked:"Wszystkie wzory odblokowane",
access_every_device:"Synchronizacja w chmurze: uzyskaj dostęp do swoich projektów ze wszystkich swoich urządzeń. Nigdy nie traćcie postępu!",
unlimited_counters:"Tak wiele liczników, jak chcesz",
unlimited_pictures:"Dodaj zdjęcia!",
try_premium_btn:"Wypróbuj wersję premium",
continue_free:"Przejdź do bezpłatnej wersji",
login_required:"Zaloguj się, aby móc wykonać kopię zapasową swoich projektów.",
buy_required:"Synchronizacja jest wyłączona. Dowiedz się więcej o wszystkich funkcjach Premium tutaj.",
log_out:"Wyloguj",
year:"rok",
		month: "Miesiąc",
		monthly_sub: "Miesięczna subskrypcja",
		annual_sub: "Roczna subskrypcja",


premium_feature:"To jest funkcja premium",
premium_pattern:"To jest wzór premium",
try_free:"Wypróbuj Premium za darmo przez tydzień!",
learn_more_premium:"Dowiedz się więcej o składce",
wrong_pwd_email:"zły email lub hasło",
error_occured_check_connection:"Wystąpił błąd. Czy masz połączenie z internetem?",
already_paid:"Mam już aktywną subskrypcję",
enter_description:"Wprowadź opis tutaj",
invalid_email:"Niepoprawny email",
email_already_registered:"Email już zarejestrowany",
username_already_registered:"Nazwa użytkownika już istnieje",

	free_week_trial:"Wypróbuj za darmo przez 7 dni!",
	learn_about:"dowiedz się więcej",

	ad_warning:"Wkrótce możesz zobaczyć reklamy. więcej informacji.",

	buy:"kupować",

	remove_ads:"usuń reklamy",

	 continue:"dalej",


	 buy_pattern_desc:"Można kupić tylko ten wzór, będzie jeszcze reklamy w aplikacji.",
		buy_all_pattern_desc:"Nabyć wszystkie wzory na raz. Korzystaj z aplikacji bez reklam i oszczędź do 80% od cen sygnatur.",
		buy_premium:"Albo spróbuj Premium i cieszyć się wszystkie wzory premium i możliwości, reklamy za darmo. Twoje projekty są synchronizowane między wszystkimi swoimi urządzeniami.",
		buy_pattern_price:"KUPUJESZ TEN wzorzec dla :",
		buy_all_patterns_price:"Kup wszystkie wzorce dla ",

		buy_all_pattern_no_ads:"Nabyć wszystkie wzory na raz. Korzystaj z aplikacji bez reklam.",

		password_forgotten:"Zapomniane hasło?",

		projects_synchro:"Projekty zapisane na serwerze",
		last_update:"Ostatnia aktualizacja",

		show_reset: "Pokaż liczbę resetów",

		annotations_hint:"Możesz długo kliknąć dokument, aby dodać adnotacje.",

		add_annotation_text:"Dodaj swój tekst tutaj",


		select_mode:"Select mode",
		knitting_mode: "",
		crochet_mode: "",

		badge1_crochet: "Poziom 1",
		badge2_crochet: "Poziom 2",
		badge3_crochet: "Poziom 3",
		badge4_crochet: "Poziom 4",
		badge5_crochet: "Poziom 5",
		badge6_crochet: "Poziom 6",

		type:'rodzaj',
		discard_changes:'Odrzucać zmiany ?',

		agree:'zgadzam się',
		agree_email:'Wyrażam zgodę na otrzymywanie wiadomości e-mail z aplikacji',
		see_terms: 'Zobacz warunki i politykę prywatności',
		must_agree_pp: 'Вы должны согласиться с условиями и политикой конфиденциальности, чтобы продолжить',
		duplicate: 'zduplikowany',

		no_ravelry_project:'Nie masz żadnego projektu na Ravelry.',
		already_imported:'Już zaimportowałeś ten projekt',
		import_ravelry:"lub importuj z Ravelry",
		import:'Import',
		small:'mały',
		normal:'normalna',
		big:'duży',
		options:'Ustawienia',
		show:'pokaz',
		hide:'ukryj',
		long_press_position:'Naciśnij długo element, aby zmienić jego pozycję',
		enable_expert_options:'Możesz włączyć tryb ekspercki w ustawieniach',
		enable_expert:"Włącz tryb ekspercki",
		counter_size:"liczników rozmiar",
		highligther_size:'rozmiar zakreślacza',
		starts_at:'zaczyna się o',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'następnie',
		minutes:'minuty',
		seconds:'sekundy',
		configure:'skonfigurować',
		add_to_timer: 'i dodaj automatycznie do timera bez pytania, kiedy ekran był wyłączony krócej niż X minuty:',
		keep_timer_off:'Timer pozostaje włączony, gdy ekran jest wyłączony',
		timer_ask:"Minęły {0}, odkąd opuściłeś aplikację, czy chcesz dodać je do timera?",
		add_time_timer:"Dodać czas do timera?",
		select_camera: "Zrobić zdjęcie",
    	select_gallery: "Galeria",
    	photo:'Zdjęcie',

    	syncing:'synchronizacja',
		sync_error:'Błąd synchronizacji',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',




		troubleshoot_watch:"Wsparcie",
reset_all_timers:"Zresetowany zegar? Cały czas spędzony na projekty zostaną zresetowane do 0.",
premium_file_sharing:"Tylko użytkownicy Premium mogą korzystać z udostępniania plików.",
shared_with_me:"Podziel sie ze mna",
no_shared:"Nikt nie podzielił się z wami jeszcze projektu.",
accept:"Zaakceptować",
from:"od",
share_project:"Dzielić projekt",
share_copyright_check:"Nie będę dzielić dokument prawa autorskiego",
share_copyright:"Należy przestrzegać autorów i tylko udostępniać dokumenty, dla których mają uprawnienia. Udostępnianie materiałów chronionych prawem autorskim może spowodować zamknięcie konta.",
share_project_with:"Chcę podzielić się z tego projektu",
disable_social:"wyposażony Wyłącz Społecznej",
friends:"Przyjaciel (s)",
shared:"Shared",
chats:"wiadomości",
chat:"Wiadomość",
join_community:"Dołącz do społeczności!",
social_desc:"Znajdź przyjaciół i dzielić radość. Znajomi mogą zobaczyć swój postęp, zdjęć i możesz dzielić się swoimi projektami z nimi.",
projects:"Projektowanie",
unfriend:"Usuń tego znajomego",
congratulate:"Pogratulować",
wait_confirm:"oczekiwanie na potwierdzenie",
not_found:"Nie znaleziono",
invite_friend:"Zaprosić przyjaciela",
invite_ext:"Witam Chciałbym zaprosić Państwa do skorzystania z aplikacji My Wiersz licznika. Zainstalować go i dodaj mnie jako znajomego moja nazwa użytkownika jest {0}",
add_friend_desc:"Dodaj znajomych w aplikacji w celu komunikowania się z nimi i dzielić swoje postępy. Znajomi będą mogli zobaczyć swoje zdjęcia.",
send_invitation:"Wyślij zaproszenie",
add_friend:"Dodaj znajomego",
no_friend_yet:"Nie przyjaciel jeszcze",
start_chatting:"Kliknij, aby rozpocząć rozmowę",
you_finished_project:"Skończyłeś projekt",
you_started_project:"Ci zacząć nowy projekt",
finished_project:"zakończeniu projektu",
started_project:"rozpoczął nowy projekt",
reached_level:"jest teraz poziom",
you_reached_level:"Jesteś teraz poziom",
sent_message:"wysłał Ci wiadomość",
congratulated_you:"pogratulował Ci 👏",
shared_project_with_you:"wspólny projekt z tobą",
wants_to_be_friend:"chce być twoim przyjacielem!",
became_your_friend:"stał się Twoim przyjacielem!",
type_your_message:"Wpisz swoją wiadomość",


login_social:"Zaloguj się, aby dołączyć do społeczności",

faq_translated:"Ta strona jest tylko w języku angielskim. Czy chcesz zobaczyć automatyczne tłumaczenie?",



		watch_help_1:"Na Apple Watch uruchom aplikację. Na tym telefonie wybierz projekt. Jeśli to nie zadziała, przeczytaj poniższe instrukcje",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",

		select:'Wybierz',
		select_part:'Wybierz Part',
		next_part:"Kolejny",
		part_finished:"części gotowej",
		part_finished_desc:"Gratulacje zakończeniu tej części!",
		part:"część",
		delete_part:"Usunąć tę rolę? Wszystkie liczniki w tej części zostaną usunięte.",

		highlight_vertical:"Wyświetl zakreślacz w pionie",

		back:"Z powrotem",
		configure_ads:"Ustawienia reklam",
		partner_click:"Kliknij na jednego z naszych partnerów by dowiedzieć się jak wykorzystuje on dane.",
		how_use_data:"W jaki sposób wykorzystujemy Twoje dane",
		tailor_ads:"Pozwól na wykorzystanie swoich danych do wyświetlania spersonalizowanych reklam?",
		consent_desc:"Dbamy o prywatność i bezpieczeństwo Twoich danych. Pokazujemy reklamy by wspierać bezpłatną wersję aplikacji.",
		consent_desc2:"Możesz zmienić swój wybór w dowolnym momencie w ustawieniach aplikacji.",
		how_collect_data:"W jaki sposób my i nasi partnerzy zbieramy dane",
		relevant_ads:"Tak, pokazuj spersonalizowane reklamy",
		ad_free:"Kup wersję bez reklam",
		not_relevant_ads:"Nie, pokazuj reklamy które nie są spersonalizowane",

		activate:"Uruchomić",

		enable_tracking: "GDPR",
        enable_tracking_desc: "Dbamy o Państwa prywatność i bezpieczeństwo danych. Zezwolenie na stosowanie plików cookie zwiększy komfort korzystania z tej aplikacji, pozwoli nam na uzyskanie większej ilości informacji w przypadku wystąpienia błędu, dzięki czemu będziemy mogli go naprawić, dostosować funkcje do Twoich potrzeb, a wykorzystanie identyfikatora reklamowego pozwoli na wyświetlenie mniejszej ilości reklam, które byłyby dla Ciebie nieistotne.  ",
        allow_cookies: "Pozwólcie na to.",
        disallow_cookies: "Pozbądź się. Rozumiem, że moje doświadczenie w tej aplikacji będzie mniej dostosowane do moich potrzeb i moje problemy nie będą zgłaszane",

        convert_what:"Co chcesz przekonwertować??",
        to:"do",


        yarn_yards	:"jardów",
yarn_meters	:"metrów",
ounces	:"uncji",
add	:"Dodaj",
select_this_yarn	:"Wybierz tę przędzę",
select	:"Wybierz",
stash	:"Schowek",
stash_ravelry_beta	:"Ta funkcja jest w Beta, używasz na własne ryzyko i zgłaszasz problemy (błędy, tłumaczenia) na tricotonapp@gmail.com.",
adjust_no_sync	:"Regulacje nie mogą być zsynchronizowane z Raverly.",
import	:"Import",
select_needle	:"Wybór igły",
remove	:"Usuń",
nothing_here	:"Jeszcze nic tu nie ma.",
see_needles	:"Patrz na igły",
see_stash	:"Zobacz schowek",
needles	:"Igły",
needle	:"Igła",
link_ravelry_proj	:"Czy chcesz powiązać ten projekt z istniejącym projektem na Ravelry?",
link_ravelry_proj_hint	:"Wybierz \"nie\", jeśli nie używasz \"Raverly\", lub jeśli nie stworzyłeś tego projektu na Ravelry",
must_be_img_pdf	:"plik nie może być pobrany. Musi to być obraz lub plik PDF",
cannot_be_zero	:"Nie może być 0",
color	:"Kolor",
add_from_stash	:"Dodaj z magazynu",
link_needles	:"podłączyć igły do tego projektu",
import_ravelry2	:"Import z Ravelry",
create	:"Stwórz",
starting_quantity	:"Ilość początkowa:",
use	:"Użyj",
adjust	:"Dostosuj",
edit_yarn	:"Edit Yarn",
yarn_stash	:"Schowek na przędzę (Beta)",
needle_stash	:"Schowek na igły (Beta)",
sync_ravelry	:"Synchronizacja z Ravelry",
enter_weight	:"wprowadzać wagę",
enter_length	:"podać długość",
totals	:"Totalnie:",
nb_skeins	:"Liczba motylków",
name	:"Nazwa",
company_name	:"Nazwa firmy",
yarn_weight	:"Waga przędzy",
unlink_ravelry	:"Unlink z Ravelry",
colorname	:"Nazwa koloru",
select_weight	:"wybrany ciężar",
use_nb_skeins	:"Liczba motylków",
use_totals	:"Sumy użytkowe",
each_skein	:"Każdy szkielet jest:",
notes	:"Uwagi",
edit	:"Edycja",
disconnect_ravelry	:"Wyloguj się z Ravelry",
type	:"wybrany typ",
length	:"Długość",
size	:"Rozmiar",
notes	:"Uwagi",
select_all	:"Wybierz wszystkie",
select_corresponding_project	:"Wybierz odpowiedni projekt na Ravelry",
used_by:"Używany do",
		left_stash:"wciąż w magazynie",

		other_sources:"więcej",

		create_custom_proj:"I have a pattern",
		create_custom_proj_desc:"Use many counters as needed, import a pattern from any source if you want to.",
		import_rc_pattern:"I'm looking for inspiration",
		import_rc_pattern_desc:"Browse among our free patterns, you might find something you like!",
		import_project_ravelry:"I have a Raverly project",
		import_project_ravelry_desc:"Import an existing Ravelry project to the app, use many counters to follow your progress.",
		ask_resize_counters:"Would you like to make the counters appear smaller? You can change the counters size anytime in the settings.",
		start:"Start",
		personal_source:" ",
		external_source:" ",



		appear_row:" ",
		single_row:"Single Row",
		range_rows:"w kilku rzędach",


		title:"Tytuł",
		icon:"Ikona",
		document_locked:"Cannot add annotations, PDF is protected by author",
},

es: {
distribstitches: {
            tooltitle: 'Distribuir Aumento y Disminución',
            increases: 'aumenta',
            increase: 'incrementar',
            decreases: 'disminuye',
            decrease: 'disminución',
            stitches: 'puntos de sutura',
            stitch: 'puntada',
            pleaseenter: 'Por favor, introduzca los 2 valores',
            willstart: 'Se iniciará la siguiente manera:',
            desirednb: 'número deseado de puntadas',
            currentnb: 'número actual de puntadas',
            weadvise: 'Aconsejamos hacer',
            every: 'cada',
            thefirst: 'Iniciar la primera',
            after: 'después',
            then: 'entonces',
            endwith: 'y luego terminar con',
        },
        infoImportPattern: "Haga clic aquí para importar su patrón de PDF, Web o Video",
lang: "es",
tts_lang: "es-ES",
ok: "ok",
cancel: "cancelar",
start_new_project: "Nuevo proyecto",
new_project: "Nuevo proyecto",
project_name: "Nombre del proyecto",
enter_name: "Ingresa tu nombre",
enter_project_name: "Por favor ingresa un nombre de proyecto",
project_type: "Selecciona el patrón de tejer",
next: "SIGUIENTE",
motif: "Seleccione Puntada",
material: "NECESITAS",
description: "DESCRIPCIÓN",
begin: "EMPEZAR",
finish: "TERMINAR",
current_rang: "Actual ahora: ",
repeat: "Repetir : ",
times: " veces",
stitches: "Puntadas",
rangs: "Vueltas",
projs_completed: "Terminado",
tutorials: "Tutoriales",
select_size: "Selecciona el tamaño",
select_woolsize: "Seleccione el tamaño de lana",
linked_tutos: "Tutoriales relacionados",
quantity: "Cantidad",
size: "tamaño",
loading: "Cargando...",
badge1: "Nivel 1",
badge2: "Nivel 2",
badge3: "Nivel 3",
badge4: "Nivel 4",
badge5: "Nivel 5",
badge6: "Nivel 6",
badge7: "Nivel 7",
badge8: "Nivel 8",
badge9: "Nivel 9",
badge10: "Nivel 10",
part: "PARTE ",
previous_seq: "Paso anterior",
finished: "¡Has terminado tu proyecto!",
congratz: "¡FELICITACIONES!",
row_counters: "Contadores de vueltas",
global_counter: "Contador global",
secondary_counter: "Contador secundario",
nb_row: "Número de vueltas",
name: "Nombre: (Opcional)",
save: "GUARDAR",
auto_inc: "Enlace con Global Counter",
set_up_main_counter: "Haz clic en el contador principal para configurarlo",
counter_project: "Usar contadores de vueltas",
edit_counters: "Editar contadores",
enter_comment_opt: "Ingresa un comentario aquí si quieres",
show_com_at_row: "Mostrar comentario en esta vuelta #",
add_counter_com: "Añadir un comentario",
comments: "Comentarios",
reinit_at: "Restablecer después de alcanzar esta vuelta",
counters: "CONTADORES",
counters_desc:"	Mantén un registro de tu progreso con nuestros contadores de vueltas. \ N \ nPuede configurar: \ n- 1 Contador global, para contar las vueltas de tu proyecto completo \ n- Varios contadores secundarios, que te ayudarán con su aumento, disminución o patrones de tejer. \ n \ n¿Necesitas un recordatorio en una vuelta específica? Agrégalo como un comentario en el contador relacionado, ¡se mostrará en el momento correcto!",
set_average_stitches:"	Ingrese el número promedio de puntadas por vuelta (aproximadamente)",
average_stitches: "Promedio de puntadas por vueltas",
delete: "Borrar",
enter_number: "Por favor, introduce un número",
meters: "METRO",
centimeters: "CM",
inches: "Pulgadas",
yards: "Yardas",
convert_units: "Conversor de unidades",
fill_all_fields: "Por favor llene todos los campos",
your_project: "TU PROYECTO",
compute_swatch: "Adaptador de muestras",
pattern: "PATRÓN",
your_swatch: "TU MUESTRA",
swatch: "muestra de tela",
must_monter: "debes comenzar sobre:",
must_knit: "debes tejer:",
grams: "gramos",
tools: "Herramientas",
the_project: "el proyecto",
tencm: "10cm",
your_wool: "Tu lana",
woolball_size: "Cada bola contiene",
wool_nb_required: "Número de bolas requeridas",
woolballs: "Bolas de lana",
number_required: "por favor ingresa los números",
woolball_convertor: "Conversor de bolas de lana",
pattern_translate: "Traducción de instrucciones",
activ_sound: "Activa el sonido para poder usar la aplicación con tu voz",
say_next:"Di 'NEXT STEP' para ir a la siguiente vuelta",
say_next_counter:"Di 'NEXT STEP' para ir a la siguiente vuelta",
say_next_ios:"Di 'NEXT' para ir a la siguiente vuelta",
say_next_counter_ios:"Di 'NEXT' para ir a la siguiente vuelta",
search: "",
permission_not_granted: "permiso no concedido",
retry: "rever",
authorize: "autorizar",
help_1: "haga clic en el ícono para más información",
you_knitted: "has tejido",
youre_now: "ahora estas",
patterns_easy: "Principiante",
patterns_medium: "Fácil",
patterns_hard: "Intermedio",
or:"o",
ravelry_library: "Ravelry Library",
		ravelry_search:"Buscar Ravelry",
		import_pattern: "Importación PATRÓN",
		import_to_counter: "Importación PATRÓN",
downloading:"descargan...",
select_source:"",
confirm_import_webpage: "Importación ?",
confirm_delete_imported_pattern:"retirar PATRÓN?",
ask_img_upload: "tu imagen será pública",
add_picture: "Añadir foto",
send_feedback:"Feedback",
important_feedback:"",
are_you_satisfied: "danos tu opinión para mejorar la aplicación",
send:"OK",
		thanks:"Thank you!",
time_elapsed: "Tiempo",
long_press: "Presione durante más tiempo en el proyecto para más opciones",
take_pic: "toma una foto",
  		gallery_pic: "seleccionar imagen",
reset_timer:"Reiniciar temporizador",
see_picture:"Ver foto",
change_picture:"Cambiar imagen",
select_language:"Seleccionar idioma",
download: "descargar",
storage_full: "Almacenamiento lleno. Elimine algunos archivos o aplicaciones y vuelva a intentarlo",
phone: "Tu dispositivo",
reset_counter:"Reiniciar contador",
started_on:'Comenzado',
time_elapsed2:'Tiempo',

need_help_contact:"¿necesita ayuda? -> tricotonapp@gmail.com",
auto_translate:"traducción automática",
raverly_suggestions:'Ravelry',
wool_size:"Tamaño del hilo :",
needle_size:"Tamaño de aguja :",
yardage:"Metraje :",
in_your_language:"Disponible en su idioma :",
yes:"sí",
		no:"no",


access_ravelry_pattern:"crear un proyecto e importarlo de la biblioteca ravelry",
		have_pattern:"ya lo tengo",
buy_download_hint:"Después de comprar el patrón, haga clic en \"Download\"",

you_no_account:"¿No tienes una cuenta?",
login: "iniciar sesión",
already_have_account:"ya tengo una cuenta",
register:"registro",
username:"nombre de usuario",
your_email:"Tu correo electrónico",
your_password:"tu contraseña",
password_not_equal:"La contraseña no es igual",
repeat_password:"repita su contraseña",
enter_username: "Introduce un nombre de usuario",login_desc:"Inicia sesión para usar la aplicación en varios dispositivos.",try_premium:"Prueba la versión premium de la aplicación!",
help_us:"Para permitirnos seguir trabajando en esta aplicación y disfrutar de todas las funciones, le pedimos su contribución.",premium_abo: "Suscripción premium",
no_ads:"Sin anuncios",
all_patterns_unlocked:"Todos los patrones desbloqueados",
access_every_device:"Sincronización en la nube: Acceda a sus proyectos desde todos sus dispositivos. Nunca pierdas tu progreso!",
unlimited_counters:"Todos los contadores que quieras.",
unlimited_pictures:"Añade fotos!",
try_premium_btn:"Prueba la versión premium",
continue_free:"Continuar con la versión gratuita.",
login_required:"Por favor inicie sesión para poder respaldar sus proyectos.",
buy_required:"La sincronización está desactivada. Obtenga más información sobre todas las funciones Premium aquí.",
log_out:"Cerrar sesión",
year:"Año",
		month: "Mes",
		monthly_sub: "Suscripción mensual",
		annual_sub: "Suscripción anual",
premium_feature:"Esta es una característica premium",
premium_pattern:"Este es un patrón premium",
try_free:"¡Prueba Premium gratis por una semana!",
learn_more_premium:"Aprende más sobre premium",
wrong_pwd_email:"Correo o contraseña equivocada",
error_occured_check_connection:"Ocurrió un error. ¿Estás conectado a internet?",
already_paid:"Ya tengo una suscripción activa",
enter_description:"Introduce la descripción aquí",
invalid_email:"Email inválido",
email_already_registered:"correo electrónico ya registrado",
username_already_registered:"nombre de usuario ya existe",


	free_week_trial:"Prueba gratis por 7 días!",

	learn_about:"aprender más acerca de",

	ad_warning:"Puede ver anuncios aquí pronto. más información >",

	buy:"comprar",

	remove_ads:"eliminar publicidad",

	  continue:"continuar",


	  buy_pattern_desc:"Usted puede comprar este patrón solamente, de todas maneras tendrá anuncios en la aplicación.",
		buy_all_pattern_desc:"Compra todos los patrones a la vez. Disfrutar de la aplicación sin anuncios y ahorrar hasta un 80% en los precios de patrones.",
		buy_premium:"O probar Premium y disfrutar de todos los patrones y características de primera calidad, anuncios gratuitos. Sus proyectos están sincronizados entre todos sus dispositivos.",
		buy_pattern_price:"COMPRA EL PATRÓN DE : ",
		buy_all_patterns_price:"Comprar todos las pautas de : ",

		buy_all_pattern_no_ads:"Compra todos los patrones a la vez. Disfrutar de la aplicación sin anuncios.",

		password_forgotten:"¿Contraseña olvidada?",


		projects_synchro:"Proyectos guardados en el servidor",

		last_update:"última actualización",

		show_reset: "Mostrar el número de restablecimientos",

		annotations_hint:"Puede hacer clic largo en el documento para agregar anotaciones.",

		add_annotation_text:"Agrega tu texto aquí",

		select_mode:"Select mode",
		knitting_mode: "Tejer",
		crochet_mode: "Crochet",

		badge1_crochet: "Nivel 1",
		badge2_crochet: "Nivel 2",
		badge3_crochet: "Nivel 3",
		badge4_crochet: "Nivel 4",
		badge5_crochet: "Nivel 5",
		badge6_crochet: "Nivel 6",

		type:'tipo',
		discard_changes:'Descartar los cambios ?',


		agree:'estoy de acuerdo',
		agree_email:'Acepto recibir correos electrónicos de la aplicación',
		see_terms: 'Ver términos y política de privacidad',
		must_agree_pp: 'Debes aceptar los términos y condiciones y la política de privacidad para continuar',
		duplicate: 'duplicado',

		no_ravelry_project:'No tienes ningún proyecto en Ravelry.',
		already_imported:'Ya importaste este proyecto',
		import_ravelry:"o importar desde Ravelry",
		import:'importación',
		small:'pequeña',
		normal:'normal',
		big:'grande',
		options:'Configuraciones',
		show:'mostrar',
		hide:'ocultar',
		long_press_position:'Mantenga presionado un elemento para cambiar su posición',
		enable_expert_options:'Puede habilitar el modo experto en la configuración',
		enable_expert:"Habilitar modo experto",
		counter_size:"contadores tamaño",
		highligther_size:'tamaño del resaltador',
		starts_at:'empieza a',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'luego',
		minutes:'minutos',
		seconds:'segundos',
		configure:'configurar',
		add_to_timer: 'y agregar al temporizador automáticamente sin preguntarme cuando la pantalla estuvo apagada menos de X minutos:',
		keep_timer_off:'El temporizador permanece encendido cuando la pantalla está apagada',
		timer_ask:"Han pasado {0} desde que dejó la aplicación, ¿desea agregarlos al temporizador?",
		add_time_timer:"Añadir tiempo al temporizador?",
		select_camera: "Toma una foto",
    	select_gallery: "Importar una imagen",
    	photo:'Foto',


    	syncing:'Sincronización',
		sync_error:'Error de sincronización',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',


		troubleshoot_watch:"Ayuda",
reset_all_timers:"Restablecimiento del temporizador? Todo el tiempo dedicado a los proyectos se restablecerá a 0.",
premium_file_sharing:"Sólo los usuarios Premium pueden utilizar para compartir archivos.",
shared_with_me:"Comparte conmigo",
no_shared:"Nadie ha compartido un proyecto con usted todavía.",
accept:"Aceptar",
from:"desde",
share_project:"Compartir un proyecto",
share_copyright_check:"No voy a compartir un documento con derechos de autor",
share_copyright:"Por favor, respeten los autores y sólo compartir documentos para los que tiene permiso. Compartir material con copyright puede resultar en la terminación de su cuenta.",
share_project_with:"Quiero compartir este proyecto con",
disable_social:"deshabilitar las características Social",
friends:"Amigos)",
shared:"Compartido",
chats:"mensajes",
chat:"Mensaje",
join_community:"¡Unete a la communidad!",
social_desc:"Encontrar amigos y compartir la alegría. Los amigos pueden ver su avance, sus imágenes y usted puede compartir sus proyectos con ellos.",
projects:"proyectos",
unfriend:"Eliminar este amigo",
congratulate:"Felicitar",
wait_confirm:"esperando confirmación",
not_found:"Extraviado",
invite_friend:"Invitar a un amigo",
invite_ext:"Hola me gustaría invitarle a probar la aplicación Mi Fila del contador. Instalarlo y agregarme como amigo mi nombre de usuario es {0}",
add_friend_desc:"Añadir a sus amigos en la aplicación con el fin de comunicarse con ellos y compartir su progreso. Los amigos serán capaces de ver sus imágenes.",
send_invitation:"Enviar invitacion",
add_friend:"Añade un amigo",
no_friend_yet:"Sin embargo amigo",
start_chatting:"Haga clic aquí para empezar a chatear",
you_finished_project:"¿Ha terminado un proyecto",
you_started_project:"Usted comenzó un nuevo proyecto",
finished_project:"terminó un proyecto",
started_project:"comenzó un nuevo proyecto",
reached_level:"es ahora nivel",
you_reached_level:"Ahora se encuentra nivel",
sent_message:"le envió un mensaje",
congratulated_you:"le felicitó 👏",
shared_project_with_you:"compartió un proyecto con usted",
wants_to_be_friend:"quiere ser tu amigo!",
became_your_friend:"se convirtió en su amigo!",
type_your_message:"Escribe tu mensaje",

login_social:"Inicia sesión para unirte a la comunidad",

faq_translated:"Esta página web solo está en inglés, ¿le gustaría ver una traducción automática?",



		watch_help_1:"En su Apple Watch, inicie la aplicación. En este teléfono, seleccione un proyecto. Si esto no funciona, lea las instrucciones a continuación.",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",


		select:'Seleccione',
		select_part:'Seleccione Parte',
		next_part:"próximo",
		part_finished:"parte terminado",
		part_finished_desc:"Enhorabuena, ha terminado esta parte!",
		part:"parte",
		delete_part:"Eliminar esta parte? Se eliminarán todos los contadores en esta parte.",

		highlight_vertical:"Mostrar resaltador verticalmente",


		back:"volver",
		configure_ads:"Configurar anuncios",
		partner_click:"Haga clic en uno de nuestros socios de aprender cómo usa los datos.",
		how_use_data:"¿Cómo utilizamos sus datos",
		tailor_ads:"¿Podemos personalizar los anuncios de acuerdo con sus datos?",
		consent_desc:"e muestran anuncios para ofrecer una versión gratuita de la aplicación.",
		consent_desc2:"Puede cambiar su selección más adelante en las opciones",
		how_collect_data:"Cómo usamos sus datos",
		relevant_ads:"Sí, ver anuncios relevantes",
		ad_free:"Compra la versión sin publicidad",
		not_relevant_ads:"No, vea anuncios que son menos relevantes",

		enable_tracking: "GDPR",
        enable_tracking_desc: "Nos preocupamos por su privacidad y la seguridad de sus datos. Permitir las cookies mejorará su experiencia en esta aplicación, nos permitirá tener más información en caso de un error para que podamos arreglarlo, adaptar las características a sus necesidades y el uso de la identificación de la publicidad permitirá mostrar menos anuncios que serían irrelevantes para usted. ",
        allow_cookies: "Permita.",
        disallow_cookies: "No lo admita. Entiendo que mi experiencia en esta aplicación se adaptará menos a mis necesidades y mis problemas no serán reportados",


		activate:"activar",

		convert_what:"¿Qué quieres convertir??",
        to:"a",


        yarn_yards	:"yardas",
yarn_meters	:"metros",
ounces	:"onzas",
add	:"Añade",
select_this_yarn	:"Seleccione este hilo",
select	:"seleccione",
stash	:"esconder",
stash_ravelry_beta	:"Esta característica está en Beta, úsela bajo su propio riesgo e informe de los problemas (errores, errores de traducción) a tricotonapp@gmail.com",
adjust_no_sync	:"Los ajustes no pueden ser sincronizados con Raverly",
import	:"Importación",
select_needle	:"Selección de la aguja",
remove	:"Retire",
nothing_here	:"Todavía no hay nada aquí.",
see_needles	:"Ver agujas",
see_stash	:"Ver el escondite",
needles	:"Agujas",
needle	:"Aguja",
link_ravelry_proj	:"¿Quiere vincular este proyecto a un proyecto existente sobre Ravelry?",
link_ravelry_proj_hint	:"Selecciona \"no\" si no usas \"Raverly\", o si no creaste este proyecto en Ravelry",
must_be_img_pdf	:"no puede ser descargado. Debe ser imagen o PDF",
cannot_be_zero	:"No puede ser 0",
color	:"Color",
add_from_stash	:"Añadir desde el escondite",
link_needles	:"conectar las agujas a este proyecto",
import_ravelry2	:"Importado de Ravelry",
create	:"Crear",
starting_quantity	:"Cantidad inicial:",
use	:"Utilice",
adjust	:"Ajustar",
edit_yarn	:"Editar hilo",
yarn_stash	:"Yarn Stash (Beta)",
needle_stash	:"Aguja Escondida (Beta)",
sync_ravelry	:"Sincronización con Ravelry",
enter_weight	:"introducir el peso...",
enter_length	:"introduzca la longitud",
totals	:"Totales:",
nb_skeins	:"Número de madejas",
name	:"Nombre",
company_name	:"Nombre de la compañía",
yarn_weight	:"El peso del hilo",
unlink_ravelry	:"Desencadenar de Ravelry",
colorname	:"Nombre del color",
select_weight	:"seleccione el peso",
use_nb_skeins	:"Número de madejas",
use_totals	:"Usar los totales",
each_skein	:"Cada madeja es:",
notes	:"Notas",
edit	:"Edita",
disconnect_ravelry	:"Salga de Ravelry",
type	:"seleccione el tipo",
length	:"Longitud",
size	:"Tamaño",
notes	:"Notas",
select_all	:"Seleccione todo...",
select_corresponding_project	:"Seleccione el proyecto correspondiente en Ravelry",
used_by:"Se utiliza para",
		left_stash:"todavía en el escondite",

		other_sources:"más",

		create_custom_proj:"I have a pattern",
		create_custom_proj_desc:"Use many counters as needed, import a pattern from any source if you want to.",
		import_rc_pattern:"I'm looking for inspiration",
		import_rc_pattern_desc:"Browse among our free patterns, you might find something you like!",
		import_project_ravelry:"I have a Raverly project",
		import_project_ravelry_desc:"Import an existing Ravelry project to the app, use many counters to follow your progress.",
		ask_resize_counters:"Would you like to make the counters appear smaller? You can change the counters size anytime in the settings.",
		start:"Start",
		personal_source:" ",
		external_source:" ",


		appear_row:" ",
		single_row:"1 Vuelta",
		range_rows:"varias vueltas",

		title:"Título",
		icon:"Icono",
		document_locked:"Cannot add annotations, PDF is protected by author",

},

it : {
distribstitches: {
            tooltitle: 'Distribuire Aumento & Decremento',
			increases: 'aumenta',
			increase: 'aumentare',
			decreases: 'diminuisce',
			decrease: 'diminuire',
			stitches: 'punti di sutura',
			stitch: 'punto',
			pleaseenter: 'Si prega di inserire i 2 valori',
			willstart: 'Inizierà in questo modo:',
			desirednb: 'il numero di punti desiderato',
			currentnb: 'Numero attuale di punti',
			weadvise: 'Si consiglia di fare',
			every: 'ogni',
			thefirst: 'Inizia il primo',
			after: 'dopo',
			then: 'poi',
			endwith: 'e poi finiscono con',
        },
	lang: "it",
	infoImportPattern: "Clicca qui per importare il tuo modello PDF, Web o Video",
tts_lang: "it-IT",
ok: "ok",
cancel: "Annulla",
start_new_project: "Nuovo Progetto",
	new_project: "Nuovo Progetto",
project_name: "Nome del Progetto",
enter_name: "Inserisci Nome",
enter_project_name: "Si prega di inserire il nome del progetto",
project_type: "O Seleziona un Motivo",
next: "PROSSIMO",
motif: "Seleziona Maglia",
material: "HAI BISOGNO DI",
description: "DESCRIZIONE",
begin: "INIZIO",
finish: "FINE",
current_rang: "Fila Corrente: ",
repeat: "Ripeti: ",
times: " volte",
stitches: "Maglie",
rangs: "Righe",
projs_completed: "Completato",
tutorials: "Tutorial",
select_size: "Seleziona Taglia",
select_woolsize: "Seleziona la Taglia della Lana",
linked_tutos: "Tutorial Correlati",
quantity: "Quantità",
size: "Dimens.",
loading: "Caricamento…",
badge1: "Livello 1",
badge2: "Livello 2",
badge3: "Livello 3",
badge4: "Livello 4",
badge5: "Livello 5",
badge6: "Livello 6",
badge7: "Livello 7",
badge8: "Livello 8",
badge9: "Livello 9",
badge10: "Livello 10",
part: "PARTE",
previous_seq: "Passi precedenti",
finished: "Hai finito il progetto!",
congratz: "CONGRATULAZIONI!",
row_counters: "Contatore Righe",
global_counter: "Contatore Globale",
secondary_counter: "Contatore Secondario",
nb_row: "Numero di Righe",
name: "Nome (Opzionale)",
save: "SALVA",
auto_inc: "Collega al Contatore Globale",
set_up_main_counter: "Clicca sul contatore principale per impostarlo",
counter_project: "Usa il Contatore di Righe",
edit_counters: "Modifica Contatori",
enter_comment_opt: "Inserisci qui un commento, se vuoi",
show_com_at_row: "Mostra il commento a questa riga #",
add_counter_com: "Aggiungi un commento",
comments: "Commenti",
reinit_at: "Resetta dopo aver raggiunto questa riga",
counters: "CONTATORI",
counters_desc:"Tieni traccia dei tuoi progressi globali con i nostri contatori!\n\nPuoi configurare:\n-1 Contatore Globale, per contare le righe del tuo intero progetto\n- Diversi contatori Secondari, che ti aiuteranno con l'aumento o i vari motivi.\n\nHai bisogno di un promemoria dI una riga specifica? Aggiungilo come commento nel contatore correlato, ti verrà mostrato al momento giusto!",
set_average_stitches:"Si prega di inserire il numero medio di maglie per fila (approssimativamente)",
average_stitches: "Numero medio di maglie per righe",
delete: "Elimina",
enter_number: "inserisci un numero",
meters: "M",
centimeters: "CM",
inches: "Pollici",
yards: "Yard",
convert_units: "Convertitore di unità di misura",
fill_all_fields: "Si prega di compilare tutti i campi",
your_project: "IL TUO PROGETTO",
compute_swatch: "Adattatore del Campione",
pattern: "MOTIVO",
your_swatch: "IL TUO CAMPIONE",
swatch: "campione",
must_monter: "devi iniziare:",
must_knit: "devi lavorare a maglia:",
grams: "grammi",
tools: "Strumenti",
the_project: "il progetto",
tencm: "10cm",
your_wool: "La tua lana",
woolball_size: "Ciascun gomitolo contiene",
wool_nb_required: "Numero di gomitoli necessari",
woolballs: "Gomitoli di lana",
number_required: "Si prega di inserire il numero",
woolball_convertor: "Convertitore di gomitoli di lana",
pattern_translate: "Traduzione delle istruzioni",
activ_sound: "Attiva i suoni per poter usare l'app con la voce",
say_next:"Dici 'NEXT STEP' per passare alla prossima fila",
say_next_counter:"Dici 'NEXT STEP' per passare alla prossima fila",
say_next_ios:"Dici 'NEXT' per andare alla prossima fila (consiglio: fallo in una stanza silenziosa, anche una voce profonda funziona meglio, per cui usa la tua voce mascolina)",
say_next_counter_ios:"Dici 'NEXT' per aumentare il contatore principale (consiglio: fallo in una stanza silenziosa, anche una voce profonda funziona meglio, per cui usa la tua voce mascolina)",
search: "cerca",
permission_not_granted: "Permesso non concesso",
retry: "Riprova",
authorize: "Consenti",
help_1: "Puoi cliccare sull'icona per vedere maggiori informazioni",
you_knitted: "Ora sei ",
youre_now: "",
patterns_easy: "Motivo Principiante",
patterns_medium: "Motivi Facili",
patterns_hard: "Motivi Intermedi",
ravelry_library: "La mia Libreria Ravelry",
ravelry_search:"Cerca su Ravelry",
import_pattern: "Importa Motivo",
import_to_counter: "Importa nel mio contatore",
downloading:"Caricamento… potrebbe richiedere alcuni minuti",
select_source:"Seleziona fonte:",
confirm_import_webpage: "Questa pagina sarà importata come la vedi ora, continuare?",
confirm_delete_imported_pattern: "Rimuovere motivo?",
ask_img_upload: "La tua immagine può essere condivisa con altri utenti, continuare?",
add_picture: "Aggiungi Immagine",
send_feedback:"Invia Feedback",
important_feedback:"Il Tuo Feedback è importante",
are_you_satisfied: "Sei soddisfatto della tua opera d'arte? Cosa ne pensi del motivo?\n\nPuoi lasciarci le tue impressioni qui per aiutarci a migliorare l'app!",
send:"invia",
thanks:"Grazie",
time_elapsed: "Tempo",
long_press: "Tieni premuto sul progetto per vedere ulteriori opzioni",
take_pic: "Fai una foto",
gallery_pic: "Seleziona dalla Galleria",
select_pic: "Si prega di selezionare:",
reset_timer:"Resetta il timer",
see_picture:"Vedi immagine",
change_picture:"Cambia Immagine",
stitches_on_row:"",
storage_full: "storage full",
phone: "dispositivo",
select_language:"select lang",
download: "download",
reset_counter:"Resetta contatore",
started_on:'Iniziato il',
time_elapsed2:'Tempo',

auto_translate:"traduzione automatica",
need_help_contact:"hai bisogno di aiuto? -> tricotonapp@gmail.com",
raverly_suggestions:'Ravelry',

wool_size:"Dimensione del filato :",
needle_size:"Dimensione dell'ago :",
yardage:"quantità in iarde :",
in_your_language:"Disponibile nella tua lingua :",
yes:"sì",
		no:"no",
		access_ravelry_pattern:"Crea un progetto e importalo dalla libreria di ravelry",
		have_pattern:"ce l'ho già",
		buy_download_hint:"Dopo aver acquistato lo schema, fare clic su \"Download\".",



you_no_account:"Non hai un conto?",
login: "accesso",
already_have_account:"Ho già un conto",
register:"Registrare",
username:"nome utente",
your_email:"la tua email",
your_password:"la tua password",
password_not_equal:"Password non uguale",
repeat_password:"Ripeti la password",
enter_username: "Inserisci un nome utente",login_desc:"Accedi per utilizzare l'app su diversi dispositivi",try_premium:"Prova la versione premium dell'app!",
help_us:"Per consentirci di continuare a lavorare su questa app e godere di tutte le funzionalità, ti stiamo chiedendo il tuo contributo.",premium_abo: "Abbonamento Premium",
no_ads:"Nessuna pubblicità",
all_patterns_unlocked:"Tutti i modelli sbloccati",
access_every_device:"Sincronizzazione nel cloud: accedi ai tuoi progetti da tutti i tuoi dispositivi. Non perdere mai i tuoi progressi!",
unlimited_counters:"Come molti contatori che vuoi",
unlimited_pictures:"Aggiungi foto!",
try_premium_btn:"Prova la versione premium",
continue_free:"Continua con la versione gratuita",
login_required:"Effettua il login per poter eseguire il backup dei tuoi progetti.",
buy_required:"La sincronizzazione è disattivata. Scopri di più su tutte le funzionalità Premium qui.",
log_out:"Disconnettersi",
year:"anno",
		month: "mese",
		monthly_sub: "Abbonamento mensile",
		annual_sub: "Abbonamento annuale",
premium_feature:"Questa è una caratteristica premium",
premium_pattern:"Questo è un modello premium",
try_free:"Prova Premium gratuitamente per una settimana!",
learn_more_premium:"Ulteriori informazioni su premium",
wrong_pwd_email:"email o password errate",
error_occured_check_connection:"Si è verificato un errore. Sei connesso a Internet?",
already_paid:"Ho già un abbonamento attivo",
enter_description:"Inserisci la descrizione qui",
invalid_email:"E-mail non valido",
email_already_registered:"Email già registrata",
username_already_registered:"il nome utente esiste già",

	free_week_trial:"Prova gratis per 7 giorni!",

	learn_about:"impara di più riguardo",


	ad_warning:"Potresti vedere gli annunci qui a breve. maggiori informazioni >",

	buy:"acquistare",

	remove_ads:"rimuovere la pubblicità",

	   continue:"Continua",

	   buy_pattern_desc:"È possibile acquistare questo modello unico, si avrà ancora annunci su app.",
		buy_all_pattern_desc:"Acquista tutti i modelli in una sola volta. Godetevi l'applicazione senza pubblicità e risparmiare fino al 80% sui prezzi del modello.",
		buy_premium:"Oppure provate Premium e godere di tutti i modelli premium e caratteristiche, un annuncio gratuito. I suoi progetti sono sincronizzate tra tutti i dispositivi.",
		buy_pattern_price:"COMPRARE QUESTO MODELLO PER ",
		buy_all_patterns_price:"Acquista tutti i modelli per ",

		buy_all_pattern_no_ads:"CAcquista tutti i modelli in una sola volta. Godetevi l'app senza annunci.",

		password_forgotten:"Password dimenticata?",

		projects_synchro:"Progetti salvati sul server",
		last_update:"ultimo aggiornamento",

		show_reset: "Mostra il numero di reset",

		annotations_hint:"È possibile fare clic a lungo sul documento per aggiungere annotazioni.",

		add_annotation_text:"Aggiungi testo qui...",


		select_mode:"Select mode",
		knitting_mode: "Maglieria",
		crochet_mode: "Uncinetto",

		badge1_crochet: "Livello 1",
		badge2_crochet: "Livello 2",
		badge3_crochet: "Livello 3",
		badge4_crochet: "Livello 4",
		badge5_crochet: "Livello 5",
		badge6_crochet: "Livello 6",

		type:'genere',
		discard_changes:'Non salvare le modifiche ?',

		agree:'sono d\'accordo',
		agree_email:'Accetto di ricevere e-mail dall\'app',
		see_terms: 'Vedi i termini e l\'informativa sulla privacy',
		must_agree_pp: 'È necessario accettare i termini e le condizioni e l\'informativa sulla privacy per continuare',
		duplicate: 'duplicato',

		no_ravelry_project:'Non hai progetti su Ravelry.',
		already_imported:'Hai già importato questo progetto',
		import_ravelry:"o importare da Ravelry",
		import:'importazione',
		small:'piccola',
		normal:'normale',
		big:'grande',
		options:'Impostazioni',
		show:'mostrare',
		hide:'nascondere',
		long_press_position:'premere a lungo su un oggetto per cambiarne la posizione',
		enable_expert_options:'È possibile abilitare la modalità esperto nelle impostazioni',
		enable_expert:"Abilita modalità esperto",
		counter_size:"dimensione contatori",
		highligther_size:'dimensione dell\'evidenziatore',
		starts_at:'inizia a',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',

		then_price:'poi',
		minutes:'minuti',
		seconds:'secondi',
		configure:'configure',
		add_to_timer: 'e aggiungi automaticamente al timer senza chiedermi quando lo schermo era spento per meno di X minuti:',
		keep_timer_off:'Il timer rimane attivo quando lo schermo è spento',
		timer_ask:"Sono trascorsi {0} da quando hai lasciato l'app, vuoi aggiungerli al timer.",
		add_time_timer:"Aggiungi tempo al timer?",
		select_camera: "Fare una foto",
    	select_gallery: "Importa foto",
    	photo:'Foto',

    	syncing:'Sincronizzazione',
		sync_error:'Errore di sincronizzazioned',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',

		troubleshoot_watch:"Aiuto",
reset_all_timers:"Ripristino timer? Tutto il tempo speso per progetti sarà riportato a 0.",
premium_file_sharing:"Solo gli utenti Premium possono utilizzare la condivisione di file.",
shared_with_me:"Condiviso con me",
no_shared:"Nessuno ha ancora condiviso un progetto con te.",
accept:"Accettare",
from:"a partire dal",
share_project:"Condividere un progetto",
share_copyright_check:"Non voglio condividere un documento protetto da copyright",
share_copyright:"Si prega di rispettare gli autori e condividere documenti solo per i quali si dispone di autorizzazione. Condivisione di materiale protetto da copyright può comportare la chiusura del tuo account.",
share_project_with:"Voglio condividere questo progetto con",
disable_social:"Caratteristiche Disabilita sociale",
friends:"Amici)",
shared:"Condivisa",
chats:"messaggi",
chat:"Messaggio",
join_community:"Unisciti alla community!",
social_desc:"Trova gli amici e condividere la gioia. Gli amici possono vedere il tuo avanzamento, le immagini e si possono condividere i vostri progetti con loro.",
projects:"progetti",
unfriend:"Rimuovere questo amico",
congratulate:"congratularsi",
wait_confirm:"In attesa di conferma",
not_found:"Non trovato",
invite_friend:"Invita un amico",
invite_ext:"Ciao vorrei invitarvi a provare l'app My Counter Row. Installarlo e mi aggiungere come amico mio username è {0}",
add_friend_desc:"Aggiungere i tuoi amici sul app per comunicare con loro e condividere i tuoi progressi. Amici saranno in grado di vedere le tue foto.",
send_invitation:"Spedire un invito",
add_friend:"Aggiungi un amico",
no_friend_yet:"Non ancora amici",
start_chatting:"Clicca per iniziare a chattare",
you_finished_project:"Hai finito un progetto",
you_started_project:"Hai iniziato un nuovo progetto",
finished_project:"finito un progetto",
started_project:"ha iniziato un nuovo progetto",
reached_level:"è ora di livello",
you_reached_level:"Si è ora di livello",
sent_message:"ti ha inviato un messaggio",
congratulated_you:"si è congratulato con 👏",
shared_project_with_you:"ha condiviso un progetto con te",
wants_to_be_friend:"vuole essere tuo amico!",
became_your_friend:"è diventato tuo amico!",
type_your_message:"Inserisci il tuo messaggio",


login_social:"Accedi per unirti alla community",

faq_translated:"Questa pagina web è solo in inglese, ti piacerebbe vedere una traduzione automatica?",



		watch_help_1:"Sul tuo Apple Watch, avvia l'app. Su questo telefono, selezionare un progetto. Se questo non funziona, leggi le istruzioni di seguito",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",



		select:'Selezionare',
		select_part:'Seleziona Parte',
		next_part:"Il prossimo",
		part_finished:"parte finito",
		part_finished_desc:"Congratulazioni, avete finito questa parte!",
		part:"parte",
		delete_part:"Eliminare questa parte? Tutti i contatori in questa parte verranno eliminati.",

		highlight_vertical:"Visualizza evidenziatore in verticale",

		back:"indietro",
		configure_ads:"Configurare la pubblicità",
		partner_click:"Clicca su uno dei nostri partner per sapere come raccoglie e utilizza i dati",
		how_use_data:"Come utilizziamo i vostri dati?",
		tailor_ads:"Possiamo utilizzare i vostri dati per personalizzare gli annunci per voi?",
		consent_desc:"Gli annunci ci permettono di offrire una versione gratuita",
		consent_desc2:"È possibile modificare la scelta in qualsiasi momento nelle impostazioni dell'app",
		how_collect_data:"Come utilizziamo i vostri dati?",
		relevant_ads:"Sì, vedi le relative pubblicità",
		ad_free:"Acquista la versione senza pubblicità",
		not_relevant_ads:"No, vedi gli annunci meno rilevanti",

		enable_tracking: "GDPR",
        enable_tracking_desc: "Abbiamo a cuore la vostra privacy e la sicurezza dei dati. Consentire i cookie migliorerà la tua esperienza su questa applicazione, ci permetterà di avere più informazioni in caso di un bug in modo da poterlo risolvere, personalizzare le funzionalità in base alle tue esigenze e l'uso dell'ID pubblicitario permetterà di visualizzare meno annunci che sarebbero irrilevanti per te. ",
        allow_cookies: "Consentire.",
        disallow_cookies: "Non permettere. Capisco che la mia esperienza su questa app sarà meno adatta alle mie esigenze e i miei problemi non saranno segnalati",



		activate:"attivare",

		convert_what:"Cosa vuoi convertire?",
        to:"per",


        yarn_yards	:"yardas",
yarn_meters	:"metros",
ounces	:"onzas",
add	:"Añade",
select_this_yarn	:"Seleccione este hilo",
select	:"seleccione",
stash	:"esconder",
stash_ravelry_beta	:"Esta característica está en Beta, úsela bajo su propio riesgo e informe de los problemas (errores, errores de traducción) a tricotonapp@gmail.com",
adjust_no_sync	:"Los ajustes no pueden ser sincronizados con Raverly",
import	:"Importación",
select_needle	:"Selección de la aguja",
remove	:"Retire",
nothing_here	:"Todavía no hay nada aquí.",
see_needles	:"Ver agujas",
see_stash	:"Ver el escondite",
needles	:"Agujas",
needle	:"Aguja",
link_ravelry_proj	:"¿Quiere vincular este proyecto a un proyecto existente sobre Ravelry?",
link_ravelry_proj_hint	:"Selecciona \"no\" si no usas \"Raverly\", o si no creaste este proyecto en Ravelry",
must_be_img_pdf	:"no puede ser descargado. Debe ser imagen o PDF",
cannot_be_zero	:"No puede ser 0",
color	:"Color",
add_from_stash	:"Añadir desde el escondite",
link_needles	:"conectar las agujas a este proyecto",
import_ravelry2	:"Importado de Ravelry",
create	:"Crear",
starting_quantity	:"Cantidad inicial:",
use	:"Utilice",
adjust	:"Ajustar",
edit_yarn	:"Editar hilo",
yarn_stash	:"Yarn Stash (Beta)",
needle_stash	:"Aguja Escondida (Beta)",
sync_ravelry	:"Sincronización con Ravelry",
enter_weight	:"introducir el peso...",
enter_length	:"introduzca la longitud",
totals	:"Totales:",
nb_skeins	:"Número de madejas",
name	:"Nombre",
company_name	:"Nombre de la compañía",
yarn_weight	:"El peso del hilo",
unlink_ravelry	:"Desencadenar de Ravelry",
colorname	:"Nombre del color",
select_weight	:"seleccione el peso",
use_nb_skeins	:"Número de madejas",
use_totals	:"Usar los totales",
each_skein	:"Cada madeja es:",
notes	:"Notas",
edit	:"Edita",
disconnect_ravelry	:"Salga de Ravelry",
type	:"seleccione el tipo",
length	:"Longitud",
size	:"Tamaño",
notes	:"Notas",
select_all	:"Seleccione todo...",
select_corresponding_project	:"Seleccione el proyecto correspondiente en Ravelry",
used_by:"Usato per",
		left_stash:"ancora in deposito",

		other_sources:"altro",

		create_custom_proj:"I have a pattern",
		create_custom_proj_desc:"Use many counters as needed, import a pattern from any source if you want to.",
		import_rc_pattern:"I'm looking for inspiration",
		import_rc_pattern_desc:"Browse among our free patterns, you might find something you like!",
		import_project_ravelry:"I have a Raverly project",
		import_project_ravelry_desc:"Import an existing Ravelry project to the app, use many counters to follow your progress.",
		ask_resize_counters:"Would you like to make the counters appear smaller? You can change the counters size anytime in the settings.",
		start:"Start",
		personal_source:" ",
		external_source:" ",


		appear_row:" ",
		single_row:"1 riga",
		range_rows:"più righe",

		title:"titolo",
		icon:"Icona",
		document_locked:"Cannot add annotations, PDF is protected by author",
},

ko: {
distribstitches: {
            tooltitle: '증가 및 감소를 배포',
            increases: '증가',
            increase: '증가하다',
            decreases: '감소',
            decrease: '감소',
            stitches: '바늘',
            stitch: '땀',
            pleaseenter: '2 개 값을 입력하십시오',
            willstart: '그것은 다음과 같이 시작합니다 :',
            desirednb: '바늘의 원하는 번호',
            currentnb: '스티치의 현재 수',
            weadvise: '우리는 할 조언',
            every: '...마다',
            thefirst: '첫 번째 시작',
            after: '후',
            then: '그때',
            endwith: '다음에 종료',
        },
        infoImportPattern: "Click here to import your pattern from any source",
	lang: "ko",
tts_lang: "ko-KO",
ok: "승인",
cancel: "취소",
start_new_project: "새 프로젝트",
new_project: "새 프로젝트",
project_name: "프로젝트 명",
enter_name: "이름 입력",
enter_project_name: "프로젝트 명을 입력해주세요",
project_type: "아니면 뜨개질 패턴을 입력해주세요",
next: "다음",
motif: "바늘 선택",
material: "필요한 것들",
description: "서술",
begin: "시작",
finish: "종료",
current_rang: "현재 열:",
repeat: "반복",
times: "번",
stitches: "바늘  ",
rangs: "열",
projs_completed: "완료됨",
tutorials: "튜토리얼",
select_size: "사이즈 선택",
select_woolsize: "양모 사이즈 선택",
linked_tutos: "관련된 튜토리얼",
quantity: "량 ",
size: "사이즈  ",
loading: "로딩…",
badge1: "레벨 1",
badge2: "레벨 2",
badge3: "레벨 3",
badge4: "레벨 4",
badge5: "레벨 5",
badge6: "레벨 6",
badge7: "레벨 7",
badge8: "레벨 8",
badge9: "레벨 9",
badge10: "레벨 10",
part: "단계",
previous_seq: "전 단계",
finished: "프로젝트를 완수했습니다!",
congratz: "축하합니다!",
row_counters: "열 카운터",
global_counter: "글로벌 카운터",
secondary_counter: "이차 카운터",
nb_row: "열 수",
name: "이름(임의)",
save: "저장",
auto_inc: "글로벌 카운터 링크하기",
set_up_main_counter: "시작하기 위해 메인 카운터를 클릭해주세요",
counter_project: "열 카운터 사용하기",
edit_counters: "카운터 수정",
enter_comment_opt: "원하시면 댓글를 작성하세요",
show_com_at_row: "이 열#에 댓글 보이기",
add_counter_com: "댓글 추가",
comments: "댓글",
reinit_at: "이 열까지 오면 재설정하기",
counters: "카운터들",
counters_desc: "저희의 열 카운터로 과정에 주의를 기울이세요!\n\n설정하실 수 있습니다:\n- 1 글로벌 카운터, 전체 프로젝트의 열을 세야 할 경우\n- 특수한 행동에 도와줄 여러 이차 카운터들.\n\n특정 열을 기억해야하나요 ? 관련된 카운터에 댓글을 작성하시면 오른쪽에서 보실 수 있습니다! ",
set_average_stitches: "바늘 땀의 평균 수를 열마다 입력해주세요 (대략)",
average_stitches: "열마다 바늘 땀의 평균 수",
delete: "삭제",
number_required: "수를 입력해 주세요",
meters: "M",
centimeters: "CM",
inches: "인치",
yards: "야드",
convert_units: "구성 단위 전환기",
fill_all_fields: "모든 필드를 작성해 주세요",
your_project: "내 프로젝트",
compute_swatch: "스와치 어댑터",
pattern: "패턴",
your_swatch: "내 스와치",
swatch: "스와치  ",
must_monter: "바늘에 코를 만들어야합니다:",
must_knit: "반드시 해야 합니다:",
grams: "그램",
tools: "도구",
the_project: "프로젝트",
tencm: "10cm",
your_wool: "내 양털",
woolball_size: "각 공은 이 것을 포함하고 있습니다:",
wool_nb_required: "필요한 양의 공",
woolballs: "양털 공",
number_required: "수를  입력해 주세요",
woolball_convertor: "양털 공 전환기",
pattern_translate: "설명 버녁",
activ_sound: "음성을 사용하도록 음성을 활성화해주세요",
say_next: "검색",
say_next_counter: "NEXT STEP'를  말하시면 다음 행동으로 넘어갑니다 (팀: 조용한 곳에서 해주시고 낮게 말씀해주세요)",
say_next_ios: "NEXT STEP'를  말하시면 다음 행동으로 넘어갑니다 (팀: 조용한 곳에서 해주시고 낮게 말씀해주세요)",
say_next_counter_ios: "NEXT STEP'를  말하시면 다음 행동으로 넘어갑니다 (팀: 조용한 곳에서 해주시고 낮게 말씀해주세요)",
search: "검색",
permission_not_granted: "허락안됨",
retry: "다시시도",
authorize: "허락 ",
youre_now: "이제 당신은",
or:"아니면  ",
ravelry_library: "내 Ravelry 도서관",
ravelry_search:"Ravelry 검색",
import_pattern: "중요 패턴",
import_to_counter: "내 열 카운터로 불러오기",
downloading:"다운로드 중… 몇  분이 소모될 수 있습니다 ",
select_source:"자료 선택:",
confirm_import_webpage: "보시는 대로 불러옵니다. 계속하시겠습니까?",
confirm_delete_imported_pattern: "패턴을 제거하시겠습니까?",
ask_img_upload: "이미지가 다른 사람들에게 공유될 수  있습니다. 계속하시겠습니까?",
add_picture: "그림 추가",
send_feedback:"피드백 보내기",
important_feedback:"귀하의 피드백은 중요합니다",
are_you_satisfied: "직접 제작한 예술이 마음에 드시나요? 패턴은 어떻게 생각하시나요?\n\n앱을 개선하기 위해 의견을 말씀해주세요!",
send:"보내기",
thanks:"감사합니다",
time_elapsed: "시간",
long_press: "옵션을 보기위해 프로젝트를 길게 눌러주세요",
take_pic: "사진찍기",
gallery_pic: "도서관에서 선택",
select_pic: "선택해주세요:",
reset_timer:"타이머 재설정",
see_picture:"사진 보기",
change_picture:"사진 바꾸기",
stitches_on_row:"바늘 수",
storage_full: "저장소가 차있습니다. 앱을 지우고 다시 시도해주세요",
phone: "내 기기",

you_no_account:"계정이 없으십니까?",
login: "로그인",
already_have_account:"저는 이미 계정이 있어요",
register:"레지스터",
username:"사용자 이름",
your_email:"귀하의 이메일",
your_password:"너의 비밀번호",
password_not_equal:"비밀번호가 같지 않음",
repeat_password:"암호를 반복하십시오.",
enter_username: "사용자 이름 입력",login_desc:"여러 기기에서 앱을 사용하려면 로그인하세요.",try_premium:"앱의 프리미엄 버전을 사용해보십시오!",
help_us:"우리가이 응용 프로그램에서 계속 작업하고 모든 기능을 즐길 수 있도록, 우리는 당신의 기여를 요청하고 있습니다.",premium_abo: "프리미엄 구독",
no_ads:"광고 없음",
all_patterns_unlocked:"모든 패턴 잠금 해제 됨",
access_every_device:"클라우드의 동기화 : 모든 장치에서 프로젝트에 액세스하십시오. 귀하의 진전을 놓치지 마십시오!",
unlimited_counters:"원하는만큼 많은 카운터",
unlimited_pictures:"사진을 추가하십시오!",
try_premium_btn:"프리미엄 버전 사용해보기",
continue_free:"무료 버전 계속하기",
login_required:"프로젝트를 백업하려면 로그인하십시오.",
buy_required:"동기화가 꺼져 있습니다. 모든 프리미엄 기능에 대해 자세히 알아보십시오.",
log_out:"로그 아웃",
year:"년",
		month: "달",
		monthly_sub: "월간 구독",
		annual_sub: "연간 구독",
premium_feature:"이것은 프리미엄 기능입니다.",
premium_pattern:"이것은 프리미엄 패턴입니다.",
try_free:"1 주 동안 무료로 프리미엄을 사용해보십시오!",
learn_more_premium:"프리미엄에 대해 자세히 알아보기",
wrong_pwd_email:"잘못된 이메일 또는 비밀번호",
error_occured_check_connection:"오류가 발생했습니다. 인터넷에 연결되어 있습니까?",
already_paid:"이미 활성 구독이 있습니다.",
enter_description:"여기에 설명을 입력하십시오.",
invalid_email:"잘못된 이메일",
email_already_registered:"이미 등록 된 이메일",
username_already_registered:"사용자 이름이 이미 존재합니다",

	free_week_trial:"7 일 동안 무료로 사용해보십시오.",

	learn_about:"에 대해 자세히 알아보기",

	ad_warning:"You may see ads here soon. Learn why.",

	   continue:"Continue",


    	syncing:'Syncing',
		sync_error:'Sync Failed',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',

		other_sources:"Other sources",
},


da :{
distribstitches: {
            tooltitle: 'Fordel Stigning & Fald',
            increases: 'stiger',
            increase: 'øge',
            decreases: 'aftager',
            decrease: 'formindske',
            stitches: 'sting',
            stitch: 'søm',
            pleaseenter: 'Indtast de 2 værdier',
            willstart: 'Det vil begynde sådan her:',
            desirednb: 'Ønsket antal sting',
            currentnb: 'Aktuelt antal sting',
            weadvise: 'Vi anbefaler at gøre',
            every: 'hver',
            thefirst: 'Start den første',
            after: 'efter',
            then: 'derefter',
            endwith: 'og derefter afslutte med',
        },
	lang: "da",
		tts_lang: "da-DA",
		infoImportPattern: "Click here to import your pattern from any source",
		start_new_project: "Nyt projekt",
			new_project: "Nyt projekt",
		project_name: "Projektnavn ",
		enter_name: "Indsæt navn",
		enter_project_name: "Indsæt et projektnavn",
		project_type: "Eller vælg et strikkemønster",
		next: "NÆSTE",
		motif: "Vælg maske",
		material: "DU HAR BRUG FOR",
		description: "BESKRIVELSE",
		begin: "BEGYND",
		finish: "FÆRDIG",
		current_rang: "Nuværende række",
		repeat: "Gentag",
		times: "tidspunkter",
		stitches: "Masker ",
		rangs: "Rækker",
		projs_completed: "Fuldendte",
		tutorials: "Tutorials",
		select_size: "Vælg størrelse",
		select_woolsize: "Vælg størrelse på uld",
		linked_tutos: "Relaterede tutorials",
		quantity: "Mængde ",
		size: "Størrelse",
		loading: "Loader…",
		badge1: "level 1",
		badge2: "Level 2",
		badge3: "Level 3",
		badge4: "Level 4",
		badge5: "Level 5",
		badge6: "Level 6",
		badge7: "Level 7",
		badge8: "Level 8",
		badge9: "Level 9",
		badge10: "Level 10",
		part: "DEL",
		previous_seq: "Forrige trin",
		finished: "Du har færdiggjort dit projekt!",
		congratz: "TILLYKKE",
		row_counters: "Omgangstæller",
		global_counter: "Tællerkode",
		secondary_counter: "Sekundær tællerkode",
		nb_row: "Antal af rækker",
		name_optional: "Navn (valgfrit)",
		save: "GEM",
		auto_inc: "Link med tællerkode",
		set_up_main_counter: "Klik på den overordnede tæller for at installere",
		counter_project: "Brug omgangstællere",
		edit_counters: "Rediger omgangstællere",
		enter_comment_opt: "Indsæt kommentar her hvis du ønsker",
		show_com_at_row: "Vis kommentarer ved denne række #",
		add_counter_com: "Tilføj en kommentar",
		comments: "Kommentarer",
		reinit_at: "Nulstil efter denne række er nået",
		counters: "TÆLLERE",
		counters_desc: "Hold øje med dine fremskridt med vores omgangstællere!\n\nDu kan konfigurere:\n-1 Tællerkode for at tælle alle rækkerne i hele dit projekt\n- Der er flere sekundærer tællere, som kan hjælpe dig med specifikke handlinger\n\nHar du brug for en påmindelse ved en specifik række? Tilføj en kommentar i den relaterede tæller, og så vil den blive vist på det rette tidspunkt!",
		set_average_stitches: "Venligst indsæt det gennemsntilige antal af masker (cirka)",
		average_stitches: "Gennemsnitlige antal af masker per række",
		delete: "Slet",
		enter_number: "Venligst indtast et nummer",
		meters: "M",
		centimeters: "CM",
		inches: "Tommer",
		yards: "Yards",
		convert_units: "Enhedskonverter ",
		fill_all_fields: "Venligst udfyld alle felter",
		your_project: "DIT PROJEKT",
		compute_swatch: "Strikkefasthed omregner",
		pattern: "MØNSTER",
		your_swatch: "DIN STRIKKEFASTHED",
		swatch: "Strikkefasthed",
		must_monter: "du skal slå op:",
		must_knit: "du skal:",
		grams: "gram",
		tools: "Sytøjer",
		the_project: "projektet",
		tencm: "10cm",
		your_wool: "Dit uld",
		woolball_size: "Hver garnnøgle indeholder",
		wool_nb_required: "Antal af garnnøgler",
		woolballs: "garn uld nøgler ",
		number_required: "venligst indtast numrerne ",
		woolball_convertor: "garn uld nøgle konverter ",
		pattern_translate: "Instruktions oversættelser",
		activ_sound: "Aktivér lyden for at bruge appen med din stemme",
		say_next: "Sig 'NEXT STEP' for at udføre næste handling (tip: gør det i et stille rum, og gør din stemme så dyb så muligt, så brug din bedste mandestemme)",
		say_next_counter: "Sig 'NEXT STEP' for at udføre næste handling (tip: gør det i et stille rum, og gør din stemme så dyb så muligt, så brug din bedste mandestemme)",
		say_next_ios: "Sig 'NEXT' for at udføre næste handling (tip: gør det i et stille rum, og gør din stemme så dyb så muligt, så brug din bedste mandestemme)",
		say_next_counter_ios: "Sig 'NEXT' for at udføre næste handling (tip: gør det i et stille rum, og gør din stemme så dyb så muligt, så brug din bedste mandestemme)",
		search: "søg",
		permission_not_granted: "Tilladelse ikke givet",
		retry: "Prøv igen",
		authorize: "Tillad",
		youre_now: "Du er nu",
		or: "eller",
		ravelry_library: "Mit Ravelry Bibliotek",
		ravelry_search: "Søg Ravelry",
		import_pattern: "Importer mønster",
		import_to_counter: "Importer mønster til min omgangstæller",
		downloading: "Downloader… Det kan tage et par minutter",
		select_source: "Vælg kilde:",
		confirm_import_webpage: "Siden vil blive importeret som den ses nu. Fortsæt?",
		confirm_delete_imported_pattern: "Fjern mønster?",
		ask_img_upload: "Dit billede bliver muligvis delt med andre brugere, fortsæt?",
		add_picture: "Tilføj billede",
		send_feedback: "Send Feedback",
		important_feedback: "Din feedback er vigtig",
		are_you_satisfied: "Er du tilfreds med dit kunstværk? Hvad syntes du om mønsteret?\n\nDu kan bedømme os her, og hjælpe os med at forbedre appen!",
		send: "send",
		thanks: "Tak",
		time_elapsed: "Tid",
		long_press: "Tryk lang tid på projektet for at få flere muligheder",
		take_pic: "Tag billede",
		gallery_pic: "Vælg fra bibliotek",
		select_pic: "Venligst vælg:",
		reset_timer: "Nulstil timer",
		see_picture: "Se billede",
		change_picture: "Skift billede",
		stitches_on_row: "Total antal af masker:",
		storage_full: "Oplagring fuld. Venligst slet nogle filer eller apps og prøv igen",
		phone: "Din enhed",



		download: "Download",
		reset_counter:"nulstil tæller",
		started_on:'startede på',
		time_elapsed2:'tiden er gået',

		detailed_pattern:"Detailed pattern",


		you_no_account:"Har du ikke en konto?",
		login: "login",
		already_have_account:"Login",
	    register:"Opret en konto",
	    username:"Navn",
	    your_email:"E-mail",
	    your_password:"Password",
	    password_not_equal:"Passwords er ikke ens",
	    repeat_password:"Gentag Password",
	    enter_username: "Indtast et navn",

		login_desc:"Log ind for at bruge appen på flere enheder",

		try_premium:"Prøv Omgangstæller Premium!",
		help_us:"For at vi kan fortsætte vores arbejde med denne app, beder vi om en donering. ",

		premium_abo: "Premium abonnement ",

		no_ads:"Ingen reklamer",
		all_patterns_unlocked:"All patterns unlocked",
		access_every_device:"Brug denne app på flere enheder, alt er synkroniseret!",
		unlimited_counters:"Ubegrænset antal af tællere",
		unlimited_pictures:"Tilføj billeder til dit projekt",
		try_premium_btn:"Prøv Omgangstæller Premium!",
		continue_free:"Fortsæt med den begrænsede version >",
		login_required:"Log ind for at bruge appen på flere enheder",
		buy_required:"Synkroniseringen er slukket. For at bruge denne app på flere enheder, Log ind og abonnér til Omgangstæller Premium. 7 dages gratis prøveperiode, du kan aflyse når som helst !",
		log_out:"Log out",
		year:"per år",
		month: "Per måned",
		monthly_sub: "Månedligt abonnement",
		annual_sub: "Årligt abonnement",
		premium_feature:"Premium-funktion",
		premium_pattern:"Dette er et premium-mønster",
		try_free:"7 dages gratis prøveperiode",
		learn_more_premium:"Lær mere om OMGANGSTÆLLER PREMIUM",
		wrong_pwd_email:"forkert e-mail eller adgangskode",
		error_occured_check_connection:"en fejl opstod. er du forbundet til internettet",
		already_paid:"Jeg har allerede et aktivt abonnement",
		enter_description:"tilføj beskrivelse her",
		invalid_email:"Ugyldig email",
		email_already_registered:"Email allerede registreret",
		username_already_registered:"Navnet eksisterer allerede",
		free_week_trial:"7 dages gratis prøveperiode",
		learn_about:"Lær mere om",

		ad_warning:"Du kan se annoncer her snart. mere information >",
		buy:"købe",


		remove_ads:"fjern reklame",


		   continue:"Blive ved",


		   password_forgotten:"Adgangskode glemt?",

		   projects_synchro:"Projekter gemt på serveren",
last_update:"sidste ændring",

annotations_hint:"Du kan længe klikke på dokumentet for at tilføje kommentarer.",
add_annotation_text:"Tilføj tekst her...",


select_mode:"Select mode",
		knitting_mode: "Strikning",
		crochet_mode: "Hæklet",

		badge1_crochet: "Niveau 1",
		badge2_crochet: "Niveau 2",
		badge3_crochet: "Niveau 3",
		badge4_crochet: "Niveau 4",
		badge5_crochet: "Niveau 5",
		badge6_crochet: "Niveau 6",

		type:'type',
		discard_changes:'Vil du kassere ændringer?',

		agree:'jeg er enig',
		agree_email:'Jeg accepterer at modtage e-mails fra appen',
		see_terms: 'Se vilkår og fortrolighedspolitik',
		must_agree_pp: 'Du skal acceptere betingelserne og fortrolighedspolitikken for at fortsætte',
		duplicate: 'duplicate',

		no_ravelry_project:'Du har ikke noget projekt om Ravelry.',
		already_imported:'Du har allerede importeret dette projekt',
		import_ravelry:"eller import fra Ravelry",
		import:'Import',
		small:'lille',
		normal:'normal',
		big:'stor',
		options:'indstillinger',
		show:'Show',
		hide:'skjul',
		long_press_position:'tryk længe på et element for at ændre sin position',
		enable_expert_options:'Du kan aktivere eksperttilstand i indstillingerne',
		enable_expert:"Aktivér eksperttilstand",
		counter_size:"tællere storlek",
		highligther_size:'størrelse på markøren',
		starts_at:'starter kl',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'derefter',
		'minutes':'minutter',
		seconds:'sekunder',
		configure:'Configure',
		add_to_timer: 'og tilføj automatisk til timeren uden at spørge mig, hvornår skærmen var slukket mindre end X minutter:',
		keep_timer_off:'Timeren forbliver tændt, når skærmen er slået fra',
		timer_ask:"Der er gået {0}, siden du forlod appen, vil du føje dem til timeren?",
		add_time_timer:"Vil du tilføje tid til timeren?",
		select_camera: "tag et billede",
    	select_gallery: "vælg billede",
    	photo:'Foto',


    	syncing:'Synkronisering',
		sync_error:'Synkroniseringsfejl',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',

		troubleshoot_watch:"Hjælp",
reset_all_timers:"Nulstil timer? Al tid brugt på projekter vil blive nulstillet til 0.",
premium_file_sharing:"Kun Premium-brugere kan bruge fildeling.",
shared_with_me:"Delt med mig",
no_shared:"Ingen har delt et projekt med dig endnu.",
accept:"Acceptere",
from:"fra",
share_project:"Del et projekt",
share_copyright_check:"Jeg vil ikke dele et ophavsretligt beskyttet dokument",
share_copyright:"Vær venlig at respektere forfatterne og kun dele dokumenter, som du har tilladelse. Deling ophavsretligt beskyttet materiale kan resultere i opsigelse af din konto.",
share_project_with:"Jeg ønsker at dele dette projekt med",
disable_social:"Deaktiver sociale funktioner",
friends:"Ven (s)",
shared:"Delt",
chats:"Beskeder",
chat:"Besked",
join_community:"Deltag i fællesskab!",
social_desc:"Find venner og dele glæden. Venner kan se din fremgang, dine billeder og du kan dele dine projekter med dem.",
projects:"Projekter",
unfriend:"Fjern denne ven",
congratulate:"Lykønske",
wait_confirm:"Venter på bekræftelse",
not_found:"Ikke fundet",
invite_friend:"Inviter ven",
invite_ext:"Hej Jeg vil gerne invitere dig til at prøve den Min Row Counter app. Installer den og tilføje mig som en ven mit brugernavn er {0}",
add_friend_desc:"Tilføj dine venner på app for at kommunikere med dem og dele dine fremskridt. Venner vil være i stand til at se dine billeder.",
send_invitation:"Send invitation",
add_friend:"Tilføj en ven",
no_friend_yet:"Ingen ven endnu",
start_chatting:"Klik for at begynde at chatte",
you_finished_project:"Du færdig med et projekt",
you_started_project:"Du startede et nyt projekt",
finished_project:"afsluttet et projekt",
started_project:"startede et nyt projekt",
reached_level:"er nu niveau",
you_reached_level:"Du er nu niveau",
sent_message:"sendt dig en besked",
congratulated_you:"lykønskede dig 👏",
shared_project_with_you:"delte et projekt med dig",
wants_to_be_friend:"ønsker at være din ven!",
became_your_friend:"blev din ven!",
type_your_message:"Skriv din besked",


login_social:"Log ind for at deltage i samfundet",

faq_translated:"Denne webside er kun på engelsk, vil du gerne se en automatisk oversættelse?",



		watch_help_1:"Start appen på din Apple Watch. Vælg et projekt på denne telefon. Hvis dette ikke fungerer, skal du læse instruktionerne herunder",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",

		select:'Vælg',
		select_part:'Vælg del',
		next_part:"Næste",
		part_finished:"del færdig",
		part_finished_desc:"Tillykke, du har afsluttet denne del!",
		part:"del",
		delete_part:"Slet denne del? Alle tællere i denne del vil blive slettet.",

		highlight_vertical:"Vis markøren lodret",

		activate:"aktivere",

		convert_what:"hvad vil du konvertere?",
        to:"til",


        yarn_yards	:"yards",
yarn_meters	:"meter",
ounces	:"ounce",
add	:"Tilføje",
select_this_yarn	:"Vælg dette garn",
select	:"Vælg",
stash	:"stash",
stash_ravelry_beta	:"Denne funktion er i beta, bruge på dine egne problemer risiko- og betænkning (bug, oversættelsesfejl) til tricotonapp@gmail.com",
adjust_no_sync	:"Justeringer kan ikke synkroniseres med Raverly",
import	:"Importere",
select_needle	:"Needle udvælgelse",
remove	:"Fjerne",
nothing_here	:"Intet her endnu.",
see_needles	:"se nåle",
see_stash	:"Se stash",
needles	:"Nåle",
needle	:"Nål",
link_ravelry_proj	:"Ønsker du at linke dette projekt til et eksisterende projekt på Ravelry?",
link_ravelry_proj_hint	:"Vælg 'nej', hvis du ikke bruger \"Raverly\", eller hvis du ikke har oprettet dette projekt på Ravelry",
must_be_img_pdf	:"fil kan ikke downloades. Skal være billede eller PDF",
cannot_be_zero	:"Kan ikke være 0",
color	:"Farve",
add_from_stash	:"Tilføj fra stash",
link_needles	:"forbinde nåle til dette projekt",
import_ravelry2	:"Importer fra Ravelry",
create	:"skab",
starting_quantity	:"Starter mængde:",
use	:"Brug",
adjust	:"Justere",
edit_yarn	:"Rediger Garn",
yarn_stash	:"Garn Stash (beta)",
needle_stash	:"Needle Stash (beta)",
sync_ravelry	:"Synkroniser med Ravelry",
enter_weight	:"indtast vægt",
enter_length	:"indtaste længde",
totals	:"totaler:",
nb_skeins	:"Antal fed",
name	:"Navn",
company_name	:"firmanavn",
yarn_weight	:"Garn vægt",
unlink_ravelry	:"Fjern link fra Ravelry",
colorname	:"Farve Navn",
select_weight	:"vælg vægt",
use_nb_skeins	:"Antal fed",
use_totals	:"Brug totaler",
each_skein	:"Hver nøgle er:",
notes	:"Noter",
edit	:"Redigere",
disconnect_ravelry	:"Log ud fra Ravelry",
type	:"Type",
length	:"Længde",
size	:"Størrelse",
notes	:"Noter",
select_all	:"Vælg alle",
select_corresponding_project	:"Vælg tilsvarende projekt på Ravelry",
used_by:"anvendes til",
		left_stash:"tilbage i stash",

		other_sources:"mere",

		create_custom_proj:"Create a new custom project",
		create_custom_proj_desc:"Configure your counters and import your patterns from any source",
		import_rc_pattern:"Import a RC pattern",
		import_rc_pattern_desc:"Import a pattern specially crafted and optimized for this app",
		import_project_ravelry:"Import a Raverly project",
		import_project_ravelry_desc:"Import a Raverly project to the app, with all its details",
		ask_resize_counters:"Would you like to make the counters appear smaller? You can change the counters size anytime in the settings.",
		start:"Start",
		personal_source:" ",
		external_source:" ",


		appear_row:" ",
		single_row:"1 række",
		range_rows:"flere rækker",

		title:"Titel",
		icon:"ikon",
		document_locked:"Cannot add annotations, PDF is protected by author",
},


sv : {

distribstitches: {
			tooltitle: 'Fördela Ökning & Minskning',
			increases: 'ökar',
			increase: 'öka',
			decreases: 'minskar',
			decrease: 'minska',
			stitches: 'stygn',
			stitch: 'sy',
			pleaseenter: 'Fyll i 2 värden',
			willstart: 'Det kommer att börja så här:',
			desirednb: 'Önskat antal stygn',
			currentnb: 'Aktuellt antal stygn',
			weadvise: 'Vi rekommenderar att göra',
			every: 'varje',
			thefirst: 'Starta första',
			after: 'efter',
			then: 'sedan',
			endwith: 'och sedan sluta med',
		        },
	you_no_account:"Har du inget konto?",
login:"logga in",
infoImportPattern: "Click here to import your pattern from any source",
already_have_account:"Jag har redan ett konto",
register:"registrera",
username:"användarnamn",
your_email:"din e-post",
your_password:"ditt lösenord",
password_not_equal:"Lösenorden stämmer inte överens",
repeat_password:"Upprepa ditt lösenord",
enter_username:"Ange ett användarnamn",

login_desc:"Logga in för att använda appen på flera enheter",

try_premium:"Pröva premiumversionen av appen!",
help_us:"För att göra det möjligt för oss att fortsätta att arbeta på den här appen och glädjas åt alla funktioner ber vi dig om ditt bidrag.",

premium_abo:"Premiumprenumeration",
no_ads:"Inga annonser",
all_patterns_unlocked:"Alla möster är åtkomliga",
access_every_device:"Synkronisering i molnet: Kom åt alla dina projekt från alla dina enheter. Förlora aldrig överblicken över var du befinner dig!",
unlimited_counters:"Så många räknare som du vill ha",
unlimited_pictures:"Lägg till bilder!",
try_premium_btn:"Pröva premiumversionen",
continue_free:"Fortsätt med gratisversionen",
login_required:"Vänligen logga in för att kunna säkerhetskopiera dina projekt.",
buy_required:"Synkronisering är avstängd. Få reda på mer om alla Premiumfunktioner här.",
log_out:"Logga ut",
year:"Per år",
month: "Per månad",
monthly_sub: "Månadsabonnemangt",
annual_sub: "Årsabonnemang",

premium_feature:"Det här är en premiumfunktion",
premium_pattern:"Det hör är ett premiummönster",
try_free:"Pröva Premium gratis under en vecka!",
learn_more_premium:"Få reda på mer om premium",
wrong_pwd_email:"Fel e-post eller lösenord",
error_occured_check_connection:"Ett fel uppstod. Är du ansluten till internet?",
already_paid:"Jag har redan en aktiv prenumeration",
enter_description:"Ange beskrivning här",
invalid_email:"Ogiltig e-post",
email_already_registered:"E-post redan registrerad",
username_already_registered:"Användarnamnet existerar redan",

lang:"sv",
tts_lang:"sv",
ok:"ok",
cancel:"avbryt",
start_new_project:"Börja mitt\nförsta projekt",
new_project:"Nytt projekt",
project_name:"Projektnamn",
enter_name:"Ange namn",
enter_project_name:"Vänligen ange ett projektnamn",
project_type:"Välj stickningsmönster",
next:"NÄSTA",
motif:"Välj maskor",
material:"DU BEHÖVER",
description:"BESKRIVNING",
begin:"BÖRJA",
finish:"SLUT",
current_rang:"Nuvarande varv:",
repeat:"Repetera:",
times:"gånger",
stitches:"Maskor",
rangs:"Varv",
projs_completed:"Färdig",
tutorials:"Handledningar",
select_size:"Välj storlek",
select_woolsize:"Välj grovlek på garnet",
linked_tutos:"Relaterade handledningar",
quantity:"Kvantitet",
size:"Storlek",
loading:"Laddar…",
badge1:"Nivå 1",
badge2:"Nivå 2",
badge3:"Nivå 3",
badge4:"Nivå 4",
badge5:"Nivå 5",
badge6:"Nivå 6",
part:"DEL",
previous_seq:"Föregående steg",
finished:"Du har gjort färdigt ditt projekt!",
congratz:"GRATTIS!",
row_counters:"Varvräknare",
global_counter:"Global räknare",
secondary_counter:"Sekundär räknare",
nb_row:"Antal rader",
name:"Namn (valfritt)",
save:"SPARA",
auto_inc:"Länka till Global räknare",
set_up_main_counter:"Klicka på huvudräknaren för att ställa in",
counter_project:"Använd varvräknare",
edit_counters:"Ändra räknare",
enter_comment_opt:"Ange en kommentar här om du vill",
show_com_at_row:"Visa kommentar på detta varvnr",
add_counter_com:"Lägg till kommentar",
comments:"Kommentar",
reinit_at:"Återställ efter det att detta varv har nåtts",
counters:"RÄKNARE",
counters_desc:"Håll koll på hur arbetet fortskrider med våra varvräknare\n\nDu kan ställa in:\n- 1 Global räknare, för att räkna antal varv på hela ditt projekt\n- Flera sekundärräknare, som kommer att hjälpa dig att öka, minska eller sticka mönster.\n\nBehöver du en påminnelse på ett speciellt var? Lägg in det som en kommentar i den relevanta räknaren, så kommer den att visas vid rätt tillfälle!",
set_average_stitches:"Vänligen ange medelantal maskor per varv (ungefär)",
average_stitches:"Medelantal maskor per varv",
delete:"Radera",
enter_number:"vänligen ange ett nummer",
meters:"M",
centimeters:"CM",
inches:"Tum",
yards:"Yard",
convert_units:"Enhetsomvandlare",
fill_all_fields:"Vänligen fyll i alla fälten",
your_project:"DITT PROJEKT",
compute_swatch:"Provlappsadapter",
pattern:"MÖNSTER",
your_swatch:"DIN PROVLAPP",
swatch:"provlapp",
must_monter:"du måste lägga upp:",
must_knit:"du måste sticka:",
grams:"gram",
tools:"Verktyg",
the_project:"projektet",
tencm:"10 cm",
your_wool:"Ditt garn",
woolball_size:"Varje nystan innehåller",
wool_nb_required:"Antal nystan som krävs",
woolballs:"Garnnystan",
number_required:"vänligen ange siffror",
woolball_convertor:"Omvandlare för garnnystan",
pattern_translate:"Instruktionsöversättningar",
activ_sound:"Aktivera ljud för att kunna använda appen med din röst",
say_next:"Säg 'NEXT STEP' för att gå till nästa varv (tips: gör det i ett tyst rum, dessutom fungerar djupa röster bäst, så använd din mest manliga röst när du stickar)",
say_next_counter:"Säg 'NEXT STEP' för att räkna upp huvudräknaren (tips: gör det i ett tyst rum, dessutom fungerar djupa röster bäst, så använd din mest manliga röst när du stickar)",
say_next_ios:"Säg 'NEXT' för att gå till nästa varv (tips: gör det i ett tyst rum, dessutom fungerar djupa röster bäst, så använd din mest manliga röst när du stickar)",
say_next_counter_ios:"Säg 'NEXT' för att räkna upp huvudräknaren (tips: gör det i ett tyst rum, dessutom fungerar djupa röster bäst, så använd din mest manliga röst när du stickar)",
search:"sök",
permission_not_granted:"Tillstånd inte givet",
retry:"Försök igen",
authorize:"Tillåt",
help_1:"Du kan klicka på ikonen för att få se mer information",
you_knitted:"Du har stickat:",
youre_now:"Du är nu",
patterns_easy:"Nybörjarmönster",
patterns_medium:"Lätta\nmönster",
patterns_hard:"Mellansvåra mönster",
or:"eller",
ravelry_library:"Mitt Ravelry-bibliotek",
ravelry_search:"Sök Ravelry",
import_pattern:"Importera mönster",
import_to_counter:"Importera till min varvräknare",
downloading:"Laddar ner… Det kan ta några minuter",
select_source:"Välj källa:",
confirm_import_webpage:"Den här sidan kommer att bli importerad som du ser den nu. Fortsätt?",
confirm_delete_imported_pattern:"Ta bort mönster?",
ask_img_upload:"Din bild kan komma att delas med andra, fortsätt?",
add_picture:"Lägg till bild",
send_feedback:"Skicka återkoppling",
important_feedback:"Din återkoppling är betydelsefull",
are_you_satisfied:"Är du nöjd med ditt konstverk? Vad tyckte du om mönstret?\n\nDu kan lämna dina synpunkter till oss här, för att hjälpa oss förbättra appen!",
send:"skicka",
thanks:"Tack",
time_elapsed:"Tid",
long_press:"Tryck länge på projekt för mer alternativ",
take_pic:"Ta en bild",
gallery_pic:"Välj från bibliotek",
select_pic:"Vänligen välj:",
reset_timer:"Återställ timer",
see_picture:"Se bild",
change_picture:"Ändra bild",
stitches_on_row:"Maskor per varv:",
storage_full:"Förvaring full. Vänligen radera några filer eller appar och försök igen",
phone:"Din enhet",
select_language:"Välj språk",
download:"Ladda ner",
reset_counter:"Återställ räknare",
started_on:"Började på",
time_elapsed2:"Tid som gått",

detailed_pattern:"Detaljerat mönster",
real_virtual_pattern:"i appen och utskrivet",

have_kit:"Jag har redan satsen",
buy:"Köp",
enter_command:"För att komma åt mönstret ska du vänligen ange ordernumret. Du kan hitta det i din e-post med orderbekräftelsen.",
command_number:"Ordernummer:",
inside:"Inuti:",
price:"Pris:",
coloris:"Färger:",
kit_from:"Sats från:",
galerie:"Galleri",
invalid_order:"Ogiltigt ordernummer",
network_error:"Nätverksfel",
need_help_contact:"Behöver du hjälp? Kontakta oss på tricotonapp@gmail.com",
access_pattern:"Åtkomst av mönster",

auto_translate:"Vad som följer är en automatisk översättning av mönsterbeskrivningen. Mönstret har översatts manuellt.",
raverly_suggestions:"reverly_suggestions:'Vi älskar på Ravelry',",
needle_size:"Storlek på stickor:",
wool_size:"Garnstorlek:",
yardage:"Längden garn:",
in_your_language:"På ditt språk:",
yes:"ja",
no:"nej",
access_ravelry_pattern:"För att komma åt dina mönster ska du skapa ett projekt på vanligt sätt, klicka på importera mönster och gå till ditt Ravelry-bibliotek (eller ladda ner det på din telefon, och välja alternativer importera från telefonen)",
have_pattern:"Jag har redan mönstret",
buy_download_hint:"Efter det att du köpt mönstret ska du klicka på nedladdningsknappen på hemsidan för att starta ditt projekt.",


you_no_account:"Har du inget konto?",
login:"logga in",
already_have_account:"Jag har redan ett konto",
register:"registrera",
username:"användarnamn",
your_email:"din e-post",
your_password:"ditt lösenord",
password_not_equal:"Lösenord inte identiska",
repeat_password:"Upprepa ditt lösenord",
enter_username:"Ange ett användarnamn",

login_desc:"Logga in för att använda appen på flera enheter",

try_premium:"Pröva premiumversionen av appen!",
help_us:"För att låta oss fortsätta att arbeta på den här appen och glädjas åt alla funktioner ber vi dig om ditt bidrag.",

premium_abo:"Premiumprenumeration",

no_ads:"Inga annonser",
all_patterns_unlocked:"Alla mönster åtkomliga",
access_every_device:"Synkronisering i molnet: Kom åt alla dina projekt från alla dina enheter. Förlora aldrig överblicken över var du befinner dig!",
unlimited_counters:"Så många räknare som du vill ha",
unlimited_pictures:"Lägg till bilder!",
try_premium_btn:"Pröva premiumversionen",
continue_free:"Fortsätt med gratisversionen",
login_required:"Vänligen logga in för att kunna säkerhetskopiera dina projekt.",
buy_required:"Synkronisering är avstängd. Få reda på mer om alla Premiumfunktioner här.",
log_out:"Logga ut",
year:"år",

premium_feature:"Det här är en premiumfunktion",
premium_pattern:"Det hör är ett premiummönster",
try_free:"Pröva Premium gratis under en vecka!",
learn_more_premium:"Få reda på mer om premium",
wrong_pwd_email:"Fel e-post eller lösenord",
error_occured_check_connection:"Ett fel uppstod. Är du ansluten till internet?",
already_paid:"Jag har redan en aktiv prenumeration",
enter_description:"Ange beskrivning här",
invalid_email:"Ogiltig e-post",
email_already_registered:"E-post redan registrerad",
username_already_registered:"Användarnamnet existerar redan",
free_week_trial:"Pröva gratis under 7 dagar!",
learn_about:"Få reda på mer om",



privacy_policy:"Integritetspolicy",
tos:"Villkor",


explain_why_premium_title:"Varför erbjuder vi en premiumversion?",
explain_why_premium_1:"Vi började arbeta på appen under januari 2018 för att släppa vår första version 2 månader senare. Sedan dess arbetar vi varje dag på att förbättra den. Den har varit gratis och utan annonser under nästan ett år, och detta medan appen har krävt:",
explain_why_premium_2:"3500 timmars arbete",
explain_why_premium_3:"lagt beslag på vår fritid",
explain_why_premium_4:"Kostnader för webbhotell och översättningar",
explain_why_premium_5:"finansierats av våra besparingar",
explain_why_premium_6:"Att vara tillgänglig på arbetsdagar och helgdagar",
explain_why_premium_7:"för att svara på din e-post och kommentarer",
explain_why_premium_8:"Vi älskar det här projektet och vill fortsätta att förbättra appen så att den på bästa sätt möter dina behov, men vi kan inte fortsätta att förlora pengar på den.\n\nPremiumprenumerationen är ett sätt för dig att stödja oss, och tillåta oss att fortsätta att arbeta med det här fantastiska projektet!",


see_premium:"Se Premiumerbjudandet >",
why_not_free:"Varför är den här appen inte helt gratis?",

ad_warning:"Du kan komma att se annonser här snart. Få reda på varför.",

remove_ads:"Ta enbart bort annonser",

continue:"fortsätt",

ios_iap_info:"• Betalning kommer att debiteras till iTunes-kontot vid bekräftande av inköp\n• Prenumerationen förnyas automatiskt om inte auto-förnya stängs av minst 24 timmar innan slutet på nuvarande period\n• Kontot kommer att debiteras för förnyande inom 24 timmar innan slutet på nuvarande period, och identifiera kostnaden för förnyande\n• Prenumerationen kan hanteras av användaren och auto-förnya kan stängas av genom att gå till användarens Kontoinställningar efter inköp\n• Eventuell oanvänd del av en gratistestperiod kommer att förloras när användaren köper en prenumeration för den publikationen när så är applicerbart",

projects_synchro:"Projekt som sparas på servern",
last_update:"senaste uppdateringen",

annotations_hint:"Du kan länge klicka på dokumentet för att lägga till kommentarer.",

add_annotation_text:"Lägg till text här...",


select_mode:"Select mode",
		knitting_mode: "Stickning",
		crochet_mode: "Virka",

		badge1_crochet: "Nivå 1",
		badge2_crochet: "Nivå 2",
		badge3_crochet: "Nivå 3",
		badge4_crochet: "Nivå 4",
		badge5_crochet: "Nivå 5",
		badge6_crochet: "Nivå 6",

		type:'type',
		discard_changes:'Ignorera ändringar ?',

		agree:'jag håller med',
		agree_email:'Jag accepterar att få e-postmeddelanden från appen',
		see_terms: 'Se villkor och integritetspolicy',
		must_agree_pp: 'Du måste godkänna villkoren och sekretesspolicyn för att fortsätta',
		duplicate: 'duplicerade',

		no_ravelry_project:'Du har inget projekt på Ravelry.',
		already_imported:'Du har redan importerat det här projektet',
		import_ravelry:"eller importera från Ravelry",
		import:'Import',
		small:'små',
		normal:'vanligt',
		big:'stor',
		options:'inställningar',
		show:'Show',
		hide:'dölja',
		long_press_position:'tryck länge på ett objekt för att ändra position',
		enable_expert_options:'Du kan aktivera expertläge i inställningarna',
		enable_expert:"Aktivera expertläge",
		counter_size:"räknare storlek",
		highligther_size:'storleken på highlighter',
		starts_at:'börjar kl',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'sedan',
		minutes:'minuter',
		seconds:'sekunder',
		configure:'Configure',
		add_to_timer: 'och lägg till timern automatiskt utan att fråga mig när skärmen stängdes av mindre än X minuter:',
		keep_timer_off:'Timern förblir på när skärmen är av',
		timer_ask:"{0} har gått sedan du lämnade appen, vill du lägga till dem i timern?",
		add_time_timer:"Lägg till tid till timer?",
		select_camera: "ta en bild",
    	select_gallery: "välj bild",
    	photo:'Foto',

    	syncing:'synkronisering',
		sync_error:'Synkroniseringsfel',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',


		troubleshoot_watch: "hjälp",
		reset_all_timers: "Återställa timer? All tid på projekt återställs till 0.",
		premium_file_sharing:"Endast Premium-användare kan använda fildelning.",
		shared_with_me:"Delade med mig",
		no_shared:"Ingen har delat ett projekt med dig ännu.",
		accept:"Acceptera",
		from:"från",
		share_project:"Dela ett projekt",
		share_copyright_check:"Jag delar inte upphovsrättsskyddat material",
		share_copyright:"Vänligen respektera författarna och dela bara material du har tillåtelse för. Dela upphovsrättsskyddat material kan leda till att ditt konto avslutas.",
		share_project_with:"Dela projekt med",
		disable_social:"Inaktivera community",
		friends:"Vänner",
		shared:"Delad",
		chats:"meddelanden",
		chat:"meddelande",
		join_community:"Gå med i gemenskapen!",
		social_desc:"Hitta vänner och dela glädjen. Vänner kan se dina framsteg och bilder, och du kan dela projekt med dem.",
		projects:"projekt",
		unfriend:"ta bort vän",
		congratulate:"Gratulera",
		wait_confirm:"Väntar på bekräftelse",
		not_found:"Hittades inte",
		invite_friend:"Bjud in vän",
		invite_ext:"Hej, jag skulle vilja bjuda in dig att prova appen My Row Counter. Installera det och lägg till mig som en vän, mitt användarnamn är {0}",
		add_friend_desc:"Lägg till dina vänner på appen för att kommunicera med dem och dela dina framsteg. Vänner kan se dina bilder.",
		send_invitation:"Skicka inbjudan",
		add_friend:"Lägg till en vän",
		no_friend_yet:"Ingen vän ännu",
		start_chatting:"Klicka för att börja chatta",
		you_finished_project:"Du är klar med ett projekt",
		you_started_project:"Du startade ett nytt projekt",
		finished_project:"avslutade ett projekt",
		started_project:"Emily startade ett nytt projekt",
		reached_level:"är nu i nivå",
		you_reached_level:"du är nu nivå",
		sent_message:"skickade ett meddelande",
		congratulated_you:"gratulerade dig 👏",
		shared_project_with_you:"delade ett projekt med dig",
		wants_to_be_friend:"Emily vill bli din vän!",
		became_your_friend:"Emily blev din vän!",
		type_your_message:"Skriv ditt meddelande",


		login_social:"Logga in för att gå med i gemenskapen",

		faq_translated:"Den här webbsidan är bara på engelska, vill du se en automatisk översättning?",



		watch_help_1:"Starta appen på din Apple Watch. Välj ett projekt på den här telefonen. Om detta inte fungerar, läs instruktionerna nedan",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",

		select:'Välj',
		select_part:'Välj del',
		next_part:"Nästa",
		part_finished:"Del färdig",
		part_finished_desc:"Grattis, du är klar med den här delen!",
		part:"del",
		delete_part:"Ta bort den här delen? Alla räknare i denna del kommer att raderas.",

		highlight_vertical:"Visa markören vertikalt",

		activate:"Aktivera",


		convert_what:"vad vill du konvertera?",
        to:"till",


        yarn_yards	:"yards",
yarn_meters	:"meter",
ounces	:"ounces",
add	:"Lägg till",
select_this_yarn	:"Välj detta garn",
select	:"Välj",
stash	:"gömma",
stash_ravelry_beta	:"Denna funktion är i beta, använd på egen risk och rapportfrågor (bugg, översättningsfel) till tricotonapp@gmail.com",
adjust_no_sync	:"Justeringar kan inte synkroniseras med Raverly",
import	:"Importera",
select_needle	:"Needle val",
remove	:"Avlägsna",
nothing_here	:"Ingenting här ännu.",
see_needles	:"se nålar",
see_stash	:"Se stash",
needles	:"nålar",
needle	:"Nål",
link_ravelry_proj	:"Vill du länka projektet till ett befintligt projekt på Ravelry?",
link_ravelry_proj_hint	:"Välj 'nej' om du inte använder Raverly, eller om du inte har skapat det här projektet på Ravelry",
must_be_img_pdf	:"Filen kan inte laddas ned. Måste vara bild eller PDF",
cannot_be_zero	:"Kan inte vara 0",
color	:"Färg",
add_from_stash	:"Lägg till från stash",
link_needles	:"Anslut nålar till detta projekt",
import_ravelry2	:"Import från Ravelry",
create	:"Skapa",
starting_quantity	:"Från och kvantitet:",
use	:"Använda sig av",
adjust	:"Justera",
edit_yarn	:"Edit Garn",
yarn_stash	:"Garn Stash (Beta)",
needle_stash	:"Nålen Stash (Beta)",
sync_ravelry	:"Sync med Ravelry",
enter_weight	:"Ange vikt",
enter_length	:"ange längd",
totals	:"Totals:",
nb_skeins	:"Antal härvor",
name	:"namn",
company_name	:"Företagsnamn",
yarn_weight	:"garnvikten",
unlink_ravelry	:"Ta bort länk från Ravelry",
colorname	:"Färgnamn",
select_weight	:"väljer vikt",
use_nb_skeins	:"Antal härvor",
use_totals	:"Använd summor",
each_skein	:"Varje härva är:",
notes	:"anteckningar",
edit	:"Redigera",
disconnect_ravelry	:"Logga ut från Ravelry",
type	:"Typ",
length	:"Längd",
size	:"Storlek",
notes	:"anteckningar",
select_all	:"Välj alla",
select_corresponding_project	:"Välj motsvarande projekt på Ravelry",
used_by:"används för",
		left_stash:"kvar i stash",

		other_sources:"Mer",

		create_custom_proj:"Create a new custom project",
		create_custom_proj_desc:"Configure your counters and import your patterns from any source",
		import_rc_pattern:"Import a RC pattern",
		import_rc_pattern_desc:"Import a pattern specially crafted and optimized for this app",
		import_project_ravelry:"Import a Raverly project",
		import_project_ravelry_desc:"Import a Raverly project to the app, with all its details",
		ask_resize_counters:"Would you like to make the counters appear smaller? You can change the counters size anytime in the settings.",
		start:"Start",
		personal_source:" ",
		external_source:" ",

		appear_row:" ",
		single_row:"Single Row",
		range_rows:"Range of rows",

		title:"Titel",
		icon:"Ikon",
		document_locked:"Cannot add annotations, PDF is protected by author",
},


fi : {
distribstitches: {
			tooltitle: 'Jaella lisäys & Vähennykset',
			increases: 'lisääntyy',
			increase: 'lisääntyä',
			decreases: 'vähenee',
			decrease: 'vähennys',
			stitches: 'silmukkaa',
			stitch: 'ommel',
			pleaseenter: 'Anna 2 arvot',
			willstart: 'Se näyttää tältä:',
			desirednb: 'Haluttu määrä silmukoita',
			currentnb: 'Nykyinen määrä silmukkaa',
			weadvise: 'Suosittelemme tekemään',
			every: 'joka',
			thefirst: 'Aloita ensimmäinen',
			after: 'jälkeen',
			then: 'sitten',
			endwith: 'ja sitten päättyy',
		},
		infoImportPattern: "Click here to import your pattern from any source",
	you_no_account:"Eikö sinulla ole vielä tiliä?",
login:"Kirjaudu sisään",
already_have_account:"Minulla on jo tili",
register:"Rekistöröidy",
username:"Käyttäjätunnus",
your_email:"Sähköpostisi",
your_password:"Salasanasi",
password_not_equal:"Salasana ei ole sama",
repeat_password:"Toista salasanasi ",
enter_username:"Kirjoita käyttäjätunnus",

login_desc:"Kirjaudu käyttääksesi sovellusta monilla laitteilla",

try_premium:"Kokeile premium versiota sovelluksesta",
help_us:"Jotta voimme jatkaa sovelluksen parissa työskentelyä ja nauttiaksemme kaikista ominaisuuksista, pyydämme teidän panostustanne ",

premium_abo:"Premium tilaus",
no_ads:"Ei mainoksia",
all_patterns_unlocked:"Kaikki kaavat avattuna ",
access_every_device:"Pilveen synkronointi: Kaikki projektisi saatavilla kaikilla laitteilla. Et koskaan menetä edistymistäsi!",
unlimited_counters:"Niin monta laskinta kuin haluat",
unlimited_pictures:"Lisää kuvia!",
try_premium_btn:"Kokeile premium versiota",
continue_free:"Jatka ilmaisella versiolla",
login_required:"Kirjaudu sisään varmuuskopioidaksesi projektisi.",
buy_required:"Synkronointi on poissa päältä. Lue lisää kaikista Premium ominaisuuksista täällä.",
log_out:"Kirjaudu ulos",
year:"Vuodessa",
month: "Kuukaudessa",
monthly_sub: "Kuukausitilaus",
annual_sub: "Vuosittainen tilaus",

premium_feature:"Tämä on premium ominaisuus",
premium_pattern:"Tämä on premium kaava",
try_free:"Kokeile Premiumia viikko maksutta ",
learn_more_premium:"Lue lisää premiumista",
wrong_pwd_email:"Väärä sähköposti tai salasana",
error_occured_check_connection:"Virhe tapahtui. Oletko yhdistettynä internettiin? ",
already_paid:"Minulla on jo aktiivinen tilaus",
enter_description:"Kirjaa kuvaus tähän",
invalid_email:"Väärä Sähköposti  ",
email_already_registered:"Sähköposti jo rekistöröity ",
username_already_registered:"Käyttäjätunnus jo käytössä",

lang:"en",
tts_lang:"en-US ",
ok:"ok",
cancel:"peruuta ",
start_new_project:"Aloita minun/ensimmäinen projektini ",
new_project:"Uusi projekti",
project_name:"Projektin nimi ",
enter_name:"Kirjaa nimi",
enter_project_name:"Kirjaa projekti nimi",
project_type:"Valitse Neulomiskaava",
next:"SEURAAVA",
motif:"Valitse Silmukka",
material:"TARVITSET",
description:"KUVAUS",
begin:"ALOITA  ",
finish:"PÄÄTÄ",
current_rang:"Nykyinen kerros",
repeat:"Toista: ",
times:"kertaa",
stitches:"Silmukat",
rangs:"Kerrokset",
projs_completed:"Suoritettu",
tutorials:"Tutoriaalit",
select_size:"Valitse koko",
select_woolsize:"Valitse villan koko",
linked_tutos:"Tähän liittyvät tutoriaalit",
quantity:"Määrä",
size:"Koko",
loading:"Ladataan…",
badge1:"Taso 1",
badge2:"Taso 2",
badge3:"Taso 3",
badge4:"Taso 4",
badge5:"Taso 5",
badge6:"Taso 6",
part:"OSA",
previous_seq:"Edellinen",
finished:"Olet saanut projektisi päätökseen!",
congratz:"ONNEKSI OLKOON!",
row_counters:"Kerrosten laskin",
global_counter:"Globaali laskin",
secondary_counter:"Toissijainen laskin",
nb_row:"Kerrosten määrä",
name:"Nimi (vaihtoehtoinen)",
save:"TALLENNA",
auto_inc:"Yhdistä Globaaliin laskimeen",
set_up_main_counter:"Klikkaa pääasiallista laskinta asentaaksesi sen",
counter_project:"Käytä Kerrosten Laskinta",
edit_counters:"Editoi laskimia",
enter_comment_opt:"Kirjaa kommentti tähän, jos haluat",
show_com_at_row:"Näytä kommentti tässä kerroksessa #",
add_counter_com:"Lisää kommentti",
comments:"Kommentit",
reinit_at:"Nollaa tämän kun tämä kerros on saavutettu",
counters:"LASKIMET",
counters_desc:"Seuraa edistymistäsi kerrosten laskimillamme!\n\n Voit konfiguroida:\n- 1 Globaalin laskimen laskemaan koko projektisi kerroksia\n- Useita toissijaisia laskimia, jotka auttavat sinua sinun lisäämisen, vähentämisen tai neulomiskaavojen kanssa.\n\nTarvitsetko muistutuksen tietyn kerroksen kohdalla? Lisää se kommenttina siihen liittyvässä laskimessa, se näytettään sinulle oikealla hetkellä! ",
set_average_stitches:"Kirjaa keskimääräinen silmukoiden määrä per kerros (Arviolta)",
average_stitches:"Keskimääräinen silmukoiden määrä per kerrokset",
delete:"Poista",
enter_number:"kirjaa numero",
meters:"M",
centimeters:"SM",
inches:"Tuumaa",
yards:"Jaardia",
convert_units:"Yksiköiden muuntaja",
fill_all_fields:"Täytä kaikki kentät",
your_project:"SINUN PROJEKTISI",
compute_swatch:"Mallitilkku Sovitin",
pattern:"KAAVA",
your_swatch:"SINUN MALLITILKKUSI",
swatch:"mallitilkku  ",
must_monter:"sinu täytyy luoda:",
must_knit:"sinun täytyy neuloa:",
grams:"grammaa",
tools:"Välineet",
the_project:"projekti",
tencm:"10 sm",
your_wool:"Sinun villasi",
woolball_size:"Jokainen kerä sisältää",
wool_nb_required:"Tarvittavien kerien määrä",
woolballs:"Villalankakerät",
number_required:"kirjaa numerot ",
woolball_convertor:"Villalankakerä muuntaja ",
pattern_translate:"Ohjeiden käännökset ",
activ_sound:"Aktivoi ääni jotta voit käyttää sovellusta äänelläsi ",
say_next:"Sano 'SEURAAVA VAIHE' siirtyäksesi seuraavaan kerrokseen (vinkki: tee se hiljaisessa huoneessa, lisäksi matalat äänet toimivat parhaiten joten käytä miehekkäintä ääntäsi kun neulot)",
say_next_counter:"Sano 'SEURAAVA VAIHE' lisätäksesi ensisijaiseen laskimeen (vinkki: tee se hiljaisessa huoneessa, lisäksi matalat äänet toimivat parhaiten joten käytä miehekkäintä ääntäsi kun neulot)",
say_next_ios:"Sano 'SEURAAVA' siirtyäksesi seuraavaan kerrokseen (vinkki: tee se hiljaisessa huoneessa, lisäksi matalat äänet toimivat parhaiten joten käytä miehekkäintä ääntäsi kun neulot)",
say_next_counter_ios:"Sano 'SEURAAVA VAIHE' lisätäksesi ensisijaiseen laskimeen (vinkki: tee se hiljaisessa huoneessa, lisäksi matalat äänet toimivat parhaiten joten käytä miehekkäintä ääntäsi kun neulot)",
search:"etsi",
permission_not_granted:"Käyttöoikeudet kielletty",
retry:"Yritä uudelleen",
authorize:"Salli",
help_1:"Voit klikata ikonia saadaksesi lisää informaatiota",
you_knitted:"Olet neulonut:",
youre_now:"Olet nyt",
patterns_easy:"Aloittelija Kaavat",
patterns_medium:"Helpot\n Kaavat",
patterns_hard:"Keskivaikeat kaavat",
or:"tai",
ravelry_library:"Minun Ravelry kirjastoni",
ravelry_search:"Etsi Ravelry:sta",
import_pattern:"Importoi Kaava",
import_to_counter:"Importoi kerros laskimeeni",
downloading:"Ladataan…Tämä voi viedä muutaman minuutin ",
select_source:"Valitse Lähde:",
confirm_import_webpage:"Tämä sivu importataan niin kuin näet sen nyt. Jatketaanko?",
confirm_delete_imported_pattern:"Poista kaava?",
ask_img_upload:"Kuvasi voidaan jakaa muiden käyttäjien kanssa, jatketaanko?",
add_picture:"Lisää Kuva",
send_feedback:"Lähetä Palautetta",
important_feedback:"Sinun palautteesi on tärkeää",
are_you_satisfied:"Oletko tyytyväinen taideteokseesi? Mitä mieltä olit kaavasta?\n\nVoit jättää mielipiteesi tänne, jotta voimme parantaa sovellusta!",
send:"lähetä   ",
thanks:"Kiitos",
time_elapsed:"Aika",
long_press:"Paina pitkään projektin kohdalla nähdäksesi lisää vaihtoehtoja",
take_pic:"Ota kuva",
gallery_pic:"Valitse kirjastosta",
select_pic:"Valitse:",
reset_timer:"Nollaa ajastin",
see_picture:"Katso Kuva",
change_picture:"Vaihda Kuva",
stitches_on_row:"Silmukoiden määrä kerroksessa:",
storage_full:"Muisti täynnä. Poista joitain tiedostoja tai sovelluksia ja kokeile uudelleen",
phone:"Sinun laitteesi",
select_language:"Valitse kieli",
download:"Lataa",
reset_counter:"Nollaa laskin",
started_on:"Aloitettu",
time_elapsed2:"Aikaa Kulunut",

detailed_pattern:"Yksityiskohtainen kaava",
real_virtual_pattern:"sovelluksessa ja tulostettu",

have_kit:"Minulla on jo setti",
buy:"Osta",
enter_command:"Avataksesi kaavan, kirjaa tilauksen numero. Löydät sen tilausvahvistus sähköpostista",
command_number:"Tilauksen numero:",
inside:"Sisällä : ",
price:"Hinta :",
coloris:"Värit :",
kit_from:"Setti on :",
galerie:"Galleria",
invalid_order:"Tilauksen numero väärä.",
network_error:"Nettiyhteys Virhe",
need_help_contact:"Tarvitsetko apua? Ota meihin yhteyttä tricotonapp@gmail.com ",
access_pattern:"Avaa kaava",

auto_translate:"Seuraavaksi näet automaattisen käännöksen kaavan kuvauksesta. Kaava on käännetty manuaalisesti.",
raverly_suggestions:"raverly_ehdotukset:'Mitä me rakastamme Raverly:ssa',",
needle_size:"Puikkojen koko :",
wool_size:"Villan koko :",
yardage:"Jaardien määrä :",
in_your_language:"Sinun kielelläsi : ",
yes:"kyllä",
no:"ei",
access_ravelry_pattern:"Avataksesi kaavasi, luo uusi projekti normaalisti, klikkaa 'importoi kaava' ja mene Rvalry kirjastoosi (tai lataa se puhelimeesi ja valitse 'importoi puhelimesta' vaihtoehto)",
have_pattern:"Minulla on jo kaava",
buy_download_hint:"Kaavan ostamisen jälkeen, klikkaa lataa nappia nettisivulla aloittaaksesi projektisi ",


you_no_account:"Eikö sinulla ole vielä tiliä?",
login:"kirjaudu sisään",
already_have_account:"Minulla on jo tili",
register:"rekistöröidy",
username:"käyttäjätunnus",
your_email:"sähköpostisi",
your_password:"salasanasi",
password_not_equal:"Salasana ei ole sama ",
repeat_password:"Toista salasanasi",
enter_username:"Kirjaa käyttäjätunnus",

login_desc:"Kirjaudu käyttääksesi sovellusta monilla laitteilla",

try_premium:"Kokeile premium versiota sovelluksesta",
help_us:"Jotta voimme jatkaa sovelluksen parissa työskentelyä ja nauttiaksemme kaikista ominaisuuksista, pyydämme teidän panostustanne.",

premium_abo:"Premium Tilaus",

no_ads:"Ei mainoksia",
all_patterns_unlocked:"Kaikki kaavat avattuna",
access_every_device:"Pilveen synkronointi: Kaikki projektisi saatavilla kaikilla laitteilla. Et koskaan menetä edistymistäsi!",
unlimited_counters:"Niin monta laskinta kuin haluat",
unlimited_pictures:"Lisää kuvia!",
try_premium_btn:"Kokeile premium versiota",
continue_free:"Jatka ilmaisella versiolla",
login_required:"Kirjaudu sisään varmuuskopioidaksesi projektisi.",
buy_required:"Synkronointi on poissa päältä. Lue lisää kaikista Premium ominaisuuksista täällä.",
log_out:"Kirjaudu ulos",
year:"vuosi",

premium_feature:"Tämä on premium ominaisuus",
premium_pattern:"Tämä on premium kaava",
try_free:"Kokeile Premiumia viikko maksutta",
learn_more_premium:"Lue lisää premiumista ",
wrong_pwd_email:"Väärä sähköposti tai salasana",
error_occured_check_connection:"Virhe tapahtui. Oletko yhdistettynä internettiin? ",
already_paid:"Minulla on jo aktiivinen tilaus",
enter_description:"Kirjaa kuvaus tähän",
invalid_email:"Väärä Sähköposti  ",
email_already_registered:"Sähköposti jo rekistöröity ",
username_already_registered:"Käyttäjätunnus jo käytössä",
free_week_trial:"Kokeile sitä 7 päivää maksutta !",
learn_about:"Lue lisää  ",



privacy_policy:"Tietosuojakäytäntö",
tos:"Käyttöehdot",


explain_why_premium_title:"Miksi tarjoamme premium version?",
explain_why_premium_1:"Aloimme työskentelemään sovelluksen parissa tammikuussa 2018 ja 2 kuukauden kuluttua julkaisimme siitä ensimmäisen version. Siitä lähtien työskentelemme joka päivä parantaaksemme sitä. Se on pysynyt ilmaisena ja mainoksettomana jo melkein vuoden ja kaikki tämä kun sovellus on vaatinut:",
explain_why_premium_2:"3500 tuntia työtä",
explain_why_premium_3:"vapaa-aikaamme ",
explain_why_premium_4:"Ylläpito ja kääntämiskulut ",
explain_why_premium_5:"Rahoitettu omilla säästöillä",
explain_why_premium_6:"Tavoitettavissa olemisen viikolla ja viikonloppuina",
explain_why_premium_7:"Teidän sähköposteihin ja kommentteihin vastaaminen",
explain_why_premium_8:"Me rakastamme tätä projektia ja haluamme jatkaa sovelluksen parantamista jotta se vastaa teidän tarpeisiinne, mutta emme voi jatkaa rahan menettämistä siihen.\n\n Premium tilaus on tapa teille tukea meitä ja mahdollistaa meidän työskentelymme tämän upean projektin parissa!",


see_premium:"Katso Premium Tarjous >",
why_not_free:"Miksei tämä sovellus ole kokonaan ilmainen?",

ad_warning:"Saatat nähdä täällä pian mainoksia. Lue miksi",

remove_ads:"Poista vain mainokset",

continue:"jatka",

ios_iap_info:"• Maksu laskutetaan iTunes Tililtä laskutuksen vahvistamisessa\n• Tilaus uusiutuu automaattisesti ellei automaattista uusintaa ole laitettu pois päältä viimeistään 24 tuntia ennen nykyisen jakson loputtua\n• Tililtä laskutetaan uusiminen 24 tunnin sisällä ennen nykyisen jakson loputtua, ja identifioidaan uusimisen hinta\n• Käyttäjä hallitsee itse tilauksiaan ja automaattinen uusiminen voidaan sammuttaa menemällä käyttäjän Tilin Asetuksiin oston jälkeen\n• Käyttämättömät osat ilmaisista kokeilujaksoista, jos tarjottu, luovutetaan kun käyttäjä ostaa tilauksen siihen julkaisuun, mihin se soveltuu",


projects_synchro:"Palvelimeen tallennetut projektit",
last_update:"Palvelimeen tallennetut projektit",

annotations_hint:"Voit lisätä huomautuksia napsauttamalla pitkään asiakirjaa.",
add_annotation_text:"Lisää teksti tähän...",


select_mode:"Select mode",
		knitting_mode: "",
		crochet_mode: "",

		badge1_crochet: "Taso 1",
		badge2_crochet: "Taso 2",
		badge3_crochet: "Taso 3",
		badge4_crochet: "Taso 4",
		badge5_crochet: "Taso 5",
		badge6_crochet: "Taso 6",

		type:'typ',
		discard_changes:'Hylkää muutokset ?',

		agree:'olen samaa mieltä',
		agree_email:'Hyväksyn vastaanottaa sähköpostia sovelluksesta',
		see_terms: 'Katso ehdot ja tietosuojakäytäntö',
		must_agree_pp: 'Jatkaaksesi sinun on hyväksyttävä ehdot ja tietosuojakäytäntö',
		duplicate: 'päällekkäinen',

		no_ravelry_project:'Sinulla ei ole projektia Ravelryssä.',
		already_imported:'Olet jo tuonut tämän projektin',
		import_ravelry:"tai tuoda Ravelry",
		import:'tuonti',
		small:'pieni',
		normal:'normaali',
		big:'iso',
		options:'asetukset',
		show:'Show',
		hide:'piilota',
		long_press_position:'Paina pitkään kohdetta muuttaaksesi sen sijaintia',
		enable_expert_options:'Voit ottaa asiantuntijatilan käyttöön asetuksissa',
		enable_expert:"Ota asiantuntijatila käyttöön",
		counter_size:"laskimen koko",
		highligther_size:'korostimen koko',
		starts_at:'alkaa',
		max_inf_begin:'Max number of rows cannot be less than "starts at"',
		reset:'reset',

		then_price:'sitten',
		minutes:'minuuttia',
		seconds:'sekuntia',
		configure:'Configure',
		add_to_timer: 'ja lisää ajastimeen automaattisesti kysymättä minulta, kun näyttö oli pois päältä alle X minuuttia:',
		keep_timer_off:'Ajastin pysyy päällä, kun näyttö on pois päältä',
		timer_ask:"Sovelluksen jättämisestä on kulunut {0}. Haluatko lisätä ne ajastimeen?",
		add_time_timer:"Lisää aikaa ajastimeen?",
		select_camera: "ota kuva",
    	select_gallery: "valitse kuva",
    	photo:'Kuva',


    	syncing:'synkronointi',
		sync_error:'Synkronointivirhe',

		connect_watch:'Connect Apple Watch (Premium)',
		watch_not_connected:'Apple Watch not connected',
		watch_connected:'Apple Watch connected',

		troubleshoot_watch:"auta",
reset_all_timers:"Nollaa ajastin? Kaikki käytetty aika hankkeisiin palautuu 0.",
premium_file_sharing:"Vain Premium käyttäjät voivat käyttää tiedostojen jakamiseen.",
shared_with_me:"Jaettu kanssani",
no_shared:"Kukaan ei ole jaettu projektin kanssasi vielä.",
accept:"Hyväksyä",
from:"alkaen",
share_project:"Jaa projekti",
share_copyright_check:"En ole samaa tekijänoikeudella suojatun dokumentin",
share_copyright:"Kunnioita kirjoittajat ja vain jakaa asiakirjoja, joihin sinulla on lupa. Jakaminen tekijänoikeudella suojattua materiaalia voi johtaa tilisi sulkemiseen.",
share_project_with:"Haluan jakaa tämän hankkeen kanssa",
disable_social:"Disable sosiaalisia ominaisuuksia",
friends:"Ystävät)",
shared:"Yhteiset",
chats:"viestien",
chat:"Viesti",
join_community:"Liity yhteisöön!",
social_desc:"Etsi ystäviä ja jakaa iloa. Ystävät näkevät etenemistään kuvia ja voit jakaa projekteja heidän kanssaan.",
projects:"projektit",
unfriend:"Poista tämä ystävä",
congratulate:"Onnitella",
wait_confirm:"odottaa vahvistusta",
not_found:"Ei löydetty",
invite_friend:"Kutsu ystävä",
invite_ext:"Hei Haluaisin kutsua sinut kokeilemaan My Row Counter sovellus. Asenna se ja lisää kaveriksi my username is {0}",
add_friend_desc:"Lisää ystäväsi app voidakseen kommunikoida heidän kanssaan ja jakaa edistymistä. Ystävät voivat nähdä kuvia.",
send_invitation:"Lähetä kutsu",
add_friend:"Lisää ystävä",
no_friend_yet:"Ei ystävä vielä",
start_chatting:"Klikkaa alkaa keskustella",
you_finished_project:"Lopetit projekti",
you_started_project:"Olet aloittanut uuden projektin",
finished_project:"päättynyt projekti",
started_project:"aloitti uuden projektin",
reached_level:"on nyt tasolla",
you_reached_level:"Olet nyt tasolla",
sent_message:"lähetti sinulle viestin",
congratulated_you:"onnitteli sinua 👏",
shared_project_with_you:"jaettu projektin kanssasi",
wants_to_be_friend:"haluaa olla ystäväsi!",
became_your_friend:"tuli ystäväsi!",
type_your_message:"Kirjoita viesti",


login_social:"Kirjaudu sisään liittyäksesi yhteisöön",

faq_translated:"Tämä verkkosivusto on vain englanniksi. Haluatko nähdä automaattisen käännöksen?",



		watch_help_1:"Käynnistä sovellus Apple Watch -sovelluksessa. Valitse tässä puhelimessa projekti. Jos tämä ei auta, lue alla olevat ohjeet",
		watch_help_2:"Open the «Watch» App on your iPhone.",
		watch_help_3:"Scroll down to find the Row Counter App, then click on it",
		watch_help_4:"Toggle the button to disable, then enable again App on Watch.",
		watch_help_5:"On your watch, launch the app. On the iPhone, go to a project.",

		select:'valita',
		select_part:'Valitse osa',
		next_part:"Seuraava",
		part_finished:"osa päättynyt",
		part_finished_desc:"Onneksi olkoon, olet tämän osan!",
		part:"osa",
		delete_part:"Poista tämä osa? Kaikki laskurit tässä osassa poistetaan.",

		highlight_vertical:"Näytä korostin pystysuunnassa",

		activate:"aktivoida",

		convert_what:"mitä haluat muuntaa?",
        to:"että",


        yarn_yards	:"metrin",
yarn_meters	:"metriä",
ounces	:"unssia",
add	:"Lisätä",
select_this_yarn	:"Valitse tämä lanka",
select	:"valita",
stash	:"jemma",
stash_ravelry_beta	:"Tämä ominaisuus on beta, käytä omalla riskillä ja raportin asioista (bug, käännösvirheet) ja tricotonapp@gmail.com",
adjust_no_sync	:"Säätöjä ei voi synkronoida Ravelry: ssa",
import	:"Tuonti",
select_needle	:"neulanvalintamekanismit",
remove	:"Poista",
nothing_here	:"Ei vielä mitään.",
see_needles	:"Katso neulat",
see_stash	:"Katso jemma",
needles	:"neulat",
needle	:"Neula",
link_ravelry_proj	:"Haluatko linkittää tämän hankkeen olemassa olevan hankkeen Ravelryssä?",
link_ravelry_proj_hint	:"Valitse 'ei', jos et käytä \"Raverly\" tai jos et ole luonut tätä projektia Ravelryssä",
must_be_img_pdf	:"tiedostoa ei voi ladata. Täytyy olla kuva tai PDF",
cannot_be_zero	:"Ei voi olla 0",
color	:"Väri",
add_from_stash	:"Lisää Stash",
link_needles	:"Liitä neulat tähän projektiin",
import_ravelry2	:"Tuonti Ravelrysta",
create	:"Luoda",
starting_quantity	:"Starting määrä:",
use	:"Käyttää",
adjust	:"Säätää",
edit_yarn	:"edit Lanka",
yarn_stash	:"Lanka Stash (Beta)",
needle_stash	:"Neula Stash (Beta)",
sync_ravelry	:"Synkronoituna Ravelryssä",
enter_weight	:"syötä paino",
enter_length	:"syöttää pituuksia",
totals	:"yhteensä:",
nb_skeins	:"Lukumäärä vyyhtiä",
name	:"Nimi",
company_name	:"Yrityksen nimi",
yarn_weight	:"lanka paino",
unlink_ravelry	:"Poista linkitys Ravelrystä",
colorname	:"väri Name",
select_weight	:"Valitse paino",
use_nb_skeins	:"Lukumäärä vyyhtiä",
use_totals	:"Käytä yhteensä",
each_skein	:"Kukin vyyhti on:",
notes	:"Huomautuksia",
edit	:"Muokata",
disconnect_ravelry	:"Kirjaudu ulos Ravelrysta",
type	:"Tyyppi",
length	:"Pituus",
size	:"Koko",
notes	:"Huomautuksia",
select_all	:"Valitse kaikki",
select_corresponding_project	:"Valitse vastaava projekti Ravelryssä",
used_by:"käytetty",
		left_stash:"jätetty koristeeseen",

		other_sources:"lisää",

		create_custom_proj:"Create a new custom project",
		create_custom_proj_desc:"Configure your counters and import your patterns from any source",
		import_rc_pattern:"Import a RC pattern",
		import_rc_pattern_desc:"Import a pattern specially crafted and optimized for this app",
		import_project_ravelry:"Import a Raverly project",
		import_project_ravelry_desc:"Import a Raverly project to the app, with all its details",
		ask_resize_counters:"Would you like to make the counters appear smaller? You can change the counters size anytime in the settings.",
		start:"Start",
		personal_source:" ",
		external_source:" ",

		appear_row:" ",
		single_row:"1 kerros",
		range_rows:"useita Kerrokset",

		title:"Otsikko",
		icon:"Kuvaketta",
		document_locked:"Cannot add annotations, PDF is protected by author",
}


});

export default strings;
